export default {
	task: 'Zadania',
	col: 'Kolumny',
	forsage: 'Dyktowanie matematyczne (Szybcy i wściekli)',
	trans: 'Tłumaczenia',

	timeIsOver: 'Czas minął',
	uploadForDetail: 'Pobierz plik, aby uzyskać więcej informacji o olimpiadzie',
	pasteFile: 'Wklej plik',
	openResult: 'Otwarte wyniki',
	countParticipant: 'Members in a Category',
	place: 'Miejsce',
	fullName: 'Nazwisko, Imię',
	club: 'Club MA',
	currencyText: 'Waluta',
	listIsEmpty: 'Lista jest pusta',
	notIndicated: 'Nie wskazano',
	yearNotIndicated: 'Błąd. Lista grup wiekowych jest pusta',
	difficultyNotIndicated: 'Błąd. Lista trudności jest pusta',
	sidebar: {
		general: 'General',
		constructor: 'Konstruktor',
		analytic: 'Analityczne',
		setting: 'Ustawienie',
	},
	template: {
		addTemplate: 'Dodaj szablon',
		fillBlocks: 'Wypełnij wszystkie pola bloku',
		fillRounds: 'Wypełnij wszystkie pola rundy',
	},
	olympic: {
		finishIn: 'Olimpiada kończy się za',

		newTemplate: 'Nowy szablon Olimpiady',
		editTemplate: 'Zmiana szablonu Olimpiady',
		tab: {
			CURRENT: 'Obecny',
			FUTURE: 'Zaplanowany',
			PASSED: 'Przeszłość',
			NEW: 'Nowa Olimpiada',
		},
		theDateOfThe: 'Data',
		name: 'Nazwa',
		participate: 'Uczestniczył',
		paid: 'Płatny',
		tested: 'Przetestowany',
		show: 'Pokaż przez',
		newOlympic: 'Nowe Igrzyska Olimpijskie',
		editOlympic: 'Edytuj Olympic',

		theTimeOfThe: 'Spędzanie czasu',
		price: 'Cena',
		add: 'Dodać do',
		templateOfTasks: 'Szablon zadania',
		cancelOlympic: 'Anuluj szablon',
		groupAge: 'Grupy wiekowe',
		levelDifficultySimple: 'Poziomy trudności',
		levelDifficulty: 'Poziomy trudności (do rejestracji)',

		tasks: {
			column: 'Zadanie „KOLUMNY”',
			furious: 'Szybka i wściekła misja',
		},
		round: 'Okrągły',
		level: 'Poziom',
		columns: 'Kolumny',
		number: 'Numer',
		difficulty: 'Złożoność',
		timeRound: 'Czas rundy (s)',
		block: 'Blok',
		numberOfExample: 'Liczba przykładów',
		speed: 'Prędkość',
		addBlock: 'Dodaj blok',
		addRound: 'Dodaj round',
		saveTemplate: 'Uratuj Olimpiadę',
		saveOlympiad: 'Uratuj igrzyska olimpijskie',
		cancelOlympiad: 'Anuluj igrzyska olimpijskie',
		setting: {
			frontPage: 'Pierwsza strona',
			titleBold: 'Tytuł (pogrubiony)',
			titleRegular: 'Tytuł (zwykły)',
			descriptionText: 'Tekst opisu',
			timeRegion: 'Region czasowy',
			agreement: 'Umowa uczestnictwa',
			testingOlympiad: 'Olimpiada Testowa',
			numberOfTests: 'Liczba testów',
			gallery: 'Galeria zdjęć na stronie głównej (1-3 pliki)',
			cancel: 'Anulować',
			passwordSetting: 'Ustawienia hasła',
			oldPassword: 'Stare hasło',
			newPassword: 'Nowe hasło',

		},

	},
	allFieldsAreRequired: 'Wszystkie pola są wymagane',
	agree: {
		isDelete: 'Czy na pewno chcesz usunąć tę Olimpiadę?',
	},
}