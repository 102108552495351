import React, { FC, memo } from 'react'
import classNames from 'classnames/bind'
import s from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { CircleLoading } from '../CircleLoading'
import { DEFAULT_LNG_ADMIN } from 'react_constants'

const cx = classNames.bind(s)

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
}

interface PropsButton {
	lng?: string | boolean
	label?: string,
	size?: 'small' | 'medium' | 'thin-small',
	color: 'orange' | 'blue' | 'white' | 'pink' | 'transparent',
	classNames?: string
	width?: 'auto' | number,
	noTranslate?: boolean,
	href?: any,
	view?: 'default' | 'thin'
	border?: boolean,
	loading?: boolean,
	howTagA?: boolean
	replace?: boolean
}

const Button: FC<PropsButton & ButtonProps> = ({
																								 label = 'text',
																								 lng,
																								 classNames,
																								 color = 'white',
																								 size,
																								 width = 'auto',
																								 border = false,
																								 noTranslate = false,
																								 href,
																								 view = 'default',
																								 loading,
																								 howTagA = false,
																								 replace = false,
																								 ...props
																							 }): JSX.Element => {
	const [t] = useTranslation()
	const styles = {
		width,
		...detectStyle({ color, border, view }),
	}
	return (
		<>
			<button
				{...props}
				style={styles}
				className={cx('Button', {
					'ButtonThin': view === 'thin',
					'ButtonThinSmall': size === 'thin-small',
				}, classNames, { ButtonSmall: size === 'small' })}
			>
				{loading && <CircleLoading isOverlay />}
				{ButtonContainer(href, howTagA, <>{noTranslate ? label : (lng ? t(label, { lng: DEFAULT_LNG_ADMIN }) : t(label))}</>, replace, props.disabled)}
			</button>
		</>
	)
}
export default memo(Button)

type detectBgColorButtonType = Pick<PropsButton, 'color' | 'border' | 'view'>

const ButtonContainer = (href: any, howTag: any, children: JSX.Element, replace = false, disabled = false) => href ?
	(howTag
		? <a href={href} onClick={(e) => disabled && e.preventDefault()}>{children}</a>
		: <Link to={href} onClick={(e) => disabled && e.preventDefault()} replace={replace}>{children}</Link>)
	: <span>{children}</span>

function detectStyle({ color, border, view }: detectBgColorButtonType) {
	function getColor(color: any) {
		if (border) {
			return { border: '1px solid ' + color, color, backgroundColor: 'transparent' }
		}
		return { backgroundColor: color }

	}

	let addition = {
		color: 'var(--white)',
		boxShadow: '2px 4px 0px var(--dark-pink)',
	} as any

	if (color === 'orange') {
		return {
			...addition,
			...getColor('var(--orange)'),
		}
	} else if (color === 'blue') {
		return {
			...addition,
			...getColor('var(--blue)'),
			boxShadow: '2px 4px 0px var(--violet)',
		}
	} else if (color === 'pink') {
		return {
			...addition,
			...getColor('var(--pink)'),
			boxShadow: '2px 4px 0px var(--violet)',
		}
	}
	return {
		backgroundColor: color === 'transparent' ? 'transparent' : 'var(--white)',
		color: 'var(--blue)',
		fontWeight: view === 'thin' && !border ? 400 : 700,
		boxShadow: '2px 4px 0px var(--violet)',
	}
}
