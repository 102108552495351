export default {
	allCountry: 'Wszystkie kraje',
	allCities: 'Wszystkie miasta',
	title: "Rejestracja",

	label : {
		email: 'E-mail',
		lastName: 'Nazwisko dziecka',
		firstName: 'Imię dziecka',
		gender: 'Płeć',
		country: 'Kraj',
		city: 'Miasto',
		school: 'Szkoła arytmetyki mentalnej',
		phone: 'Numer kontaktowy',
		level: 'Poziom złożoności',
		birthday: "Rok urodzenia"

	},
	gender: {
		boy: "Сhłopiec",
		girl: "Dziewczynka",
		notSay: 'Nie chcę wskazywać'

	},
	pay: "Zapłać",
	select: {
		selectCountry: "Wybierz kraj",
		selectCity: "Wybierz miasto",
		selectYear: "Wybierz rok urodzenia",
		selectLevel: 'Wybierz poziom trudności'

	},
	notify: {
		title: "Uwaga!",
		description: " Podaj tylko prawdziwe dane. Zostaną one wyświetlone w wynikach Olimpiady oraz wykorzystane przy tworzeniu dyplomów."
	},
	notifyPhone: {
		title: 'Uwaga!',
		description: `Aby zapewnić wygodę i pracę bez zakłóceń wykonuj zadania na tablecie, komputerze lub laptopie.`,
		hide: 'Przejdź do gry',
	},
	errors: {
		globalError: 'Proszę wypełnić wszystkie pola powyżej!',
		fieldIsRequired: "Pole wymagane",
		numeric: "Pole musi zawierać tylko liczby",
		minLength: "Liczba znaków musi być większa niż ",
		maxLength: 'Liczba znaków musi być mniejsza niż',
		not_numeric: 'Ciąg nie może zawierać liczb',
		email: 'Niepoprawny email',
		password: 'Pole musi zawierać co najmniej jedną literę i jedną cyfrę',
		notSymbol: 'Pole nie może zawierać znaków specjalnych',


		//Olympiad
		INVALID_CODE: 'Nieprawidłowy kod',
		ALREADY_PARTICIPATED: 'Już wykorzystałeś swój unikalny kod! Jeśli chcesz ponownie wziąć udział w igrzyskach olimpijskich, musisz się ponownie zarejestrować.',
		TIME_EXPIRED: 'Upłynął czas',
		FIRST_NAME_REQUIRED: 'Wymaga pola "Imię"',
		LAST_NAME_REQUIRED: 'Wymagane pole "Nazwisko"',
		INVALID_EMAIL: 'Nieprawidłowy e-mail',
		INVALID_PHONE: 'Nieprawidłowy telefon',
		INVALID_YEAR: 'Nieprawidłowa grupa wiekowa',
		INVALID_DIFFICULTY: 'Nieprawidłowy poziom trudności',
		INVALID_COUNTRY: 'Nieprawidłowy kraj',
		NOT_AUTHENTICATED: 'Autoryzowany błąd',
		NOT_ADMIN: 'Nie jesteś administratorem',
		NOT_FOUND: 'Nie znaleziono',
		SHORT_PHONE: 'Podany ciąg jest zbyt krótki, aby był numerem telefonu',
		'invalid phone': 'Nieprawidłowy numer telefonu',
		LONG_PHONE: 'Podany ciąg jest za długi, aby był numerem telefonu',
		'invalid email': 'Niepoprawny email',
		DAILY_TESTS_LIMIT: 'Na dziś wyczerpałeś 10 prób. Jutro możesz ponownie potrenować.',
		TEMPLATE_NOT_FOUND: 'Nie znaleziono szablonu'

	}
}
