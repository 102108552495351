export default {
	task: 'Задания',
	col: 'Столбцы',
	forsage: 'Математический диктант (Форсаж)',
	trans: 'Переводы',

	timeIsOver: 'Время истекло',
	uploadForDetail: 'Загрузить файл для “детальнее про олимпиаду"',
	pasteFile: 'Вставьте файл',
	openResult: 'Открыть результаты',
	countParticipant: 'Участников в категории',
	place: 'Место',
	fullName: 'Фамилия, Имя',
	club: 'Клуб МА',
	currency: 'Валюта',
	listIsEmpty: 'Список пуст',
	notIndicated: 'Не указано',
	currencyText: 'Валюта',
	yearNotIndicated: 'Ошибка. Список "Возрастные группы" пуст',
	difficultyNotIndicated: 'Ошибка. Список "Сложность" пуст',
	sidebar: {
		general: 'Главная',
		constructor: 'Конструктор',
		analytic: 'Аналитика',
		setting: 'Настройки',
	},
	template: {
		addTemplate: 'Добавить шаблон',
		fillBlocks: 'Заполните все поля блока',
		fillRounds: 'Заполните все поля раунда',
	},
	olympic: {
		finishIn: 'Олимпиада заканчивается через',
		newTemplate: 'Новый шаблон Олимпиады',
		editTemplate: 'Изменение шаблона Олимпиады',
		tab: {
			CURRENT: 'Текущая',
			FUTURE: 'Запланированные',
			PASSED: 'Прошедшие',
			NEW: 'Новая олимпиада',
		},
		theDateOfThe: 'Дата проведения',
		name: 'Название',
		participate: 'Участвовали',
		paid: 'Оплатили',
		tested: 'Тестировали',
		show: 'Показать по',
		newOlympic: 'Новая Олимпиада',
		editOlympic: 'Изменить Олимпиаду',
		theTimeOfThe: 'Время проведения',
		price: 'Цена',
		add: 'Добавить',
		templateOfTasks: 'Шаблон заданий',
		cancelOlympic: 'Отменить шаблон',
		groupAge: 'Возрастные группы',
		levelDifficulty: 'Уровни сложности (для регистрации)',
		levelDifficultySimple: 'Уровни сложности',
		tasks: {
			column: 'Задание “СТОЛБЦЫ”',
			furious: 'Задание “ФОРСАЖ”',
		},
		round: 'Раунд',
		level: 'Уровень',
		columns: 'Столбцов',
		number: 'Число',
		difficulty: 'Сложность',
		timeRound: 'Время раунда (сек)',
		block: 'Блок',
		numberOfExample: 'К-во примеров',
		numberOfActions: 'К-во действий',
		speed: 'Скорость',
		addBlock: 'Добавить блок',
		addRound: 'Добавить раунд',
		saveTemplate: 'Сохранить шаблон',
		saveOlympiad: 'Сохранить Олимпиаду',
		cancelOlympiad: 'Отменить Олимпиаду',
		setting: {
			frontPage: 'Титульная страница',
			titleBold: 'Заголовок (жирный)',
			titleRegular: 'Заголовок (обычный)',
			descriptionText: 'Текст описания',
			timeRegion: 'Регион отсчёта времени',
			agreement: 'Договор об участии',
			testingOlympiad: 'Выставить текущую олимпиаду',
			numberOfTests: 'Количество тестирований',
			gallery: 'Фотогалерея на главной (1-3 файла)',
			cancel: 'Отменить',
			passwordSetting: 'Настройки пароля',
			oldPassword: 'Старый пароль',
			newPassword: 'Новый пароль',

		},

	},
	allFieldsAreRequired: 'все поля обязательны к заполнению',
	agree: {
		isDelete: 'Вы действительно хотите удалить эту Олимпиаду?',
	},
}
