export default {
	exit: 'Դուրս գալ',

	warning: `<div> Ուշադրությո՛ւն, </br> Դուք ունեք <strong> մեկ փորձ </strong> մուտքագրելու </br><strong>գրանցման ծածկագիրը</strong> </div>`,
	inputCode: 'Մուտքագրել գրանցման ծածկագիրը',
	startLater: 'Սկսել ավելի ուշ',
	olympiadIndigo: ' INDIGO առցանց օլիմպիադա',
	olympiadIndigoTest: 'INDIGO օլիմպիադայի փորձարկում',
	welcome: 'Բարի գալուստ օլիմպիադա',
	access: `Առաջադրանքներին հասանելիություն ունենալու համար Դուք պետք է մուտքագրեք <br/> <strong>էլ. փոստի հասցեն</strong> և <strong>եզակի ծածկագիրը</strong>`,
	accessTest: `Առաջադրանքների բաժին մուտք գործելու համար պետք է նշել <strong>էլ. փոստի հասցեն</strong>`,
	uniqueCode: 'Եզակի ծածկագիր',
	example: 'Օրինակ',
	auth: 'Մուտք գործել',
	authTest: 'Մուտք գործել',
	readyDescription: `Պատրա՞ստ ես </br> <strong> Մաղթում ենք հաջողությո՛ւն </strong>`,
	goToExecution: 'Անցնել կատարմանը',
	ready: 'Պատրաստվի՛ր',
	nextStage: 'Հաջորդ փուլ',
	finishStage: 'Հաջորդը',
	finishAtALl: 'Ավարտել	',

	start: 'Մեկնարկ',
	startTask: 'Սկսել առաջադրանքը',
	answerAllExample: 'Պատասխանել',
	inputAnswerInCell: `Պատասխանը մուտքագրեք վանդակում: <br/> Եթե չեք հասցնում հաշվել՝  մուտքագրեք ցանկացած թիվ`,
	youDidIt: 'Շնորհավորո՛ւմ ենք, Դուք անցել եք օլիմպիադան:',
	youDidItTest: 'Այո՜, թեստային առաջադրանքները կատարված են',
	yourRecord: 'Ձեր արդյունքը՝',
	points: 'Միավոր',
	pointsTest: 'Միավոր',
	downloadResult: 'Ներբեռնել արդյունքը',
	endParticipation: 'Ավարտել մասնակցությունը',
	congratulation: `Շնորհավորո՛ւմ ենք, Դուք անցել եք օլիմպիադան: <br/> Ձեր՝ մասնակցի դիպլոմը <strong>էլ.փոստում</strong> է: <br/> Սպասեք մրցույթի ավարտին <br/>Մաղթում ենք գրավել  մրցանակային տեղ:`,
	payment: `Շնորհավորում ենք, <strong> վճարումը հաջողված է։ </strong> </br> Խնդրում ենք, <strong>  ստուգել էլեկտրոնային փոստը</strong> </br> ( եթե նամակ չեք ստացվել, ապա ստուգեք սպամ կամ պրոմոակցիաներ բաժինները) </br>և սպասել օլիմպիադայի մեկնարկին։`,
	email: 'էլ. փոստի հասցե՝',
	attempt: 'Փորձ',
	takeParticipate: `Մասնակցեք <strong>օլիմպիադային</strong> և ստուգեք ձեր կարողությունները այլ մասնակիցների հետ մրցույթում:`,
	tryAgain: 'Փորձեք նորից',
	leaveTesting: 'Ելք',
	lookItUpResultPrev: `Ծանոթանալ օլիմպիադայի <strong>արդյունքներին</strong>`,
	showResult: 'Տեսնել արդյունքները',
	ONE: 'Միանիշ',
	TEN: 'Երկնիշ',
	HUNDRED: 'Եռանիշ',
	THOUSAND: 'Քառանիշ',
	TEN_THOUSANDS: 'Հնգանիշ',
	actions: 'գործողություն',
	action: 'գործողություն',
	speed: 'արագություն',
	sec: 'վրկ',
	mental: '“Մենթալ թվաբանություն”',
	prev: 'Հետ դեպի',
	next: 'Հետևում է',
	completedStage: 'Ամբողջական փուլ'
}
