export default {
	allCountry: 'Bütün ölkələr',
	allCities: 'Вütün şəhərlər',
	title: 'Qeydiyyat',

	label: {
		email: 'Email',
		lastName: 'Uşağın soyadı',
		firstName: 'Uşağın adı',
		gender: 'Cins',
		country: 'Ölkə',
		city: 'Şəhər',
		school: 'Mental Arifmetika',
		phone: 'Əlaqə nömrəsi',
		level: 'Mürəkkəbliy səviyyəsi',
		birthday: 'Doğum ili',

	},
	gender: {
		boy: 'Oğlan',
		girl: 'Qız',
		notSay: 'Göstərmək istəmirəm'

	},
	pay: 'Ödəmə etmək',
	select: {
		selectCountry: 'Bir ölkə seçin',
		selectCity: 'Səhəri seçin',
		selectYear: 'Doğum ilinizi seçin',
		selectLevel: 'Mürəkkəbliy səviyyəsini seçin'
	},
	notify: {
		title: 'Diqqət!',
		description: 'Yalnız doğru məlumatları qeyd edin. Bu məlumatlar Olimpiadanın nəticələrində dərc ediləcək, həmçinin diplomların hazırlanmasında istifadə olunacaq.',
	},
	notifyPhone: {
		title: 'Diqqət!',
		description: `Rahat və fasiləsiz fəaliyyəti təmin etmək məqsədilə Olimpiada yalnız planşetlərdə və kompüterlərdə keçilə bilər.`,
		hide: 'Oyuna keç',
	},
	errors: {
		globalError: 'Xahiş edirik yuxarıda bütün qrafları doldurasınız!',
		fieldIsRequired: 'Zəruri sahə',
		numeric: 'Sahədə yalnız rəqəmlər olmalıdır',
		minLength: 'Simvolların sayı\' -dan çox olmalıdır ',
		maxLength: 'Simvolların sayı\' -dan az olmalıdır',
		not_numeric: 'Simli nömrələr ola bilməz',
		email: 'Email etibarsız',
		password: 'Sahədə ən azı bir hərf və bir nömrə olmalıdır',
		notSymbol: 'Sahədə xüsusi simvollar olmamalıdır',

		//Olympiad
		INVALID_CODE: 'Yanlış kod',
		ALREADY_PARTICIPATED: 'Kodunu artıq istifadə etmisən! Yenidən olimpiadadan keçmək istəyirsənsə, təkrar qeydiyyatdan keçməlisən.',
		TIME_EXPIRED: 'Vaxt bitdi',
		FIRST_NAME_REQUIRED: '"Ad" sahəsini tələb edir',
		LAST_NAME_REQUIRED: 'Zəruri sahə "Soyad"',
		INVALID_EMAIL: 'Yanlış E-poçt',
		INVALID_PHONE: 'Yanlış Telefon',
		INVALID_YEAR: 'Yanlış yaş qrupu',
		INVALID_DIFFICULTY: 'Yanlış çətinlik səviyyəsi',
		INVALID_COUNTRY: 'Yanlış ölkə',
		NOT_AUTHENTICATED: 'İcazəli səhv',
		NOT_ADMIN: 'Siz administrator deyilsiniz',
		NOT_FOUND: 'Tapılmadı',
		SHORT_PHONE: "Təqdim olunan simli telefon nömrəsi olmaq üçün çox qısadır",
		'invalid phone': 'Yanlış telefon nömrəsi',
		LONG_PHONE: 'Verilən simli telefon nömrəsi olmaq üçün çox uzundur',
		'invalid email': "Yanlış email",
		DAILY_TESTS_LIMIT: 'Bu gün üçün cəhdlərin bitdi, sabah məşqlərə yenidən başlaya bilərsən',
		TEMPLATE_NOT_FOUND: 'Şablon tapılmadı'
	},
}
