export default {
	'01': {
		'name': 'Ianuarie',
		'short': 'Jan',
		'number': 1,
		'days': 31,
	},
	'02': {
		'name': 'Februarie',
		'short': 'Feb',
		'number': 2,
		'days': 28,
	},
	'03': {
		'name': 'Martie',
		'short': 'Mar',
		'number': 3,
		'days': 31,
	},
	'04': {
		'name': 'Aprilie',
		'short': 'Apr',
		'number': 4,
		'days': 30,
	},
	'05': {
		'name': 'Mai',
		'short': 'May',
		'number': 5,
		'days': 31,
	},
	'06': {
		'name': 'Iunie',
		'short': 'Jun',
		'number': 6,
		'days': 30,
	},
	'07': {
		'name': 'Iulie',
		'short': 'Jul',
		'number': 7,
		'days': 31,
	},
	'08': {
		'name': 'August',
		'short': 'Aug',
		'number': 8,
		'days': 31,
	},
	'09': {
		'name': 'Septembrie',
		'short': 'Sep',
		'number': 9,
		'days': 30,
	},
	'10': {
		'name': 'Octombrie',
		'short': 'Oct',
		'number': 10,
		'days': 31,
	},
	'11': {
		'name': 'Noiembrie',
		'short': 'Nov',
		'number': 11,
		'days': 30,
	},
	'12': {
		'name': 'Decembrie',
		'short': 'Dec',
		'number': 12,
		'days': 31,
	},
}
