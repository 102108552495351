export default {
	exit: 'Ieși',

	warning: `<div> Fii atent! </br> Există doar <strong> o încercare </strong> de a introduce </br> <strong> codul de înregistrare </strong> la pasul următor </div>`,
	inputCode: 'Introduceți codul de înregistrare',
	startLater: 'Începeți mai târziu',
	olympiadIndigo: 'Olimpiada online INDIGO',
	olympiadIndigoTest: 'Testarea Olimpiadei INDIGO',
	welcome: 'Bine ați venit la Olimpiadă!',
	access: `Pentru a accesa sarcinile, trebuie să specificați un <br/><strong>e-mail</strong> și <strong>un cod unic</strong>`,
	accessTest: `Trebuie să specificați <strong>e-mail</strong> pentru a accesa sarcinile`,
	uniqueCode: 'Cod unic',
	example: 'De exemplu',
	auth: 'Autentificare',
	authTest: 'Autorizare',
	readyDescription: `Sunteți gata? </br> <strong> Vă dorim succes! </strong>`,
	goToExecution: 'Accesați  pentru  a  efectua sarcinile',
	ready: 'Pregătește-te',
	nextStage: 'Etapa următoare',
	finishStage: 'Următorul',
	finishAtALl: 'Finisare',

	start: 'Start',
	startTask: 'Începeți sarcina',
	answerAllExample: 'Răspuns',
	inputAnswerInCell: `Introduceți răspunsul în celulă. <br/> Dacă nu aveți timp să numărați, introduceți orice număr.`,
	youDidIt: 'Felicitări, ai trecut Olimpiada!',
	youDidItTest: 'Ura, sarcina de testare s-a finisat!',
	yourRecord: 'Rezultatul dvs.:',
	points: 'Puncte',
	pointsTest: 'Puncte',
	downloadResult: 'Descărcați rezultatul',
	endParticipation: 'Finisați  participarea',
	congratulation: `Felicitări, ai trecut <strong>Olimpiada</strong>! <br/>Diploma de participant transmisă prin <strong>e-mail</strong>. <br/>Așteptați să se finiseze   competiția. <br/> Vrem să luăm premiul!`,
	payment: `Felicitări  ,<strong> plata </strong> a trecut cu  succes!  </br>Verificați  poșta electronică </br> (dacă nu ați  primit confirmare verificați în  <strong>Spam sau  Promoreduceri</strong>) </br>și  așteptați startul <strong> Olimpiadei. </strong>`,

	email: 'e-mail',
	attempt: 'Încercare',
	takeParticipate: `Participă la <strong>Olimpiadă</strong> și testează-ți abilitățile împotriva altora!`,
	tryAgain: 'Încercați din nou',
	leaveTesting: 'Eșire  din  Testare',
	lookItUpResultPrev: `Faceți cunoștință cu <strong>rezultatele</strong> Olimpiadei`,
	showResult: 'Vezi rezultatele',
	ONE: 'Unități',
	TEN: 'Zecimi',
	HUNDRED: 'Sutimi',
	THOUSAND: 'Cu patru cifre',
	TEN_THOUSANDS: 'Cinci cifre',
	actions: 'acțiune',
	action: 'acțiune',
	speed: 'viteză',
	sec: 'sec',
	mental: '“Aritmetica Mentală”',

	prev: 'Înapoi la',
	next: 'Ca urmare a',
	completedStage: 'Etapa completă'
}
