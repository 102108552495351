
export default {
	task: 'Առաջադրանքներ',
	col: 'Սյունակներ',
	forsage: 'Մաթեմատիկական թելադրություն (Արագ և Բուռն)',
	trans: 'Թարգմանություններ',

	timeIsOver: 'Timeամանակն անցել է',
	uploadForDetail: 'Ներբեռնեք ֆայլը "օլիմպիադայի մասին ավելի մանրամասն"',
	pasteFile: 'Կպցնել ֆայլը',
	openResult: 'Բացել արդյունքները',
	countParticipant: 'Կատեգորիայի անդամներ',
	place: 'Տեղ',
	fullName: 'Ազգանուն, Անուն',
	club: 'Club MA',
	currency:'Արժույթ',
	listIsEmpty: 'Listուցակը դատարկ է',
	notIndicated: 'Նշված չէ',
	yearNotIndicated: 'Սխալ. Տարիքային խմբերի ցուցակը դատարկ է',
	difficultyNotIndicated: 'Դժվարություն» ցուցակը դատարկ է',
	currencyText: 'Արժույթ',

	sidebar: {
		general: 'Տուն',
		constructor: 'Կոնստրուկտոր',
		analytic: 'Վերլուծություն',
		setting: 'Կարգավորումներ'
	},
	template: {
		addTemplate: 'Ավելացնել ձևանմուշ',
		fillBlocks: 'Ավելացնել ձևանմուշ',
		fillRounds: 'Լրացրու տուրի բոլոր դաշտերը'	},
	olympic: {
		finishIn: 'Օլիմպիական խաղերն ավարտվում են',
		newTemplate: 'Նոր օլիմպիադայի ձևանմուշ',
		editTemplate: 'Փոխելով օլիմպիադայի ձևանմուշը',
		tab: {
			CURRENT: 'Ընթացիկ',
			FUTURE: 'Պլանավորված',
			PASSED: 'Անցյալ',
			NEW: 'Նոր օլիմպիադա',
		},
		theDateOfThe: 'Միջոցառման ամսաթիվը',
		name: 'Անուն',
		participate: 'Մասնակցել',
		paid: 'Վճարված',
		tested: 'Փորձարկված',
		show: 'Ցուցադրել',
		newOlympic: 'Նոր օլիմպիական խաղեր',
		editOlympic: 'Փոփոխել օլիմպիական խաղերը',
		theTimeOfThe: 'Իրադարձության ժամանակը',
		price: 'Գինը',
		add: 'Ավելացնել',
		templateOfTasks: 'Առաջադրանքի ձևանմուշ',
		cancelOlympic: 'Չեղարկել կաղապարը',
		groupAge: 'Տարիքային խմբեր',
		levelDifficulty: 'Դժվարության մակարդակներ (գրանցման համար)',
		levelDifficultySimple: 'Բարդության մակարդակ',
		tasks: {
			column: 'Աշխատանք« Սյունակներ',
			furious: 'Արագ և Բուռն որոնում',
		},
		round: 'Կլոր',
		level: 'Մակարդակ',
		columns: 'Սյունակներ',
		number: 'Համարը',
		difficulty: 'Դժվարություն',
		timeRound: 'Կլոր ժամանակ (վրկ)',
		block: 'Արգելափակել',
		numberOfExample: 'Օրինակների քանակ',
		speed: 'Արագություն',
		addBlock: 'Ավելացնել բլոկ',
		addRound: 'Ավելացնել տուր',
		saveTemplate: 'Պահել ձևանմուշը',
		saveOlympiad: 'Փրկեք օլիմպիական խաղերը',
		cancelOlympiad: 'Չեղարկել օլիմպիադան',
		setting: {
			frontPage: 'Առաջին էջ',
			titleBold: 'Վերնագիր (թավ)',
			titleRegular: 'Վերնագիր (կանոնավոր)',
			descriptionText: 'Նկարագրության տեքստ',
			timeRegion: 'referenceամանակի տեղեկանք տարածաշրջան',
			agreement: 'Մասնակցության պայմանագիր',
			testingOlympiad: 'Թեստային օլիմպիադա',
			numberOfTests: 'Թեստերի քանակ',
			gallery: 'Հիմնական էջի պատկերասրահ (1-3 ֆայլ)',
			cancel: 'Չեղարկել',
			passwordSetting: 'Գաղտնաբառի կարգավորումներ',
			oldPassword: 'Հին գաղտնաբառ',
			newPassword: 'Նոր գաղտնաբառ',

		},

	},
	allFieldsAreRequired: 'բոլոր դաշտերը պարտադիր են',
	agree: {
		isDelete: 'Վստա՞հ եք, որ ցանկանում եք ջնջել այս օլիմպիադան',
	},
}
