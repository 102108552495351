import landing from './landing'
import forms from './forms'
import modal from './modal'
import admin from './admin/index'
import notification from './notification'
import olympiad from './olympiad/index'
import date from './date'

export default {
	landing,
	forms,
	modal,
	date,
	...olympiad,
	...admin,
	notification,
	fromNumber: ' из',
	loading: 'Загрузка...',
	show: 'Показать',
	category: 'Категория результатов',
	top: 'ТОП 10 в категории',
	saveResults: 'Скачать результаты',
	results: 'Результаты',
	checkoutResult: `Ознакомьтесь с <strong>результатами</strong> Олимпиады`,
	templates: 'Шаблоны заданий (по возрастным группам и уровням сложности)',
	mail: 'Email',
	password: 'Пароль',
	search: 'Поиск',
	logIn: 'Войти',
	lng: {
		ru: 'Русский',
		uk: 'Украинский',
		pl: 'Польский',
		en: 'Английский',
		ro: 'Румынский',
		az: 'Азербайджанский',
		hy: 'Армянский'

	},
	currency: {
		full: {
			PLN: 'Злотых',
			USD: 'Долларов',
			UAH: 'Гривен',
			RUB: 'Рублей',
			RON: 'Лей',
			AZN: 'Манат',
			AMD: 'Драм'

		},
		short: {
			PLN: 'Злт',
			USD: 'Дол',
			UAH: 'Грн',
			RUB: 'Руб',
			RON: 'Лей',
			AZN: 'Манат',
			AMD: 'Драм'
		},
	},
	demoLesson: 'Демо-урок',
	registration: 'Регистрация',
	startOlympic: 'Начать олимпиаду',
	franchise: 'Франшиза',
	personalArea: 'Личный кабинет',
	menu: 'Меню',
	backDown: 'Вернуться назад',
	giveAgree: 'Даю согласие',
	goHome: 'На главную',
	reload: 'Обновить',
	delete: 'Удалить',
	save: 'Сохранить',
	errors: {
		status: {
			404: `<p>Страница не найдена</p> <p>Начните с главной.</p>`,
			500: `<p>Произошла ошибка.</p> <p>Обновите страницу.</p>`,
			503: `<p>Произошла ошибка сервера.</p> <p>Обновите страницу.</p>`,
		},
	},
	condition: {
		fondy: 'Fondy договор',
		title: 'Перед оплатой ознакомьтесь с <strong> Условиями Мероприятия</strong>',
		description: 'Равным образом постоянный количественный рост и сфера нашей активности в значительной степени обуславливает создание направлений прогрессивного развития. Не следует, однако забывать, что начало повседневной работы по формированию позиции в значительной степени обуславливает создание форм развития. Разнообразный и богатый опыт укрепление и развитие структуры требуют от нас анализа существенных финансовых и административных условий. Равным образом начало повседневной работы по формированию позиции требуют определения и уточнения дальнейших направлений развития. Разнообразный и богатый опыт постоянное информационно-пропагандистское обеспечение нашей деятельности позволяет выполнять важные задания по разработке новых предложений. Повседневная практика показывает, что постоянный количественный рост и сфера нашей активности играет важную роль в формировании системы обучения кадров, соответствует насущным потребностям.<br/>' +
			'<br/>' +
			'Разнообразный и богатый опыт укрепление и развитие структуры требуют определения и уточнения дальнейших направлений развития. Разнообразный и богатый опыт реализация намеченных плановых заданий способствует подготовки и реализации соответствующий условий активизации. С другой стороны сложившаяся структура организации требуют определения и уточнения модели развития.<br/>' +
			'' +
			'Товарищи! сложившаяся структура организации способствует подготовки и реализации дальнейших направлений развития. Задача организации, в особенности же укрепление и развитие структуры играет важную роль в формировании существенных финансовых и административных условий. Товарищи! дальнейшее развитие различных форм деятельности позволяет оценить значение систем массового участия.</br>' +
			'<br/>' +
			'Разнообразный и богатый опыт укрепление и развитие структуры позволяет выполнять важные задания по разработке дальнейших направлений развития. Равным образом дальнейшее развитие различных форм деятельности в значительной степени обуславливает создание систем массового участия.<br/>' +
			'<br/>' +
			'Задача организации, в особенности же постоянное информационно-пропагандистское обеспечение нашей деятельности позволяет выполнять важные задания по разработке направлений прогрессивного развития. Разнообразный и богатый опыт сложившаяся структура организации играет важную роль в формировании дальнейших направлений развития.<br/>' +
			'<br/>' +
			'Товарищи! дальнейшее развитие различных форм деятельности требуют определения и уточнения направлений прогрессивного развития. Равным образом дальнейшее развитие различных форм деятельности требуют определения и уточнения модели развития. Задача организации, в особенности же укрепление <br/>',
	},

}
