import landing from "./landing";
import forms from './forms';
import modal from './modal';
import admin from './admin/index'
import notification from './notification'
import olympiad from './olympiad/index'
import date from './date.js'

export default {
  landing,
  forms,
  modal,
  notification,
  date,
  ...admin,
  ...olympiad,
  fromNumber: '-dən',
  loading: 'Yüklənir ...',
  test: 'Test',
  show: 'Göstərmək',
  category: 'Nəticələr kateqoriyası',
  top: 'Kateqoriyada ТOP 10-luq',
  saveResults: 'Nəticələri yükləyin',
  results: 'Nəticələr',
  checkoutResult: `Olimpiadanın <strong> nəticələri </strong> ilə tanış olun`,
  templates: 'İş şablonları (yaş qrupuna və çətinlik səviyyəsinə görə)',
  mail: 'Email',
  password: 'Şifrə',
  search: 'Axtarış',
  logIn: 'İçəri girmək',
  lng: {
    ru: "Rus dili",
    uk: "Ukrayna",
    pl: "Polşa",
    en: "İngilis dili",
    ro: "Rumıniya",
    az: "Azərbaycan dili",
    hy: 'Erməni'

  },
  currency: {
    full: {
      PLN: 'Zloty',
      USD: 'Dollar',
      UAH: 'UAH',
      RUB: 'Rubles',
      RON: 'Ley',
      AZN: 'AZN',
      AMD: 'Dram'

    },
    short: {
      PLN: 'ZL',
      USD: 'USD',
      UAH: 'UAH',
      RUB: 'RUB',
      RON: 'RON',
      AZN: 'AZN',
      AMD: 'Dram'

    },
  },
  demoLesson: "Demo dərs",
  registration: "Qeydiyyat",
  startOlympic: "Olimpiadaya Başla",
  franchise: 'Franşiza',
  personalArea: 'Şəxsi sahə',
  menu: "Menyu",
  backDown: "Geri qayıt",
  giveAgree: "Razıyam",
  goHome: 'Evə dön',
  reload: 'Yeniləmə',
  delete: 'Sil',
  save: 'Saxla',
  errors: {
    status: {
      404: `<p>Səhifə tapılmadı.</p> <p>Başdan oxuyun.</p>`,
      500: `<p>Baş tərəfdən öyrənin.</p> <p>Səhifəni yeniləyin.</p>`,
      503: `<p>Server qəbirə çevrildi.</p> <p>Səhifəni yeniləyin.</p>`,
    },
  },
  condition: {
    fondy: 'Fondy müqavilə',
    title: "Ödəniş etmədən əvvəl <strong> Tədbir şərtləri </strong> ni oxuyun",
    description: "Eynilə, fəaliyyətimizin davamlı kəmiyyət böyüməsi və əhatə dairəsi böyük ölçüdə mütərəqqi inkişaf istiqamətlərinin yaradılmasını müəyyənləşdirir. Bununla birlikdə, bir mövqe yaratmaq üçün gündəlik işin başlanğıcının inkişaf formalarının yaradılmasını böyük ölçüdə müəyyən etdiyini unutmamalıyıq. Strukturu gücləndirmək və inkişaf etdirmək üçün müxtəlif və zəngin təcrübə bizdən əhəmiyyətli maliyyə və inzibati şərtləri təhlil etməyimizi tələb edir, eyni zamanda bir vəzifə formalaşdırmaq üçün gündəlik işin başlanğıcı gələcək inkişaf istiqamətlərinin müəyyənləşdirilməsini və dəqiqləşdirilməsini tələb edir. zəngin təcrübə, fəaliyyətlərimizin davamlı məlumat və təbliğat dəstəyi yeni təkliflər hazırlamaq üçün vacib tapşırıqları yerinə yetirməyimizə imkan verir ki, daimi kəmiyyət artımı və fəaliyyətimizin əhatə dairəsi kadr hazırlığı sisteminin formalaşmasında vacib rol oynayır. ehtiyacları. <br/> "+
      <br/> +
      "Quruluşun müxtəlif və zəngin təcrübəsi, möhkəmləndirilməsi və inkişafı gələcək inkişaf istiqamətlərinin müəyyənləşdirilməsini və dəqiqləşdirilməsini tələb edir. Planlaşdırılmış hədəflərin həyata keçirilməsinin müxtəlif və zəngin təcrübəsi aktivləşdirmə üçün uyğun şərtlərin hazırlanmasına və həyata keçirilməsinə kömək edir. Digər tərəfdən, təşkilatın mövcud quruluşu inkişaf modelinin tərifini və dəqiqləşdirilməsini tələb edir. <br/> "+
      <br/> +
      "Yoldaşlar, təşkilatın mövcud strukturu gələcək inkişaf istiqamətlərinin hazırlanmasına və həyata keçirilməsinə öz töhfəsini verir. Təşkilatın vəzifəsi, xüsusən strukturun möhkəmləndirilməsi və inkişafı mühüm maliyyə və inzibati şərtlərin formalaşmasında mühüm rol oynayır . Yoldaşlar, müxtəlif fəaliyyət formalarının daha da inkişafı bizə kütləvi iştirak sistemlərinin əhəmiyyətini qiymətləndirməyə imkan verir. <br/> "+
      <br/> +
      "Strukturun möhkəmləndirilməsi və inkişaf etdirilməsində müxtəlif və zəngin təcrübə gələcək inkişaf istiqamətlərinin inkişafı üçün vacib tapşırıqları yerinə yetirməyimizə imkan verir. Eyni zamanda, müxtəlif fəaliyyət formalarının daha da inkişafı kütləvi iştirak sistemlərinin yaradılmasını böyük ölçüdə müəyyənləşdirir. <Br / > "+
      <br/> +
      "Təşkilatın vəzifəsi, xüsusən də fəaliyyətlərimizə davamlı informasiya və təbliğat dəstəyi, mütərəqqi inkişaf istiqamətlərini inkişaf etdirmək üçün vacib tapşırıqları yerinə yetirməyimizə imkan verir. Müxtəlif və zəngin təcrübə, təşkilatın mövcud quruluşunun formalaşmasında mühüm rol oynayır gələcək inkişaf istiqamətləri. <br/> "+
      <br/> +
      "Yoldaşlar, müxtəlif fəaliyyət formalarının daha da inkişafı mütərəqqi inkişaf istiqamətlərinin müəyyənləşdirilməsini və dəqiqləşdirilməsini tələb edir. Eynilə, müxtəlif fəaliyyət formalarının daha da inkişafı inkişaf modelinin müəyyənləşdirilməsi və dəqiqləşdirilməsini tələb edir. Təşkilatın vəzifəsi, xüsusən gücləndirmək."
  },
}
