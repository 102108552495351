export default {
	allCountry: 'Բոլոր երկրները',
	allCities: 'բոլոր քաղաքները',
	title: 'գրանցվել',
	label: {
		email: 'Էլ. փոստ',
		lastName: 'Երեխայի ազգանուն',
		firstName: 'Երեխայի անուն',
		gender: 'Սեռ',
		country: 'Երկիր',
		city: 'Քաղաք',
		school: 'Մենթալ թվաբանության դպրոց',
		phone: 'Կոնտակտային հեռախոսահամար',
		level: 'Բարդության մակարդակ',
		birthday: 'Ծննդյան տարի',
	},
	gender: {
		boy: 'Արական',
		girl: 'Իգական',
		notSay: 'Չեմ ցանկանում նշել'
	},
	pay: 'Վճարել',
	select: {
		selectCountry: 'Ընտրեք երկիր',
		selectCity: 'Ընտրիր քաղաք',
		selectYear: 'Ընտրել ծննդյան տարին',
		selectLevel: 'Ընտրել բարդության մակարդակը',

	},
	notify: {
		title: 'Ուշադրությո՛ւն',
		description: 'Խնդրում ենք մուտքագրել միայն իրական տվյալներ: Տվյալները կհրապարակվեն օլիմպիադայի արդյունքներում, ինչպես նաև կօգտագործվեն դիպլոմների ստեղծման ժամանակ:',
	},
	notifyPhone: {
		title: 'Ուշադրությո՛ւն,',
		description: `հարմար և անխափան ընթացքի համար օլիմպիադային մասնակցելը հասանելի է միայն պլանշետով և համակարգչով:`,
		hide: 'Oyuna keç',
	},
	errors: {
		globalError: 'Խնդրում ենք լրացնել վերևում նշված բոլոր դաշտերը',
		fieldIsRequired: 'Պահանջվող դաշտ',
		numeric: 'Դաշտը պետք է պարունակի միայն թվեր',
		minLength: 'Նիշերի քանակը պետք է ավելի մեծ լինի, քան ',
		maxLength: 'Նիշերի քանակը պետք է պակաս լինի ',

		not_numeric: 'Լարը չի կարող թվեր պարունակել',
		email: 'Էլ. փոստի հասցե (Email)',
		password: 'Դաշտը պետք է պարունակի առնվազն մեկ տառ և մեկ թիվ',
		notSymbol: 'Դաշտը չպետք է պարունակի հատուկ նիշեր',

		//Olympiad
		INVALID_CODE: 'Անվավեր ծածկագիր',
		ALREADY_PARTICIPATED: 'Դուք արդեն օգտագործել եք Ձեր ծածկագիրը: Եթե ​​ցանկանում եք կրկին անցնել օլիմպիադան, Դուք պետք է կրկին գրանցվեք:',
		TIME_EXPIRED: 'Timeամանակն անցել է',
		FIRST_NAME_REQUIRED: 'Պահանջում է« Անուն »դաշտը',
		LAST_NAME_REQUIRED: 'Պարտադիր դաշտ "Ազգանուն"',
		INVALID_EMAIL: 'Անվավեր էլփոստ',
		INVALID_PHONE: 'Անվավեր հեռախոս',
		INVALID_YEAR: 'Անվավեր տարիքային խումբ',
		INVALID_DIFFICULTY: 'Դժվարության անվավեր մակարդակ',
		INVALID_COUNTRY: 'Անվավեր երկիր',
		NOT_AUTHENTICATED: 'Լիազորված սխալ',
		NOT_ADMIN: 'Դուք ադմինիստրատոր չեք',
		NOT_FOUND: 'Չի գտնվել',
		SHORT_PHONE: 'Նշված տողը չափազանց կարճ է հեռախոսահամար լինելու համար',
		'invalid phone': 'Անվավեր հեռախոսահամար',
		LONG_PHONE: 'Նշված տողը չափազանց երկար է հեռախոսահամար լինելու համար',
		'invalid email': 'Էլ. փոստի անվավեր հասցե',
		DAILY_TESTS_LIMIT: 'Այսօրվա համար Ձեր փորձերն ավարտվել են, վաղը կարող եք սկսել մարզվել նորից',
		TEMPLATE_NOT_FOUND: 'Ձևանմուշը չի գտնվել'

	},
}
