import landing from './landing'
import forms from './forms'
import modal from './modal'
import admin from './admin/index'
import notification from './notification'
import olympiad from './olympiad/index'
import date from './date'

export default {
	landing,
	forms,
	modal,
	notification,
	date,
	...admin,
	...olympiad,
	fromNumber: ' z',
	loading: 'Ładowanie...',
	show: 'Pokaż',
	category: 'Kategoria wyników',
	top: 'TOP 10 w kategoriach',
	saveResults: 'Pobierz wyniki',
	results: 'Wyniki',
	checkoutResult: `Zapoznaj się z <strong>wynikami</strong> igrzysk olimpijskich`,
	templates: 'Szablony ofert pracy (według grupy wiekowej i poziomu trudności)',
	mail: 'Email',
	password: 'Hasło',
	search: 'Szukaj',
	logIn: 'Zaloguj',
	lng: {
		ru: 'Rosyjski',
		uk: 'Ukraiński',
		pl: 'Polski',
		en: 'Angielski',
		ro: 'Rumuński',
		az: 'Azerbejdżański',
		hy: 'Ormiański'

	},
	currency: {
		full: {
			PLN: 'Złoty',
			USD: 'Dolary',
			UAH: 'UAH',
			RUB: 'Rubles',
			RON: 'Lei',
			AZN: 'AZN',
			AMD: 'Dram'

		},
		short: {
			PLN: 'Zł',
			USD: 'USD',
			UAH: 'UAH',
			RUB: 'RUB',
			RON: 'RON',
			AZN: 'AZN',
			AMD: 'Dram'

		},
	},
	demoLesson: 'Lekcja demonstracyjna',
	registration: 'Rejestracja',
	startOlympic: 'Rozpocznij',
	franchise: 'Franczyza',
	personalArea: 'Obszar osobisty',
	menu: 'Menu',
	backDown: 'Wróć',
	giveAgree: 'Zgadzam się',
	goHome: 'Na głowie',
	reload: 'Onoviti',
	delete: 'Usunąć',
	save: 'Zapisać',
	errors: {
		status: {
			404: `<p>Strona nie jest znana.</p> <p>Dowiedz się od strony głowy.</p>`,
			500: `<p>Stał się grobem.</p> <p>Zaktualizuj stronę.</p>`,
			503: `<p>Serwer stał się grobem.</p> <p>Zaktualizuj stronę.</p>`,
		},
	},
	condition: {
		fondy: 'Fondy kontrakt',
		title: 'Przeczytaj <strong> Warunki Olimpiady </strong> przed dokonaniem płatności',
		description: 'Podobnie, stały wzrost ilościowy i zakres naszej działalności w dużej mierze determinuje tworzenie obszarów progresywnego rozwoju. Nie należy jednak zapominać, że rozpoczęcie codziennej pracy nad utworzeniem stanowiska w dużej mierze determinuje tworzenie form rozwoju. Różnorodne i bogate doświadczenie wzmacnianie i rozwijanie struktury wymagają od nas analizy istotnych uwarunkowań finansowych i administracyjnych, a rozpoczęcie codziennej pracy nad utworzeniem stanowiska wymaga określenia i doprecyzowania dalszych kierunków rozwoju.Różnorodne i bogate doświadczenie, ciągłe wsparcie informacyjne i propagandowe naszych działań pozwala nam na wykonanie ważnych zadań w celu opracowania nowych propozycji. że stały wzrost ilościowy i zakres naszej działalności odgrywa ważną rolę w kształtowaniu systemu szkolenia personelu, spełnia pilne potrzeby. <br/> ' +
			'<br/>' +
			'Różnorodne i bogate doświadczenie, wzmacnianie i rozbudowa struktury wymaga określenia i doprecyzowania dalszych kierunków rozwoju. Różnorodne i bogate doświadczenie w realizacji zaplanowanych celów sprzyja przygotowaniu i wdrożeniu odpowiednich warunków aktywizacji. Z drugiej strony istniejąca struktura organizacji wymaga zdefiniowania i dopracowania modelu rozwoju. <br/>' +
			'<br/>' +
			'Towarzysze, istniejąca struktura organizacji przyczynia się do przygotowania i realizacji dalszych kierunków rozwoju. Zadanie organizacji, w szczególności wzmocnienie i rozwój struktury, odgrywa ważną rolę w kształtowaniu istotnych warunków finansowych i administracyjnych. Towarzysze, dalszy rozwój różnych form działalności pozwala ocenić wagę systemów partycypacji masowej. <br/>' +
			'<br/>' +
			'Różnorodne i bogate doświadczenie we wzmacnianiu i rozwijaniu konstrukcji pozwala nam na realizację ważnych zadań dla rozwoju dalszych kierunków rozwoju. Równocześnie dalszy rozwój różnych form działalności w dużej mierze determinuje tworzenie systemów partycypacji masowej. <br/>' +
			'<br/>' +
			'Zadanie organizacji, w szczególności stałe wsparcie informacyjne i propagandowe naszych działań, pozwala nam na realizację ważnych zadań w celu wypracowania kierunków progresywnego rozwoju. Różnorodne i bogate doświadczenie, istniejąca struktura organizacji odgrywa istotną rolę w kształtowaniu dalszych kierunków rozwoju. <br/>' +
			'<br/>' +
			'Towarzysze, dalszy rozwój różnych form działalności wymaga określenia i doprecyzowania kierunków stopniowego rozwoju. Równocześnie dalszy rozwój różnych form działalności wymaga zdefiniowania i dopracowania modelu rozwoju. Zadanie organizacji, zwłaszcza wzmacnianie',
	},



}
