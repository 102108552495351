export default {
	'01': {
		'name': 'Styczeń',
		'short': 'Jan',
		'number': 1,
		'days': 31,
	},
	'02': {
		'name': 'Luty',
		'short': 'Feb',
		'number': 2,
		'days': 28,
	},
	'03': {
		'name': 'Marsz',
		'short': 'Mar',
		'number': 3,
		'days': 31,
	},
	'04': {
		'name': 'Kwiecień',
		'short': 'Apr',
		'number': 4,
		'days': 30,
	},
	'05': {
		'name': 'Może',
		'short': 'May',
		'number': 5,
		'days': 31,
	},
	'06': {
		'name': 'Czerwiec',
		'short': 'Jun',
		'number': 6,
		'days': 30,
	},
	'07': {
		'name': 'Lipiec',
		'short': 'Jul',
		'number': 7,
		'days': 31,
	},
	'08': {
		'name': 'Sierpień',
		'short': 'Aug',
		'number': 8,
		'days': 31,
	},
	'09': {
		'name': 'Wrzesień',
		'short': 'Sep',
		'number': 9,
		'days': 30,
	},
	'10': {
		'name': 'Październik',
		'short': 'Oct',
		'number': 10,
		'days': 31,
	},
	'11': {
		'name': 'Listopad',
		'short': 'Nov',
		'number': 11,
		'days': 30,
	},
	'12': {
		'name': 'Grudzień',
		'short': 'Dec',
		'number': 12,
		'days': 31,
	},
}
