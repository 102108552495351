export default {
	task: 'Tapşırıqlar',
	col: 'Sütunlar',
	forsage: 'Riyazi dikte (Tez və qəzəbli)',
	trans: 'Tərcümə',
	timeIsOver: 'Vaxt bitdi',
	uploadForDetail: 'Olimpiada haqqında daha çox məlumat üçün faylı yükləyin',
	pasteFile: 'Fayl yapışdır',
	openResult: 'Açıq nəticələr',
	countParticipant: 'Bir kateqoriyadakı üzvlər',
	place: 'Yer',
	fullName: 'Soyad, ad',
	club: 'Club MA',
	currencyText: 'Valyuta',
	listIsEmpty: 'Siyahı boşdur',
	notIndicated: 'Göstərilməyib',
	yearNotIndicated: 'Səhv. Yaş qrupları siyahısı boşdur',
	difficultyNotIndicated: 'Səhv. Çətinlik siyahısı boşdur',
	sidebar: {
		general: 'Ümumi',
		constructor: 'Konstruktor',
		analytic: 'Analitik',
		setting: 'Ayar',
	},
	template: {
		addTemplate: 'Şablon əlavə et',
		fillBlocks: 'Blokun bütün sahələrini doldurun',
		fillRounds: 'Turun bütün sahələrini doldurun\'',
	},
	olympic: {
		finishIn: 'Olimpiadalar sona çatır',
		newTemplate: 'Yeni Olimpiada şablonu',
		editTemplate: 'Olimpiada şablonunun dəyişdirilməsi',
		tab: {
			CURRENT: 'Cari',
			FUTURE: 'Planlaşdırıldı',
			PASSED: 'Keçmiş',
			NEW: 'Yeni Olimpiada',
		},
		theDateOfThe: 'Tarixi',
		name: 'Ad',
		participate: 'Iştirak etdi',
		paid: 'Ödənişli',
		tested: 'Ödənişli',
		show: 'Göstərmək',
		newOlympic: 'Yeni Olimpiya',
		editOlympic: 'Düzəliş Olimpiya',

		theTimeOfThe: 'Vaxt sərf',
		price: 'Qiymət',
		add: 'Əlavə et',
		templateOfTasks: 'Tapşırıq şablonu',
		cancelOlympic: 'Şablonu ləğv edin',
		groupAge: 'Yaş qrupları',
		levelDifficulty: 'Çətinlik səviyyəsi (qeydiyyat üçün)',
		levelDifficultySimple: 'Çətinlik Səviyyələri',

		tasks: {
			column: 'Görev "Sütunlar',
			furious: 'Tez və Furious Quest',
		},
		round: 'Dəyirmi',
		level: 'Səviyyə',
		columns: 'Sütunlar',
		number: 'Nömrə',
		difficulty: 'Mürəkkəblik',
		timeRound: 'Dəyirmi vaxt (saniyə)',
		block: 'Blok',
		numberOfExample: 'Nümunələrin sayı',
		speed: 'Sürət',
		addBlock: 'Blok əlavə et',
		addRound: 'Dəyirmi əlavə et',

		saveTemplate: 'Şablonu Saxla',
		saveOlympiad: 'Olimpiadaları saxla	',
		cancelOlympiad: 'Olimpiadaları ləğv edin',
		setting: {
			frontPage: 'Ön səhifə',
			titleBold: 'Başlıq (qalın)',
			titleRegular: 'Başlıq (müntəzəm)',
			descriptionText: 'Təsvir mətni',
			timeRegion: 'Saat bölgəsi',
			agreement: 'İştirak müqaviləsi',
			testingOlympiad: 'Olimpiadanı sınaqdan keçirtmək',
			numberOfTests: 'Testlərin sayı',
			gallery: 'Əsas səhifədəki foto qalereya (1-3 sənəd)',
			cancel: 'Ləğv et',
			passwordSetting: 'Parol parametrləri',
			oldPassword: 'Köhnə şifrə',
			newPassword: 'Yeni parol',

		},

	},
	allFieldsAreRequired: 'bütün sahələr tələb olunur',
	agree: {
		isDelete: 'Bu olimpiadanı silmək istədiyinizə əminsiniz?',
	},
}
