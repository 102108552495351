import Preloader from "components/Preloader";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import "./assets/styles/normalize.css";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistratration";
import { availableNewVersion } from "./api/apollo/state";
import * as Sentry from "@sentry/react";
import Main from "./Main";
//import reportWebVitals from './reportWebVitals';
require("dotenv").config();
Sentry.init({ dsn: "https://28fa09fc0d21aeb5629738a434f6c78f@o4505507149840384.ingest.sentry.io/4506355615203328" });

ReactDOM.render(
  <React.StrictMode>
    <div>
      <Suspense fallback={<Preloader />}>
        <Main />
      </Suspense>
    </div>
  </React.StrictMode>,
  document.getElementById("root")
);
// @ts-ignore
const ignored = window.self.__WB_MANIFEST || {};

serviceWorkerRegistration.register({
  onUpdate: (registration: any) => {
    availableNewVersion(true);
  },
});
//reportWebVitals(console.log);
