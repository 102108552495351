import React, { FC } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { storage } from 'utils/localStorage'
import { RouteProps } from 'react-router'


const RouteWrapper: FC<{ component: JSX.Element | any, needToken?: boolean, whenRedirect?: any, pathRedirect?: any } & RouteProps> = (
	{ component: Component, needToken = false, whenRedirect, pathRedirect = '/', ...rest }): JSX.Element => {

	const token = storage('token')
	/**
	 * Redirect user to SignIn page if he tries to access a private      route
	 * without authentication.
	 */
	if (needToken && !token) {
		return <Redirect to={'/'} />
	}
	if (whenRedirect) {
		return <Redirect to={pathRedirect} />
	}
	/**
	 * If not included on both previous cases, redirect user to the desired route.
	 */
	return <Route {...rest} component={Component} />
}
export default RouteWrapper
