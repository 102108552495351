import {memo} from "react";

export const FacebookIcon = memo(()=> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.51562 24H11.3438V15.4688H8.53125V12.6562H11.3438V9.14062C11.3438 7.20209 12.9208 5.625 14.8594 5.625H18.375V8.4375H15.5625C14.787 8.4375 14.1562 9.0683 14.1562 9.84375V12.6562H18.2481L17.7794 15.4688H14.1562V24H20.4844C22.4229 24 24 22.4229 24 20.4844V3.51562C24 1.57709 22.4229 0 20.4844 0H3.51562C1.57709 0 0 1.57709 0 3.51562V20.4844C0 22.4229 1.57709 24 3.51562 24ZM1.40625 3.51562C1.40625 2.35254 2.35254 1.40625 3.51562 1.40625H20.4844C21.6475 1.40625 22.5938 2.35254 22.5938 3.51562V20.4844C22.5938 21.6475 21.6475 22.5938 20.4844 22.5938H15.5625V16.875H18.9706L19.9081 11.25H15.5625V9.84375H19.7812V4.21875H14.8594C12.1454 4.21875 9.9375 6.42664 9.9375 9.14062V11.25H7.125V16.875H9.9375V22.5938H3.51562C2.35254 22.5938 1.40625 21.6475 1.40625 20.4844V3.51562Z" fill="white"/>
  </svg>
)
export const YoutubeIcon = memo(()=> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.51562 21.1406H20.4844C22.4229 21.1406 24 19.5635 24 17.625V6.375C24 4.43646 22.4229 2.85938 20.4844 2.85938H3.51562C1.57709 2.85938 0 4.43646 0 6.375V17.625C0 19.5635 1.57709 21.1406 3.51562 21.1406ZM1.40625 6.375C1.40625 5.21191 2.35254 4.26562 3.51562 4.26562H20.4844C21.6475 4.26562 22.5938 5.21191 22.5938 6.375V17.625C22.5938 18.7881 21.6475 19.7344 20.4844 19.7344H3.51562C2.35254 19.7344 1.40625 18.7881 1.40625 17.625V6.375Z" fill="white"/>
    <path d="M8.48438 7.28955V16.8473L16.9453 11.9901L8.48438 7.28955ZM9.89062 9.67944L14.0859 12.0102L9.89062 14.4186V9.67944Z" fill="white"/>
  </svg>
)
export const InstagramIcon = memo(()=> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.51562 24H20.4844C22.4229 24 24 22.4229 24 20.4844V3.51562C24 1.57709 22.4229 0 20.4844 0H3.51562C1.57709 0 0 1.57709 0 3.51562V20.4844C0 22.4229 1.57709 24 3.51562 24ZM1.40625 3.51562C1.40625 2.35254 2.35254 1.40625 3.51562 1.40625H20.4844C21.6475 1.40625 22.5938 2.35254 22.5938 3.51562V20.4844C22.5938 21.6475 21.6475 22.5938 20.4844 22.5938H3.51562C2.35254 22.5938 1.40625 21.6475 1.40625 20.4844V3.51562Z" fill="white"/>
    <path d="M12 18.3281C15.4893 18.3281 18.3281 15.4893 18.3281 12C18.3281 8.51074 15.4893 5.67188 12 5.67188C8.51074 5.67188 5.67188 8.51074 5.67188 12C5.67188 15.4893 8.51074 18.3281 12 18.3281ZM12 7.07812C14.714 7.07812 16.9219 9.28601 16.9219 12C16.9219 14.714 14.714 16.9219 12 16.9219C9.28601 16.9219 7.07812 14.714 7.07812 12C7.07812 9.28601 9.28601 7.07812 12 7.07812Z" fill="white"/>
    <path d="M19.0312 7.07812C20.1943 7.07812 21.1406 6.13184 21.1406 4.96875C21.1406 3.80566 20.1943 2.85938 19.0312 2.85938C17.8682 2.85938 16.9219 3.80566 16.9219 4.96875C16.9219 6.13184 17.8682 7.07812 19.0312 7.07812ZM19.0312 4.26562C19.4189 4.26562 19.7344 4.58112 19.7344 4.96875C19.7344 5.35638 19.4189 5.67188 19.0312 5.67188C18.6436 5.67188 18.3281 5.35638 18.3281 4.96875C18.3281 4.58112 18.6436 4.26562 19.0312 4.26562Z" fill="white"/>
  </svg>
)