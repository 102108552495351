import landing from "./landing";
import forms from './forms'
import modal from './modal'
import admin from './admin/index'
import notification from './notification'
import olympiad from './olympiad/index'
import date from './date'

export default {
  landing,
  forms,
  modal,
  notification,
  date,
  ...admin,
  ...olympiad,
  fromNumber: ' із',
  test: 'Тест укр',
  loading: 'Загрузка...',
  show: 'Показати',
  category: 'Категорія результатів',
  top: 'ТОП 10 в категоріях',
  saveResults: 'Завантажити результати',
  results: 'Результати',
  checkoutResult: `Ознайомтеся з <strong> результатами </strong> Олімпіади`,
  templates: 'Шаблони завдань (по віковим групам і рівнями складності)',
  mail: 'Email',
  password: 'Пароль',
  search: 'Пошук',
  logIn: 'Увійти',
  lng: {
    ru: "Російський",
    uk: "Українська",
    pl: "Польський",
    en: "Англійський",
    ro: "Румунський",
    az: 'Азербайджанський',
    hy: 'Вірменський'


  },
  currency: {
    full: {
      PLN: 'Злотих',
      USD: 'Доларів',
      UAH: 'Гривень',
      RUB: 'Рублів',
      RON: 'Лей',
      AZN: 'Манат',
      AMD: 'Драм'


    },
    short: {
      PLN: 'Злт',
      USD: 'Дол',
      UAH: 'Грн',
      RUB: 'Руб',
      RON: 'Лей',
      AZN: 'Манат',
      AMD: 'Драм'


    },
  },
  demoLesson: "Демо-урок",
  registration: "Реєстрація",
  startOlympic: "Почати олімпіаду",
  franchise: 'Франшиза',
  personalArea: 'Особистий кабінет',
  menu: "Меню",
  backDown: "Повернутись назад",
  giveAgree: "Даю згоду",
  goHome: 'На головну',
  reload: 'Оновити',
  delete: 'Видалити',
  save: 'Зберегти',
  errors: {
    status: {
      404: `<p>Сторінка не знайдена</p> <p>Почніть з головної.</p>`,
      500: `<p>Сталася помилка.</p> <p>Оновіть сторінку.</p>`,
      503: `<p>Сталася помилка сервера.</p> <p>Оновіть сторінку.</p>`,
    },
  },
  condition: {
    fondy: 'Fondy договір',
    title: "Перед оплатою ознайомтеся з <strong> Умовами Заходу </strong>",
    description: "Так само постійний кількісний ріст і сфера нашої активності в значній мірі обумовлює створення напрямків прогресивного розвитку. Не слід, однак забувати, що початок повсякденної роботи по формуванню позиції в значній мірі обумовлює створення форм розвитку. Різноманітний і багатий досвід зміцнення і розвиток структури вимагають від нас аналізу істотних фінансових і адміністративних умов. Так само початок повсякденної роботи по формуванню позиції вимагають визначення та уточнення подальших напрямів розвитку. Різноманітний і багатий досвід постійне інформаційно-пропагандистське забезпечення нашої діяльності дозволяє виконувати важливі завдання по розробці нових пропозицій. Повсякденна практика показує, що постійний кількісний ріст і сфера нашої активності відіграє важливу роль у формуванні системи навчання кадрів, відповідає нагальним потребам. <br/> "+
      "<br/>" +
      "Різноманітний і багатий досвід зміцнення і розвиток структури вимагають визначення та уточнення подальших напрямів розвитку. Різноманітний і багатий досвід реалізація намічених планових завдань сприяє підготовки та реалізації відповідний умов активізації. З іншого боку склалася структура організації вимагають визначення та уточнення моделі розвитку. <br/>" +
      "<br/>" +
      "Товариші! Склалася структура організації сприяє підготовки і реалізації подальших напрямків розвитку. Завдання організації, особливо ж зміцнення і розвиток структури відіграє важливу роль у формуванні істотних фінансових і адміністративних умов. Товариші! Подальший розвиток різних форм діяльності дозволяє оцінити значення систем масової участі. <br/> "+
      "<br/>" +
      "Різноманітний і багатий досвід зміцнення і розвиток структури дозволяє виконувати важливі завдання по розробці подальших напрямків розвитку. Так само подальший розвиток різних форм діяльності в значній мірі обумовлює створення систем масового участі. <br/>" +
      "<br/>" +
      "Завдання організації, особливо ж постійне інформаційно-пропагандистське забезпечення нашої діяльності дозволяє виконувати важливі завдання по розробці напрямків прогресивного розвитку. Різноманітний і багатий досвід склалася структура організації відіграє важливу роль у формуванні подальших напрямків розвитку. <br/>" +
      "<br/>" +
      "Товариші! Подальший розвиток різних форм діяльності вимагають визначення та уточнення напрямків прогресивного розвитку. Так само подальший розвиток різних форм діяльності вимагають визначення та уточнення моделі розвитку. Завдання організації, особливо ж зміцнення"
  },

}
