import { makeVar } from '@apollo/client'
import { ObtainJsonWebToken } from '../generated'

export const showMenuMobile = makeVar<boolean>(false)
export const contestId = makeVar(null)
export const lastContestId = makeVar<string | null>(null)
export const authToken = makeVar<Pick<ObtainJsonWebToken, 'payload' | 'refreshExpiresIn' | 'token'> | null>(null)
export const networkError = makeVar<null | any>(null)
export const congratulation = makeVar<boolean>(false)
export const availableNewVersion = makeVar<boolean>(false)
export const CODE_AUTH = makeVar<string>('')
export const TEST_CODE = makeVar<string>('')
export const TIMEZONE = makeVar<any>('')
export const paymentId = makeVar<any>('')
export const TESTS_DAILY = makeVar<any>(null)
export const TESTS_PLAYED_TODAY = makeVar<any>(null)
export const PARTICIPANT_EMAIL = makeVar<any>(null)
