import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   * 
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any;
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
};

export type Query = {
  __typename?: 'Query';
  configuration?: Maybe<ConfigurationNode>;
  order?: Maybe<Order>;
  countries?: Maybe<Array<Maybe<CountryNode>>>;
  country?: Maybe<CountryNode>;
  /** Cities by country and language */
  cities?: Maybe<Array<Maybe<CityNode>>>;
  contests?: Maybe<ContestsResponseNode>;
  currentContest?: Maybe<ContestNode>;
  contest?: Maybe<ContestNode>;
  getExercise?: Maybe<ExerciseResponseNode>;
  contestResults?: Maybe<Array<Maybe<ParticipantNode>>>;
  participants?: Maybe<ParticipantsNode>;
  difficulties?: Maybe<Array<Maybe<DifficultyNode>>>;
  getParticipant?: Maybe<ParticipantNode>;
  templates?: Maybe<Array<Maybe<TemplateNode>>>;
  rounds?: Maybe<Array<Maybe<RoundNode>>>;
  blocks?: Maybe<Array<Maybe<BlockNode>>>;
  template?: Maybe<TemplateNode>;
};


export type QueryConfigurationArgs = {
  lang: Scalars['String'];
};


export type QueryOrderArgs = {
  orderId: Scalars['ID'];
};


export type QueryCountriesArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type QueryCountryArgs = {
  lang?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['ID']>;
};


export type QueryCitiesArgs = {
  lang?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Int']>;
};


export type QueryContestsArgs = {
  lang: Scalars['String'];
  state?: Maybe<ContestStateEnum>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sorting?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryCurrentContestArgs = {
  lang: Scalars['String'];
};


export type QueryContestArgs = {
  uid: Scalars['UUID'];
};


export type QueryGetExerciseArgs = {
  code?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
};


export type QueryContestResultsArgs = {
  contestId: Scalars['UUID'];
  yearName: Scalars['String'];
  cityId?: Maybe<Scalars['ID']>;
  countryId?: Maybe<Scalars['ID']>;
  difficultyName: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryParticipantsArgs = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  contestId: Scalars['UUID'];
  difficultyName?: Maybe<Scalars['ID']>;
  yearName?: Maybe<Scalars['ID']>;
  lang: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  sorting?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryDifficultiesArgs = {
  yearName: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryGetParticipantArgs = {
  email: Scalars['String'];
  code: Scalars['String'];
};


export type QueryTemplatesArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type QueryTemplateArgs = {
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
};

export type ConfigurationNode = {
  __typename?: 'ConfigurationNode';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  terms?: Maybe<Scalars['String']>;
  termsRu?: Maybe<Scalars['String']>;
  termsUk?: Maybe<Scalars['String']>;
  termsEn?: Maybe<Scalars['String']>;
  termsPl?: Maybe<Scalars['String']>;
  termsRo?: Maybe<Scalars['String']>;
  termsAz?: Maybe<Scalars['String']>;
  termsHy?: Maybe<Scalars['String']>;
  privacy?: Maybe<Scalars['String']>;
  privacyRu?: Maybe<Scalars['String']>;
  privacyUk?: Maybe<Scalars['String']>;
  privacyEn?: Maybe<Scalars['String']>;
  privacyPl?: Maybe<Scalars['String']>;
  privacyRo?: Maybe<Scalars['String']>;
  privacyAz?: Maybe<Scalars['String']>;
  privacyHy?: Maybe<Scalars['String']>;
  fondyTerms?: Maybe<Scalars['String']>;
  fondyTermsRu?: Maybe<Scalars['String']>;
  fondyTermsUk?: Maybe<Scalars['String']>;
  fondyTermsEn?: Maybe<Scalars['String']>;
  fondyTermsPl?: Maybe<Scalars['String']>;
  fondyTermsRo?: Maybe<Scalars['String']>;
  fondyTermsAz?: Maybe<Scalars['String']>;
  fondyTermsHy?: Maybe<Scalars['String']>;
  agreementText?: Maybe<Scalars['String']>;
  agreementTextRu?: Maybe<Scalars['String']>;
  agreementTextUk?: Maybe<Scalars['String']>;
  agreementTextEn?: Maybe<Scalars['String']>;
  agreementTextPl?: Maybe<Scalars['String']>;
  agreementTextRo?: Maybe<Scalars['String']>;
  agreementTextAz?: Maybe<Scalars['String']>;
  agreementTextHy?: Maybe<Scalars['String']>;
  gaData?: Maybe<Scalars['String']>;
  pixelData?: Maybe<Scalars['String']>;
  headerBold?: Maybe<Scalars['String']>;
  headerBoldRu?: Maybe<Scalars['String']>;
  headerBoldUk?: Maybe<Scalars['String']>;
  headerBoldEn?: Maybe<Scalars['String']>;
  headerBoldPl?: Maybe<Scalars['String']>;
  headerBoldRo?: Maybe<Scalars['String']>;
  headerBoldAz?: Maybe<Scalars['String']>;
  headerBoldHy?: Maybe<Scalars['String']>;
  headerPlain?: Maybe<Scalars['String']>;
  headerPlainRu?: Maybe<Scalars['String']>;
  headerPlainUk?: Maybe<Scalars['String']>;
  headerPlainEn?: Maybe<Scalars['String']>;
  headerPlainPl?: Maybe<Scalars['String']>;
  headerPlainRo?: Maybe<Scalars['String']>;
  headerPlainAz?: Maybe<Scalars['String']>;
  headerPlainHy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionRu?: Maybe<Scalars['String']>;
  descriptionUk?: Maybe<Scalars['String']>;
  descriptionEn?: Maybe<Scalars['String']>;
  descriptionPl?: Maybe<Scalars['String']>;
  descriptionRo?: Maybe<Scalars['String']>;
  descriptionAz?: Maybe<Scalars['String']>;
  descriptionHy?: Maybe<Scalars['String']>;
  countryZone?: Maybe<CountryNode>;
  agreementDoc?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  currentContest?: Maybe<ContestNode>;
  lastContest?: Maybe<ContestNode>;
  contestState?: Maybe<ConfigurationContestStateEnum>;
  testsAllowed?: Maybe<Scalars['Int']>;
  gamesEndpoint?: Maybe<Scalars['String']>;
  apiEndpoint?: Maybe<Scalars['String']>;
  frontEndpoint?: Maybe<Scalars['String']>;
  photos: Array<PhotoNode>;
  decks: Array<DeckNode>;
  currency?: Maybe<CurrencyEnum>;
  countryCode?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  totalContestsPassed?: Maybe<Scalars['Int']>;
  totalContestsFuture?: Maybe<Scalars['Int']>;
};


export type CountryNode = {
  __typename?: 'CountryNode';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nameRu?: Maybe<Scalars['String']>;
  nameUk?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  namePl?: Maybe<Scalars['String']>;
  nameRo?: Maybe<Scalars['String']>;
  nameAz?: Maybe<Scalars['String']>;
  nameHy?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<CountryCurrencyCode>;
  configurationSet: Array<ConfigurationNode>;
  participants: Array<ParticipantNode>;
  cities: Array<CityNode>;
  prices: Array<Price>;
};

/** An enumeration. */
export enum CountryCurrencyCode {
  /** UAH */
  A_1 = 'A_1',
  /** RUB */
  A_2 = 'A_2',
  /** USD */
  A_3 = 'A_3',
  /** PLN */
  A_4 = 'A_4',
  /** AZN */
  A_5 = 'A_5',
  /** EUR */
  A_6 = 'A_6',
  /** RON */
  A_7 = 'A_7',
  /** AMD */
  A_8 = 'A_8',
  /** MDL */
  A_9 = 'A_9'
}

export type ParticipantNode = {
  __typename?: 'ParticipantNode';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['UUID'];
  code?: Maybe<Scalars['String']>;
  isActivated: Scalars['Boolean'];
  email: Scalars['String'];
  phone: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender: ParticipantGender;
  schoolName?: Maybe<Scalars['String']>;
  contest: ContestNode;
  year: YearNode;
  difficulty?: Maybe<DifficultyNode>;
  country?: Maybe<CountryNode>;
  city?: Maybe<Scalars['String']>;
  livingCity?: Maybe<CityNode>;
  status?: Maybe<ParticipantStatus>;
  startedAt?: Maybe<Scalars['DateTime']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  template?: Maybe<TemplateNode>;
  points?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  state: ParticipantState;
  hadTesting?: Maybe<Scalars['Int']>;
  exercises: Array<ExerciseNode>;
  orders: Array<Order>;
  testCode?: Maybe<Scalars['String']>;
  testsDaily?: Maybe<Scalars['Int']>;
  testsPlayedToday?: Maybe<Scalars['Int']>;
  results?: Maybe<Scalars['String']>;
  diploma?: Maybe<Scalars['String']>;
};


/** An enumeration. */
export enum ParticipantGender {
  /** Male */
  A_1 = 'A_1',
  /** Female */
  A_2 = 'A_2',
  /** Prefer not to say */
  A_3 = 'A_3'
}

export type ContestNode = {
  __typename?: 'ContestNode';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  templates?: Maybe<Array<Maybe<TemplateNode>>>;
  isCurrent: Scalars['Boolean'];
  configurationSet: Array<ConfigurationNode>;
  lastConfig: Array<ConfigurationNode>;
  participants?: Maybe<Array<Maybe<ParticipantNode>>>;
  prices: Array<Price>;
  participantsPaid?: Maybe<Array<Maybe<ParticipantNode>>>;
  participantsTest?: Maybe<Array<Maybe<ParticipantNode>>>;
  yearsList?: Maybe<Array<Maybe<Scalars['String']>>>;
  difficultiesList?: Maybe<Array<Maybe<Scalars['String']>>>;
  agreement?: Maybe<Scalars['String']>;
  years?: Maybe<Array<Maybe<YearNode>>>;
  difficulties?: Maybe<Array<Maybe<DifficultyNode>>>;
};

export type TemplateNode = {
  __typename?: 'TemplateNode';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  year?: Maybe<YearNode>;
  difficulty?: Maybe<DifficultyNode>;
  columnsName?: Maybe<Scalars['String']>;
  columnsNameRu?: Maybe<Scalars['String']>;
  columnsNameUk?: Maybe<Scalars['String']>;
  columnsNameEn?: Maybe<Scalars['String']>;
  columnsNamePl?: Maybe<Scalars['String']>;
  columnsNameRo?: Maybe<Scalars['String']>;
  columnsNameAz?: Maybe<Scalars['String']>;
  columnsNameHy?: Maybe<Scalars['String']>;
  columnsText?: Maybe<Scalars['String']>;
  columnsTextRu?: Maybe<Scalars['String']>;
  columnsTextUk?: Maybe<Scalars['String']>;
  columnsTextEn?: Maybe<Scalars['String']>;
  columnsTextPl?: Maybe<Scalars['String']>;
  columnsTextRo?: Maybe<Scalars['String']>;
  columnsTextAz?: Maybe<Scalars['String']>;
  columnsTextHy?: Maybe<Scalars['String']>;
  rounds: Array<RoundNode>;
  forsageName?: Maybe<Scalars['String']>;
  forsageNameRu?: Maybe<Scalars['String']>;
  forsageNameUk?: Maybe<Scalars['String']>;
  forsageNameEn?: Maybe<Scalars['String']>;
  forsageNamePl?: Maybe<Scalars['String']>;
  forsageNameRo?: Maybe<Scalars['String']>;
  forsageNameAz?: Maybe<Scalars['String']>;
  forsageNameHy?: Maybe<Scalars['String']>;
  forsageText?: Maybe<Scalars['String']>;
  forsageTextRu?: Maybe<Scalars['String']>;
  forsageTextUk?: Maybe<Scalars['String']>;
  forsageTextEn?: Maybe<Scalars['String']>;
  forsageTextPl?: Maybe<Scalars['String']>;
  forsageTextRo?: Maybe<Scalars['String']>;
  forsageTextAz?: Maybe<Scalars['String']>;
  forsageTextHy?: Maybe<Scalars['String']>;
  blocks: Array<BlockNode>;
  contests: Array<ContestNode>;
  participants: Array<ParticipantNode>;
};

export type YearNode = {
  __typename?: 'YearNode';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nameRu?: Maybe<Scalars['String']>;
  nameUk?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  namePl?: Maybe<Scalars['String']>;
  nameRo?: Maybe<Scalars['String']>;
  nameAz?: Maybe<Scalars['String']>;
  nameHy?: Maybe<Scalars['String']>;
  templates: Array<TemplateNode>;
  participants: Array<ParticipantNode>;
};

export type DifficultyNode = {
  __typename?: 'DifficultyNode';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  nameRu?: Maybe<Scalars['String']>;
  nameUk?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  namePl?: Maybe<Scalars['String']>;
  nameRo?: Maybe<Scalars['String']>;
  nameAz?: Maybe<Scalars['String']>;
  nameHy?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  templates: Array<TemplateNode>;
  participants: Array<ParticipantNode>;
};

export type RoundNode = {
  __typename?: 'RoundNode';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  level?: Maybe<LevelEnum>;
  columns?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  examples?: Maybe<Scalars['Int']>;
  difficulty?: Maybe<DifficultyEnum>;
  /** in seconds, default 120 */
  timeout?: Maybe<Scalars['Float']>;
  templates: Array<TemplateNode>;
  exercises: Array<ExerciseNode>;
};

/** An enumeration. */
export enum LevelEnum {
  Simple = 'SIMPLE',
  Small = 'SMALL',
  Big = 'BIG',
  Family = 'FAMILY',
  Anzan = 'ANZAN',
  AnzanPositive = 'ANZAN_POSITIVE',
  AnzanNegative = 'ANZAN_NEGATIVE'
}

/** An enumeration. */
export enum DifficultyEnum {
  One = 'ONE',
  Ten = 'TEN',
  Hundred = 'HUNDRED',
  Thousand = 'THOUSAND',
  TenThousands = 'TEN_THOUSANDS'
}

export type ExerciseNode = {
  __typename?: 'ExerciseNode';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  participant?: Maybe<ParticipantNode>;
  data?: Maybe<Scalars['JSONString']>;
  payload?: Maybe<Scalars['JSONString']>;
  exerciseType?: Maybe<ExerciseTypeEnum>;
  isCorrect: Scalars['Boolean'];
  answer?: Maybe<Array<Maybe<Scalars['Int']>>>;
  rnd?: Maybe<RoundNode>;
  block?: Maybe<BlockNode>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  points?: Maybe<Scalars['Float']>;
  template?: Maybe<TemplateNode>;
};


/** An enumeration. */
export enum ExerciseTypeEnum {
  Columns = 'COLUMNS',
  Forsage = 'FORSAGE'
}

export type BlockNode = {
  __typename?: 'BlockNode';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  level?: Maybe<LevelEnum>;
  number?: Maybe<Scalars['Int']>;
  examples?: Maybe<Scalars['Int']>;
  sequences?: Maybe<Scalars['Int']>;
  difficulty?: Maybe<DifficultyEnum>;
  /** in seconds, default 2.0 */
  timeout?: Maybe<Scalars['Float']>;
  templates: Array<TemplateNode>;
  exercises: Array<ExerciseNode>;
};

export type Price = {
  __typename?: 'Price';
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  currency?: Maybe<CurrencyEnum>;
  amount?: Maybe<Scalars['Decimal']>;
  isActive: Scalars['Boolean'];
  country?: Maybe<CountryNode>;
  contest?: Maybe<ContestNode>;
  orders: Array<Order>;
};

/** An enumeration. */
export enum CurrencyEnum {
  Uah = 'UAH',
  Rub = 'RUB',
  Usd = 'USD',
  Pln = 'PLN',
  Azn = 'AZN',
  Eur = 'EUR',
  Ron = 'RON',
  Amd = 'AMD',
  Mdl = 'MDL'
}


export type Order = {
  __typename?: 'Order';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['UUID'];
  status?: Maybe<OrderStatus>;
  participant?: Maybe<ParticipantNode>;
  link?: Maybe<Scalars['String']>;
  price?: Maybe<Price>;
};

/** An enumeration. */
export enum OrderStatus {
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  Decline = 'DECLINE',
  Pending = 'PENDING'
}

export type CityNode = {
  __typename?: 'CityNode';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nameRu?: Maybe<Scalars['String']>;
  nameUk?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  namePl?: Maybe<Scalars['String']>;
  nameRo?: Maybe<Scalars['String']>;
  nameAz?: Maybe<Scalars['String']>;
  nameHy?: Maybe<Scalars['String']>;
  country?: Maybe<CountryNode>;
  participants: Array<ParticipantNode>;
};

/** An enumeration. */
export enum ParticipantStatus {
  SignedUp = 'SIGNED_UP',
  Paid = 'PAID',
  Activated = 'ACTIVATED',
  Columns = 'COLUMNS',
  Forsage = 'FORSAGE',
  Participated = 'PARTICIPATED'
}

/** An enumeration. */
export enum ParticipantState {
  /** WAITING */
  A_1 = 'A_1',
  /** TEST */
  A_2 = 'A_2',
  /** CONTEST */
  A_3 = 'A_3'
}

export enum ConfigurationContestStateEnum {
  NoContest = 'NO_CONTEST',
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED',
  Finished = 'FINISHED'
}

export type PhotoNode = {
  __typename?: 'PhotoNode';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['String']>;
  config?: Maybe<ConfigurationNode>;
  url?: Maybe<Scalars['String']>;
};

export type DeckNode = {
  __typename?: 'DeckNode';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  config?: Maybe<ConfigurationNode>;
  url?: Maybe<Scalars['String']>;
};

export type ContestsResponseNode = {
  __typename?: 'ContestsResponseNode';
  contests?: Maybe<Array<Maybe<ContestNode>>>;
  meta?: Maybe<MetaNode>;
};

export type MetaNode = {
  __typename?: 'MetaNode';
  pagesTotal?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  itemsTotal?: Maybe<Scalars['Int']>;
};

export enum ContestStateEnum {
  Current = 'CURRENT',
  Future = 'FUTURE',
  Passed = 'PASSED'
}

export type ExerciseResponseNode = {
  __typename?: 'ExerciseResponseNode';
  data?: Maybe<Scalars['String']>;
  exerciseId?: Maybe<Scalars['ID']>;
  exerciseType?: Maybe<ExerciseTypeEnum>;
  exerciseOrderNumber?: Maybe<Scalars['Int']>;
  exerciseOrderNumbersTotal?: Maybe<Scalars['Int']>;
  isLastColumn?: Maybe<Scalars['Boolean']>;
  isLastForsage?: Maybe<Scalars['Boolean']>;
  columnNumber?: Maybe<Scalars['Int']>;
  forsageNumber?: Maybe<Scalars['Int']>;
  participant?: Maybe<ParticipantNode>;
  exercise?: Maybe<ExerciseNode>;
  errors?: Maybe<ErrorsEnum>;
};

/** Errors enum to keep errors centralized */
export enum ErrorsEnum {
  InvalidCode = 'INVALID_CODE',
  CodeActivated = 'CODE_ACTIVATED',
  TimeExpired = 'TIME_EXPIRED',
  FirstNameRequired = 'FIRST_NAME_REQUIRED',
  LastNameRequired = 'LAST_NAME_REQUIRED',
  InvalidEmail = 'INVALID_EMAIL',
  InvalidPhone = 'INVALID_PHONE',
  InvalidYear = 'INVALID_YEAR',
  InvalidDifficulty = 'INVALID_DIFFICULTY',
  InvalidCountry = 'INVALID_COUNTRY',
  NotAuthenticated = 'NOT_AUTHENTICATED',
  NotAdmin = 'NOT_ADMIN',
  NotFound = 'NOT_FOUND',
  ExerciseNotFound = 'EXERCISE_NOT_FOUND',
  AlreadyAnswered = 'ALREADY_ANSWERED',
  IdRequired = 'ID_REQUIRED',
  NoIdRequired = 'NO_ID_REQUIRED',
  TemplateNotFound = 'TEMPLATE_NOT_FOUND',
  CityRequired = 'CITY_REQUIRED',
  InvalidFirstName = 'INVALID_FIRST_NAME',
  InvalidLastName = 'INVALID_LAST_NAME',
  InvalidParams = 'INVALID_PARAMS',
  DailyTestsLimit = 'DAILY_TESTS_LIMIT',
  ContestNotFound = 'CONTEST_NOT_FOUND',
  AlreadyParticipated = 'ALREADY_PARTICIPATED',
  InvalidCity = 'INVALID_CITY'
}

export type ParticipantsNode = {
  __typename?: 'ParticipantsNode';
  participants?: Maybe<Array<Maybe<ParticipantNode>>>;
  meta?: Maybe<MetaNode>;
  errors?: Maybe<ErrorsEnum>;
};

export type Mutation = {
  __typename?: 'Mutation';
  setGA?: Maybe<SetGa>;
  setPixel?: Maybe<SetPixel>;
  changePassword?: Maybe<ChangePassword>;
  updateData?: Maybe<UpdateData>;
  setContestAbout?: Maybe<SetContestAbout>;
  uploadDeck?: Maybe<UploadDeck>;
  uploadPhoto?: Maybe<UploadPhotoMutation>;
  deletePhoto?: Maybe<DeletePhoto>;
  deleteDeck?: Maybe<DeleteDeck>;
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  verifyToken?: Maybe<Verify>;
  refreshToken?: Maybe<Refresh>;
  createOrder?: Maybe<CreateOrder>;
  createTemplate?: Maybe<CreateTemplate>;
  updateTemplate?: Maybe<UpdateTemplate>;
  deleteTemplate?: Maybe<DeleteTemplate>;
  /** Duplicate template and create new template fully based on the old one */
  duplicateTemplate?: Maybe<DuplicateTemplate>;
  signUp?: Maybe<SignUp>;
  createContest?: Maybe<CreateContest>;
  updateContest?: Maybe<UpdateContest>;
  deleteContest?: Maybe<DeleteContest>;
  resetParticipant?: Maybe<ResetParticipant>;
  participate?: Maybe<Participate>;
  setAnswer?: Maybe<SetAnswer>;
  startCountdown?: Maybe<StartCountdown>;
};


export type MutationSetGaArgs = {
  googleAnalytics: Scalars['String'];
};


export type MutationSetPixelArgs = {
  facebookPixel: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationUpdateDataArgs = {
  configurationInput?: Maybe<ConfigurationInput>;
  lang: Scalars['String'];
};


export type MutationSetContestAboutArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadDeckArgs = {
  file: Scalars['Upload'];
  lang: Scalars['String'];
};


export type MutationUploadPhotoArgs = {
  file: Scalars['Upload'];
  id?: Maybe<Scalars['ID']>;
};


export type MutationDeletePhotoArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDeckArgs = {
  id: Scalars['ID'];
};


export type MutationTokenAuthArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationVerifyTokenArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationRefreshTokenArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationCreateOrderArgs = {
  participantId: Scalars['ID'];
  priceId: Scalars['ID'];
};


export type MutationCreateTemplateArgs = {
  lang?: Maybe<Scalars['String']>;
  template?: Maybe<TemplateInput>;
};


export type MutationUpdateTemplateArgs = {
  lang?: Maybe<Scalars['String']>;
  template?: Maybe<TemplateInput>;
};


export type MutationDeleteTemplateArgs = {
  pk?: Maybe<Scalars['ID']>;
};


export type MutationDuplicateTemplateArgs = {
  templateId: Scalars['ID'];
};


export type MutationSignUpArgs = {
  participantInput: ParticipantInput;
};


export type MutationCreateContestArgs = {
  contest: CreateContestInput;
  lang?: Maybe<Scalars['String']>;
};


export type MutationUpdateContestArgs = {
  contest: UpdateContestInput;
  lang?: Maybe<Scalars['String']>;
};


export type MutationDeleteContestArgs = {
  uid: Scalars['UUID'];
};


export type MutationResetParticipantArgs = {
  code?: Maybe<Scalars['String']>;
};


export type MutationParticipateArgs = {
  code?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  isTest?: Maybe<Scalars['Boolean']>;
};


export type MutationSetAnswerArgs = {
  answer: Array<Maybe<Scalars['Int']>>;
  code: Scalars['String'];
  exerciseId: Scalars['ID'];
  isTest: Scalars['Boolean'];
  lang: Scalars['String'];
  seconds?: Maybe<Scalars['Float']>;
};


export type MutationStartCountdownArgs = {
  code: Scalars['String'];
  isExerciseSetCode?: Maybe<Scalars['Boolean']>;
};

export type SetGa = {
  __typename?: 'SetGA';
  configuration?: Maybe<ConfigurationNode>;
  errors?: Maybe<Scalars['String']>;
};

export type SetPixel = {
  __typename?: 'SetPixel';
  configuration?: Maybe<ConfigurationNode>;
  errors?: Maybe<Scalars['String']>;
};

export type ChangePassword = {
  __typename?: 'ChangePassword';
  errors?: Maybe<Scalars['String']>;
};

export type UpdateData = {
  __typename?: 'UpdateData';
  configuration?: Maybe<ConfigurationNode>;
  errors?: Maybe<ErrorsEnum>;
};

export type ConfigurationInput = {
  headerBold: Scalars['String'];
  headerPlain: Scalars['String'];
  description: Scalars['String'];
  countryZoneId: Scalars['ID'];
  /** contest UUID */
  currentContestId?: Maybe<Scalars['UUID']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  testsAllowed: Scalars['Int'];
  agreementText: Scalars['String'];
  fondyTerms: Scalars['String'];
};

export type SetContestAbout = {
  __typename?: 'SetContestAbout';
  configuration?: Maybe<ConfigurationNode>;
  errors?: Maybe<ErrorsEnum>;
};


export type UploadDeck = {
  __typename?: 'UploadDeck';
  errors?: Maybe<ErrorsEnum>;
};

export type UploadPhotoMutation = {
  __typename?: 'UploadPhotoMutation';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<ErrorsEnum>;
};

export type DeletePhoto = {
  __typename?: 'DeletePhoto';
  errors?: Maybe<ErrorsEnum>;
};

export type DeleteDeck = {
  __typename?: 'DeleteDeck';
  errors?: Maybe<ErrorsEnum>;
};

/** Obtain JSON Web Token mutation */
export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};


export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type CreateOrder = {
  __typename?: 'CreateOrder';
  order?: Maybe<Order>;
  errors?: Maybe<Scalars['String']>;
};

export type CreateTemplate = {
  __typename?: 'CreateTemplate';
  template?: Maybe<TemplateNode>;
  errors?: Maybe<ErrorsEnum>;
};

export type TemplateInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  columnsName?: Maybe<Scalars['String']>;
  columnsText?: Maybe<Scalars['String']>;
  forsageName?: Maybe<Scalars['String']>;
  forsageText?: Maybe<Scalars['String']>;
  year?: Maybe<YearInput>;
  difficulty?: Maybe<DifficultyInput>;
  rounds?: Maybe<Array<Maybe<RoundInput>>>;
  blocks?: Maybe<Array<Maybe<BlockInput>>>;
};

export type YearInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type DifficultyInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type RoundInput = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  level?: Maybe<LevelEnum>;
  number?: Maybe<Scalars['Int']>;
  columns?: Maybe<Scalars['Int']>;
  examples?: Maybe<Scalars['Int']>;
  difficulty?: Maybe<DifficultyEnum>;
  timeout?: Maybe<Scalars['Int']>;
};

export type BlockInput = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  level?: Maybe<LevelEnum>;
  number?: Maybe<Scalars['Int']>;
  examples?: Maybe<Scalars['Int']>;
  sequences?: Maybe<Scalars['Int']>;
  difficulty?: Maybe<DifficultyEnum>;
  timeout?: Maybe<Scalars['Float']>;
};

export type UpdateTemplate = {
  __typename?: 'UpdateTemplate';
  template?: Maybe<TemplateNode>;
  errors?: Maybe<ErrorsEnum>;
};

export type DeleteTemplate = {
  __typename?: 'DeleteTemplate';
  errors?: Maybe<ErrorsEnum>;
};

/** Duplicate template and create new template fully based on the old one */
export type DuplicateTemplate = {
  __typename?: 'DuplicateTemplate';
  newTemplate?: Maybe<TemplateNode>;
  errors?: Maybe<ErrorsEnum>;
};

export type SignUp = {
  __typename?: 'SignUp';
  participant?: Maybe<ParticipantNode>;
  errors?: Maybe<ErrorsEnum>;
};

export type ParticipantInput = {
  email: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  gender: GenderEnum;
  yearName: Scalars['String'];
  countryId: Scalars['ID'];
  livingCityId: Scalars['ID'];
  schoolName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  difficultyName: Scalars['String'];
  contestId: Scalars['ID'];
  lang: Scalars['String'];
};

/** An enumeration. */
export enum GenderEnum {
  Male = 'MALE',
  Female = 'FEMALE',
  WontSay = 'WONT_SAY'
}

export type CreateContest = {
  __typename?: 'CreateContest';
  errors?: Maybe<ErrorsEnum>;
  contest?: Maybe<ContestNode>;
};

export type CreateContestInput = {
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  prices: Array<Maybe<PriceInput>>;
  templates?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type PriceInput = {
  id?: Maybe<Scalars['ID']>;
  currency: CurrencyEnum;
  amount: Scalars['Float'];
};

export type UpdateContest = {
  __typename?: 'UpdateContest';
  errors?: Maybe<ErrorsEnum>;
  contest?: Maybe<ContestNode>;
};

export type UpdateContestInput = {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  prices?: Maybe<Array<Maybe<PriceInput>>>;
  templates?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lang?: Maybe<Scalars['String']>;
};

export type DeleteContest = {
  __typename?: 'DeleteContest';
  errors?: Maybe<ErrorsEnum>;
};

export type ResetParticipant = {
  __typename?: 'ResetParticipant';
  participant?: Maybe<ParticipantNode>;
  errors?: Maybe<ErrorsEnum>;
};

export type Participate = {
  __typename?: 'Participate';
  participant?: Maybe<ParticipantNode>;
  errors?: Maybe<ErrorsEnum>;
};

export type SetAnswer = {
  __typename?: 'SetAnswer';
  exercise?: Maybe<ExerciseResponseNode>;
};

export type StartCountdown = {
  __typename?: 'StartCountdown';
  errors?: Maybe<ErrorsEnum>;
};

export type BlockFragment = (
  { __typename?: 'BlockNode' }
  & Pick<BlockNode, 'id' | 'order' | 'level' | 'number' | 'examples' | 'difficulty' | 'timeout' | 'sequences'>
);

export type ConfigurationFragmentFragment = (
  { __typename?: 'ConfigurationNode' }
  & Pick<ConfigurationNode, 'terms' | 'privacy' | 'agreementText' | 'gaData' | 'pixelData' | 'headerBold' | 'headerPlain' | 'description' | 'agreementDoc' | 'testsAllowed' | 'fondyTerms'>
  & { lastContest?: Maybe<(
    { __typename?: 'ContestNode' }
    & Pick<ContestNode, 'id'>
  )> }
);

export type CountryZoneFragment = (
  { __typename?: 'CountryNode' }
  & Pick<CountryNode, 'id' | 'name'>
);

export type DifficultyFragmentFragment = (
  { __typename?: 'DifficultyNode' }
  & Pick<DifficultyNode, 'id' | 'name'>
);

export type RoundFragment = (
  { __typename?: 'RoundNode' }
  & Pick<RoundNode, 'id' | 'order' | 'level' | 'columns' | 'number' | 'difficulty' | 'timeout' | 'examples'>
);

export type YearFragmentFragment = (
  { __typename?: 'YearNode' }
  & Pick<YearNode, 'name' | 'id'>
);

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePassword?: Maybe<(
    { __typename?: 'ChangePassword' }
    & Pick<ChangePassword, 'errors'>
  )> }
);

export type CreateContestMutationVariables = Exact<{
  contest: CreateContestInput;
}>;


export type CreateContestMutation = (
  { __typename?: 'Mutation' }
  & { createContest?: Maybe<(
    { __typename?: 'CreateContest' }
    & Pick<CreateContest, 'errors'>
    & { contest?: Maybe<(
      { __typename?: 'ContestNode' }
      & Pick<ContestNode, 'id' | 'name'>
      & { templates?: Maybe<Array<Maybe<(
        { __typename?: 'TemplateNode' }
        & Pick<TemplateNode, 'id' | 'name'>
      )>>> }
    )> }
  )> }
);

export type CreateOrderMutationVariables = Exact<{
  participantId: Scalars['ID'];
  priceId: Scalars['ID'];
}>;


export type CreateOrderMutation = (
  { __typename?: 'Mutation' }
  & { createOrder?: Maybe<(
    { __typename?: 'CreateOrder' }
    & Pick<CreateOrder, 'errors'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'status' | 'link' | 'id'>
    )> }
  )> }
);

export type CreateTemplateMutationVariables = Exact<{
  template?: Maybe<TemplateInput>;
  lang?: Maybe<Scalars['String']>;
}>;


export type CreateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createTemplate?: Maybe<(
    { __typename?: 'CreateTemplate' }
    & Pick<CreateTemplate, 'errors'>
    & { template?: Maybe<(
      { __typename?: 'TemplateNode' }
      & Pick<TemplateNode, 'id'>
    )> }
  )> }
);

export type DeleteContestMutationVariables = Exact<{
  uid: Scalars['UUID'];
}>;


export type DeleteContestMutation = (
  { __typename?: 'Mutation' }
  & { deleteContest?: Maybe<(
    { __typename?: 'DeleteContest' }
    & Pick<DeleteContest, 'errors'>
  )> }
);

export type DeletePhotoMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePhotoMutation = (
  { __typename?: 'Mutation' }
  & { deletePhoto?: Maybe<(
    { __typename?: 'DeletePhoto' }
    & Pick<DeletePhoto, 'errors'>
  )> }
);

export type DeleteTemplateMutationVariables = Exact<{
  pk?: Maybe<Scalars['ID']>;
}>;


export type DeleteTemplateMutation = (
  { __typename?: 'Mutation' }
  & { deleteTemplate?: Maybe<(
    { __typename?: 'DeleteTemplate' }
    & Pick<DeleteTemplate, 'errors'>
  )> }
);

export type DuplicateTemplateMutationVariables = Exact<{
  templateId: Scalars['ID'];
}>;


export type DuplicateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { duplicateTemplate?: Maybe<(
    { __typename?: 'DuplicateTemplate' }
    & Pick<DuplicateTemplate, 'errors'>
    & { newTemplate?: Maybe<(
      { __typename?: 'TemplateNode' }
      & Pick<TemplateNode, 'id' | 'name' | 'columnsName' | 'columnsNameRu' | 'columnsNameUk' | 'columnsNameEn' | 'columnsNamePl' | 'columnsNameRo' | 'columnsNameAz' | 'forsageName' | 'forsageNameRu' | 'forsageNameUk' | 'forsageNameEn' | 'forsageNamePl' | 'forsageNameRo' | 'forsageNameAz'>
    )> }
  )> }
);

export type ParticipateMutationVariables = Exact<{
  code: Scalars['String'];
  email: Scalars['String'];
  isTest?: Maybe<Scalars['Boolean']>;
}>;


export type ParticipateMutation = (
  { __typename?: 'Mutation' }
  & { participate?: Maybe<(
    { __typename?: 'Participate' }
    & Pick<Participate, 'errors'>
    & { participant?: Maybe<(
      { __typename?: 'ParticipantNode' }
      & Pick<ParticipantNode, 'id' | 'code' | 'testCode' | 'testsDaily' | 'testsPlayedToday'>
    )> }
  )> }
);

export type ResetParticipantMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type ResetParticipantMutation = (
  { __typename?: 'Mutation' }
  & { resetParticipant?: Maybe<(
    { __typename?: 'ResetParticipant' }
    & Pick<ResetParticipant, 'errors'>
    & { participant?: Maybe<(
      { __typename?: 'ParticipantNode' }
      & Pick<ParticipantNode, 'id' | 'code'>
    )> }
  )> }
);

export type SetAnswerMutationVariables = Exact<{
  answer: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
  code: Scalars['String'];
  exerciseId: Scalars['ID'];
  isTest: Scalars['Boolean'];
  lang: Scalars['String'];
  seconds?: Maybe<Scalars['Float']>;
}>;


export type SetAnswerMutation = (
  { __typename?: 'Mutation' }
  & { setAnswer?: Maybe<(
    { __typename?: 'SetAnswer' }
    & { exercise?: Maybe<(
      { __typename?: 'ExerciseResponseNode' }
      & Pick<ExerciseResponseNode, 'data' | 'exerciseId' | 'exerciseType' | 'isLastColumn' | 'columnNumber' | 'forsageNumber' | 'isLastForsage' | 'errors'>
      & { participant?: Maybe<(
        { __typename?: 'ParticipantNode' }
        & Pick<ParticipantNode, 'id'>
      )> }
    )> }
  )> }
);

export type StartCountdownMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type StartCountdownMutation = (
  { __typename?: 'Mutation' }
  & { startCountdown?: Maybe<(
    { __typename?: 'StartCountdown' }
    & Pick<StartCountdown, 'errors'>
  )> }
);

export type RefreshTokenMutationVariables = Exact<{
  token?: Maybe<Scalars['String']>;
}>;


export type RefreshTokenMutation = (
  { __typename?: 'Mutation' }
  & { refreshToken?: Maybe<(
    { __typename?: 'Refresh' }
    & Pick<Refresh, 'payload' | 'refreshExpiresIn' | 'token'>
  )> }
);

export type SetContestAboutMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type SetContestAboutMutation = (
  { __typename?: 'Mutation' }
  & { setContestAbout?: Maybe<(
    { __typename?: 'SetContestAbout' }
    & Pick<SetContestAbout, 'errors'>
    & { configuration?: Maybe<(
      { __typename?: 'ConfigurationNode' }
      & Pick<ConfigurationNode, 'id' | 'about'>
    )> }
  )> }
);

export type SetGaMutationVariables = Exact<{
  googleAnalytics: Scalars['String'];
}>;


export type SetGaMutation = (
  { __typename?: 'Mutation' }
  & { setGA?: Maybe<(
    { __typename?: 'SetGA' }
    & Pick<SetGa, 'errors'>
    & { configuration?: Maybe<(
      { __typename?: 'ConfigurationNode' }
      & Pick<ConfigurationNode, 'id' | 'gaData' | 'pixelData' | 'agreementText'>
    )> }
  )> }
);

export type SetPixelMutationVariables = Exact<{
  facebookPixel: Scalars['String'];
}>;


export type SetPixelMutation = (
  { __typename?: 'Mutation' }
  & { setPixel?: Maybe<(
    { __typename?: 'SetPixel' }
    & Pick<SetPixel, 'errors'>
    & { configuration?: Maybe<(
      { __typename?: 'ConfigurationNode' }
      & Pick<ConfigurationNode, 'id' | 'gaData' | 'pixelData' | 'agreementText'>
    )> }
  )> }
);

export type SignUpMutationVariables = Exact<{
  participantInput: ParticipantInput;
}>;


export type SignUpMutation = (
  { __typename?: 'Mutation' }
  & { signUp?: Maybe<(
    { __typename?: 'SignUp' }
    & Pick<SignUp, 'errors'>
    & { participant?: Maybe<(
      { __typename?: 'ParticipantNode' }
      & Pick<ParticipantNode, 'id'>
    )> }
  )> }
);

export type TokenAuthMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type TokenAuthMutation = (
  { __typename?: 'Mutation' }
  & { tokenAuth?: Maybe<(
    { __typename?: 'ObtainJSONWebToken' }
    & Pick<ObtainJsonWebToken, 'payload' | 'refreshExpiresIn' | 'token'>
  )> }
);

export type UpdateContestMutationVariables = Exact<{
  contest: UpdateContestInput;
}>;


export type UpdateContestMutation = (
  { __typename?: 'Mutation' }
  & { updateContest?: Maybe<(
    { __typename?: 'UpdateContest' }
    & Pick<UpdateContest, 'errors'>
    & { contest?: Maybe<(
      { __typename?: 'ContestNode' }
      & Pick<ContestNode, 'id'>
      & { prices: Array<(
        { __typename?: 'Price' }
        & Pick<Price, 'id' | 'amount' | 'currency'>
      )> }
    )> }
  )> }
);

export type UpdateDataMutationVariables = Exact<{
  configInput?: Maybe<ConfigurationInput>;
  lang: Scalars['String'];
}>;


export type UpdateDataMutation = (
  { __typename?: 'Mutation' }
  & { updateData?: Maybe<(
    { __typename?: 'UpdateData' }
    & Pick<UpdateData, 'errors'>
    & { configuration?: Maybe<(
      { __typename?: 'ConfigurationNode' }
      & { countryZone?: Maybe<(
        { __typename?: 'CountryNode' }
        & CountryZoneFragment
      )>, currentContest?: Maybe<(
        { __typename?: 'ContestNode' }
        & Pick<ContestNode, 'startDate' | 'endDate'>
      )> }
      & ConfigurationFragmentFragment
    )> }
  )> }
);

export type UpdateTemplateMutationVariables = Exact<{
  template: TemplateInput;
  lang?: Maybe<Scalars['String']>;
}>;


export type UpdateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateTemplate?: Maybe<(
    { __typename?: 'UpdateTemplate' }
    & Pick<UpdateTemplate, 'errors'>
    & { template?: Maybe<(
      { __typename?: 'TemplateNode' }
      & Pick<TemplateNode, 'id' | 'name' | 'columnsName' | 'columnsText' | 'forsageName' | 'forsageText'>
      & { year?: Maybe<(
        { __typename?: 'YearNode' }
        & YearFragmentFragment
      )>, difficulty?: Maybe<(
        { __typename?: 'DifficultyNode' }
        & DifficultyFragmentFragment
      )>, rounds: Array<(
        { __typename?: 'RoundNode' }
        & RoundFragment
      )>, blocks: Array<(
        { __typename?: 'BlockNode' }
        & BlockFragment
      )> }
    )> }
  )> }
);

export type UploadDeckMutationVariables = Exact<{
  file: Scalars['Upload'];
  lang: Scalars['String'];
}>;


export type UploadDeckMutation = (
  { __typename?: 'Mutation' }
  & { uploadDeck?: Maybe<(
    { __typename?: 'UploadDeck' }
    & Pick<UploadDeck, 'errors'>
  )> }
);

export type UploadImagesMutationVariables = Exact<{
  file: Scalars['Upload'];
  id?: Maybe<Scalars['ID']>;
}>;


export type UploadImagesMutation = (
  { __typename?: 'Mutation' }
  & { uploadPhoto?: Maybe<(
    { __typename?: 'UploadPhotoMutation' }
    & Pick<UploadPhotoMutation, 'success'>
  )> }
);

export type VerifyTokenMutationVariables = Exact<{
  token?: Maybe<Scalars['String']>;
}>;


export type VerifyTokenMutation = (
  { __typename?: 'Mutation' }
  & { verifyToken?: Maybe<(
    { __typename?: 'Verify' }
    & Pick<Verify, 'payload'>
  )> }
);

export type CitiesQueryVariables = Exact<{
  countryId?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type CitiesQuery = (
  { __typename?: 'Query' }
  & { cities?: Maybe<Array<Maybe<(
    { __typename?: 'CityNode' }
    & Pick<CityNode, 'name' | 'id'>
  )>>> }
);

export type ConfigurationQueryVariables = Exact<{
  lang: Scalars['String'];
}>;


export type ConfigurationQuery = (
  { __typename?: 'Query' }
  & { configuration?: Maybe<(
    { __typename?: 'ConfigurationNode' }
    & Pick<ConfigurationNode, 'fondyTerms' | 'id' | 'contestState' | 'timezone' | 'about'>
    & { photos: Array<(
      { __typename?: 'PhotoNode' }
      & Pick<PhotoNode, 'file' | 'url' | 'id'>
    )>, countryZone?: Maybe<(
      { __typename?: 'CountryNode' }
      & CountryZoneFragment
    )>, decks: Array<(
      { __typename?: 'DeckNode' }
      & Pick<DeckNode, 'id' | 'lang' | 'file' | 'url'>
    )>, lastContest?: Maybe<(
      { __typename?: 'ContestNode' }
      & Pick<ContestNode, 'yearsList' | 'difficultiesList' | 'id'>
    )> }
    & ConfigurationFragmentFragment
  )> }
);

export type ConfigurationMinQueryVariables = Exact<{
  lang: Scalars['String'];
}>;


export type ConfigurationMinQuery = (
  { __typename?: 'Query' }
  & { configuration?: Maybe<(
    { __typename?: 'ConfigurationNode' }
    & Pick<ConfigurationNode, 'contestState' | 'totalContestsPassed' | 'totalContestsFuture' | 'countryCode' | 'currency'>
    & { lastContest?: Maybe<(
      { __typename?: 'ContestNode' }
      & Pick<ContestNode, 'id' | 'yearsList' | 'difficultiesList'>
    )>, countryZone?: Maybe<(
      { __typename?: 'CountryNode' }
      & Pick<CountryNode, 'name' | 'code'>
    )> }
  )> }
);

export type ContestResultsQueryVariables = Exact<{
  contestId: Scalars['UUID'];
  yearName: Scalars['String'];
  difficultyName: Scalars['String'];
  lang: Scalars['String'];
  cityId?: Maybe<Scalars['ID']>;
  countryId?: Maybe<Scalars['ID']>;
}>;


export type ContestResultsQuery = (
  { __typename?: 'Query' }
  & { contestResults?: Maybe<Array<Maybe<(
    { __typename?: 'ParticipantNode' }
    & Pick<ParticipantNode, 'lastName' | 'firstName' | 'points' | 'city'>
    & { country?: Maybe<(
      { __typename?: 'CountryNode' }
      & Pick<CountryNode, 'name'>
    )>, livingCity?: Maybe<(
      { __typename?: 'CityNode' }
      & Pick<CityNode, 'name' | 'id'>
    )> }
  )>>> }
);

export type ContestsQueryVariables = Exact<{
  lang: Scalars['String'];
  state?: Maybe<ContestStateEnum>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sorting?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type ContestsQuery = (
  { __typename?: 'Query' }
  & { contests?: Maybe<(
    { __typename?: 'ContestsResponseNode' }
    & { contests?: Maybe<Array<Maybe<(
      { __typename?: 'ContestNode' }
      & Pick<ContestNode, 'id' | 'startDate' | 'endDate' | 'name' | 'yearsList' | 'difficultiesList'>
      & { templates?: Maybe<Array<Maybe<(
        { __typename?: 'TemplateNode' }
        & Pick<TemplateNode, 'id' | 'name'>
      )>>>, participants?: Maybe<Array<Maybe<(
        { __typename?: 'ParticipantNode' }
        & Pick<ParticipantNode, 'id' | 'firstName'>
      )>>>, prices: Array<(
        { __typename?: 'Price' }
        & Pick<Price, 'id' | 'amount' | 'currency'>
      )>, participantsPaid?: Maybe<Array<Maybe<(
        { __typename?: 'ParticipantNode' }
        & Pick<ParticipantNode, 'id'>
      )>>>, participantsTest?: Maybe<Array<Maybe<(
        { __typename?: 'ParticipantNode' }
        & Pick<ParticipantNode, 'id'>
      )>>>, years?: Maybe<Array<Maybe<(
        { __typename?: 'YearNode' }
        & YearFragmentFragment
      )>>>, difficulties?: Maybe<Array<Maybe<(
        { __typename?: 'DifficultyNode' }
        & DifficultyFragmentFragment
      )>>> }
    )>>>, meta?: Maybe<(
      { __typename?: 'MetaNode' }
      & Pick<MetaNode, 'pagesTotal' | 'page' | 'limit' | 'itemsTotal'>
    )> }
  )> }
);

export type ShortContestsQueryVariables = Exact<{
  lang: Scalars['String'];
  state?: Maybe<ContestStateEnum>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type ShortContestsQuery = (
  { __typename?: 'Query' }
  & { contests?: Maybe<(
    { __typename?: 'ContestsResponseNode' }
    & { meta?: Maybe<(
      { __typename?: 'MetaNode' }
      & Pick<MetaNode, 'pagesTotal' | 'page' | 'limit' | 'itemsTotal'>
    )>, contests?: Maybe<Array<Maybe<(
      { __typename?: 'ContestNode' }
      & Pick<ContestNode, 'id' | 'name'>
    )>>> }
  )> }
);

export type CountriesQueryVariables = Exact<{
  lang?: Maybe<Scalars['String']>;
}>;


export type CountriesQuery = (
  { __typename?: 'Query' }
  & { countries?: Maybe<Array<Maybe<(
    { __typename?: 'CountryNode' }
    & Pick<CountryNode, 'id' | 'name' | 'nameRu' | 'nameUk' | 'nameEn'>
  )>>> }
);

export type CurrentContestQueryVariables = Exact<{
  lang: Scalars['String'];
}>;


export type CurrentContestQuery = (
  { __typename?: 'Query' }
  & { currentContest?: Maybe<(
    { __typename?: 'ContestNode' }
    & Pick<ContestNode, 'agreement' | 'startDate' | 'endDate' | 'id' | 'yearsList' | 'difficultiesList'>
    & { prices: Array<(
      { __typename?: 'Price' }
      & Pick<Price, 'id' | 'currency' | 'amount'>
    )>, years?: Maybe<Array<Maybe<(
      { __typename?: 'YearNode' }
      & YearFragmentFragment
    )>>>, difficulties?: Maybe<Array<Maybe<(
      { __typename?: 'DifficultyNode' }
      & DifficultyFragmentFragment
    )>>> }
  )> }
);

export type DifficultiesQueryVariables = Exact<{
  yearName: Scalars['String'];
  lang: Scalars['String'];
}>;


export type DifficultiesQuery = (
  { __typename?: 'Query' }
  & { difficulties?: Maybe<Array<Maybe<(
    { __typename?: 'DifficultyNode' }
    & Pick<DifficultyNode, 'name' | 'id'>
  )>>> }
);

export type GetExerciseQueryVariables = Exact<{
  code?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type GetExerciseQuery = (
  { __typename?: 'Query' }
  & { getExercise?: Maybe<(
    { __typename?: 'ExerciseResponseNode' }
    & Pick<ExerciseResponseNode, 'data' | 'exerciseId' | 'exerciseType' | 'exerciseOrderNumber' | 'exerciseOrderNumbersTotal' | 'isLastColumn' | 'columnNumber' | 'forsageNumber' | 'isLastForsage' | 'errors'>
    & { exercise?: Maybe<(
      { __typename?: 'ExerciseNode' }
      & Pick<ExerciseNode, 'points'>
      & { template?: Maybe<(
        { __typename?: 'TemplateNode' }
        & Pick<TemplateNode, 'columnsName' | 'columnsText' | 'forsageName' | 'forsageText'>
        & { year?: Maybe<(
          { __typename?: 'YearNode' }
          & Pick<YearNode, 'id' | 'name'>
        )>, difficulty?: Maybe<(
          { __typename?: 'DifficultyNode' }
          & Pick<DifficultyNode, 'id' | 'name'>
        )>, blocks: Array<(
          { __typename?: 'BlockNode' }
          & Pick<BlockNode, 'id' | 'timeout' | 'number' | 'examples' | 'difficulty'>
        )>, rounds: Array<(
          { __typename?: 'RoundNode' }
          & Pick<RoundNode, 'id' | 'timeout'>
        )> }
      )> }
    )>, participant?: Maybe<(
      { __typename?: 'ParticipantNode' }
      & Pick<ParticipantNode, 'code' | 'points' | 'testCode'>
    )> }
  )> }
);

export type GetParticipantQueryVariables = Exact<{
  email: Scalars['String'];
  code: Scalars['String'];
}>;


export type GetParticipantQuery = (
  { __typename?: 'Query' }
  & { getParticipant?: Maybe<(
    { __typename?: 'ParticipantNode' }
    & Pick<ParticipantNode, 'points' | 'results'>
  )> }
);

export type ParticipantsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  contestId: Scalars['UUID'];
  difficultyName?: Maybe<Scalars['ID']>;
  yearName?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  lang: Scalars['String'];
  sorting?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type ParticipantsQuery = (
  { __typename?: 'Query' }
  & { participants?: Maybe<(
    { __typename?: 'ParticipantsNode' }
    & Pick<ParticipantsNode, 'errors'>
    & { participants?: Maybe<Array<Maybe<(
      { __typename?: 'ParticipantNode' }
      & Pick<ParticipantNode, 'lastName' | 'firstName' | 'points' | 'id' | 'schoolName' | 'results' | 'city'>
      & { country?: Maybe<(
        { __typename?: 'CountryNode' }
        & CountryZoneFragment
      )> }
    )>>>, meta?: Maybe<(
      { __typename?: 'MetaNode' }
      & Pick<MetaNode, 'pagesTotal' | 'page' | 'limit' | 'itemsTotal'>
    )> }
  )> }
);

export type TemplateQueryVariables = Exact<{
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type TemplateQuery = (
  { __typename?: 'Query' }
  & { template?: Maybe<(
    { __typename?: 'TemplateNode' }
    & Pick<TemplateNode, 'id' | 'name' | 'columnsName' | 'columnsText' | 'forsageName' | 'forsageText'>
    & { rounds: Array<(
      { __typename?: 'RoundNode' }
      & RoundFragment
    )>, blocks: Array<(
      { __typename?: 'BlockNode' }
      & BlockFragment
    )>, year?: Maybe<(
      { __typename?: 'YearNode' }
      & Pick<YearNode, 'id' | 'name'>
    )>, difficulty?: Maybe<(
      { __typename?: 'DifficultyNode' }
      & Pick<DifficultyNode, 'id' | 'name'>
    )> }
  )> }
);

export type TemplatesQueryVariables = Exact<{
  lang?: Maybe<Scalars['String']>;
}>;


export type TemplatesQuery = (
  { __typename?: 'Query' }
  & { templates?: Maybe<Array<Maybe<(
    { __typename?: 'TemplateNode' }
    & Pick<TemplateNode, 'id' | 'name' | 'columnsName' | 'columnsText' | 'forsageName' | 'forsageText' | 'columnsNameRu' | 'columnsNameUk' | 'columnsNameEn' | 'columnsNamePl' | 'columnsNameRo' | 'columnsNameAz' | 'columnsNameHy' | 'columnsTextRu' | 'columnsTextUk' | 'columnsTextEn' | 'columnsTextPl' | 'columnsTextRo' | 'columnsTextAz' | 'columnsTextHy' | 'forsageNameRu' | 'forsageNameUk' | 'forsageNameEn' | 'forsageNamePl' | 'forsageNameRo' | 'forsageNameAz' | 'forsageNameHy' | 'forsageTextRu' | 'forsageTextUk' | 'forsageTextEn' | 'forsageTextPl' | 'forsageTextRo' | 'forsageTextAz' | 'forsageTextHy'>
    & { rounds: Array<(
      { __typename?: 'RoundNode' }
      & RoundFragment
    )>, blocks: Array<(
      { __typename?: 'BlockNode' }
      & BlockFragment
    )>, year?: Maybe<(
      { __typename?: 'YearNode' }
      & Pick<YearNode, 'id' | 'name'>
    )>, difficulty?: Maybe<(
      { __typename?: 'DifficultyNode' }
      & Pick<DifficultyNode, 'id' | 'name'>
    )> }
  )>>> }
);

export type TemplatesForSelectQueryVariables = Exact<{
  lang?: Maybe<Scalars['String']>;
}>;


export type TemplatesForSelectQuery = (
  { __typename?: 'Query' }
  & { templates?: Maybe<Array<Maybe<(
    { __typename?: 'TemplateNode' }
    & Pick<TemplateNode, 'id' | 'name'>
  )>>> }
);

export const BlockFragmentDoc = gql`
    fragment Block on BlockNode {
  id
  order
  level
  number
  examples
  difficulty
  timeout
  sequences
}
    `;
export const ConfigurationFragmentFragmentDoc = gql`
    fragment ConfigurationFragment on ConfigurationNode {
  terms
  privacy
  agreementText
  gaData
  pixelData
  headerBold
  headerPlain
  description
  agreementDoc
  testsAllowed
  fondyTerms
  lastContest {
    id
  }
}
    `;
export const CountryZoneFragmentDoc = gql`
    fragment CountryZone on CountryNode {
  id
  name
}
    `;
export const DifficultyFragmentFragmentDoc = gql`
    fragment DifficultyFragment on DifficultyNode {
  id
  name
}
    `;
export const RoundFragmentDoc = gql`
    fragment Round on RoundNode {
  id
  order
  level
  columns
  number
  difficulty
  timeout
  examples
}
    `;
export const YearFragmentFragmentDoc = gql`
    fragment YearFragment on YearNode {
  name
  id
}
    `;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($newPassword: String!, $oldPassword: String!) {
  changePassword(newPassword: $newPassword, oldPassword: $oldPassword) {
    errors
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      oldPassword: // value for 'oldPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CreateContestDocument = gql`
    mutation CreateContest($contest: CreateContestInput!) {
  createContest(contest: $contest) {
    errors
    contest {
      id
      name
      templates {
        id
        name
      }
    }
  }
}
    `;
export type CreateContestMutationFn = Apollo.MutationFunction<CreateContestMutation, CreateContestMutationVariables>;

/**
 * __useCreateContestMutation__
 *
 * To run a mutation, you first call `useCreateContestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContestMutation, { data, loading, error }] = useCreateContestMutation({
 *   variables: {
 *      contest: // value for 'contest'
 *   },
 * });
 */
export function useCreateContestMutation(baseOptions?: Apollo.MutationHookOptions<CreateContestMutation, CreateContestMutationVariables>) {
        return Apollo.useMutation<CreateContestMutation, CreateContestMutationVariables>(CreateContestDocument, baseOptions);
      }
export type CreateContestMutationHookResult = ReturnType<typeof useCreateContestMutation>;
export type CreateContestMutationResult = Apollo.MutationResult<CreateContestMutation>;
export type CreateContestMutationOptions = Apollo.BaseMutationOptions<CreateContestMutation, CreateContestMutationVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($participantId: ID!, $priceId: ID!) {
  createOrder(participantId: $participantId, priceId: $priceId) {
    order {
      status
      link
      id
    }
    errors
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      priceId: // value for 'priceId'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, baseOptions);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const CreateTemplateDocument = gql`
    mutation CreateTemplate($template: TemplateInput, $lang: String) {
  createTemplate(template: $template, lang: $lang) {
    template {
      id
    }
    errors
  }
}
    `;
export type CreateTemplateMutationFn = Apollo.MutationFunction<CreateTemplateMutation, CreateTemplateMutationVariables>;

/**
 * __useCreateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateMutation, { data, loading, error }] = useCreateTemplateMutation({
 *   variables: {
 *      template: // value for 'template'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useCreateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateTemplateMutation, CreateTemplateMutationVariables>) {
        return Apollo.useMutation<CreateTemplateMutation, CreateTemplateMutationVariables>(CreateTemplateDocument, baseOptions);
      }
export type CreateTemplateMutationHookResult = ReturnType<typeof useCreateTemplateMutation>;
export type CreateTemplateMutationResult = Apollo.MutationResult<CreateTemplateMutation>;
export type CreateTemplateMutationOptions = Apollo.BaseMutationOptions<CreateTemplateMutation, CreateTemplateMutationVariables>;
export const DeleteContestDocument = gql`
    mutation DeleteContest($uid: UUID!) {
  deleteContest(uid: $uid) {
    errors
  }
}
    `;
export type DeleteContestMutationFn = Apollo.MutationFunction<DeleteContestMutation, DeleteContestMutationVariables>;

/**
 * __useDeleteContestMutation__
 *
 * To run a mutation, you first call `useDeleteContestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContestMutation, { data, loading, error }] = useDeleteContestMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useDeleteContestMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContestMutation, DeleteContestMutationVariables>) {
        return Apollo.useMutation<DeleteContestMutation, DeleteContestMutationVariables>(DeleteContestDocument, baseOptions);
      }
export type DeleteContestMutationHookResult = ReturnType<typeof useDeleteContestMutation>;
export type DeleteContestMutationResult = Apollo.MutationResult<DeleteContestMutation>;
export type DeleteContestMutationOptions = Apollo.BaseMutationOptions<DeleteContestMutation, DeleteContestMutationVariables>;
export const DeletePhotoDocument = gql`
    mutation DeletePhoto($id: ID!) {
  deletePhoto(id: $id) {
    errors
  }
}
    `;
export type DeletePhotoMutationFn = Apollo.MutationFunction<DeletePhotoMutation, DeletePhotoMutationVariables>;

/**
 * __useDeletePhotoMutation__
 *
 * To run a mutation, you first call `useDeletePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhotoMutation, { data, loading, error }] = useDeletePhotoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePhotoMutation(baseOptions?: Apollo.MutationHookOptions<DeletePhotoMutation, DeletePhotoMutationVariables>) {
        return Apollo.useMutation<DeletePhotoMutation, DeletePhotoMutationVariables>(DeletePhotoDocument, baseOptions);
      }
export type DeletePhotoMutationHookResult = ReturnType<typeof useDeletePhotoMutation>;
export type DeletePhotoMutationResult = Apollo.MutationResult<DeletePhotoMutation>;
export type DeletePhotoMutationOptions = Apollo.BaseMutationOptions<DeletePhotoMutation, DeletePhotoMutationVariables>;
export const DeleteTemplateDocument = gql`
    mutation DeleteTemplate($pk: ID) {
  deleteTemplate(pk: $pk) {
    errors
  }
}
    `;
export type DeleteTemplateMutationFn = Apollo.MutationFunction<DeleteTemplateMutation, DeleteTemplateMutationVariables>;

/**
 * __useDeleteTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateMutation, { data, loading, error }] = useDeleteTemplateMutation({
 *   variables: {
 *      pk: // value for 'pk'
 *   },
 * });
 */
export function useDeleteTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>) {
        return Apollo.useMutation<DeleteTemplateMutation, DeleteTemplateMutationVariables>(DeleteTemplateDocument, baseOptions);
      }
export type DeleteTemplateMutationHookResult = ReturnType<typeof useDeleteTemplateMutation>;
export type DeleteTemplateMutationResult = Apollo.MutationResult<DeleteTemplateMutation>;
export type DeleteTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>;
export const DuplicateTemplateDocument = gql`
    mutation DuplicateTemplate($templateId: ID!) {
  duplicateTemplate(templateId: $templateId) {
    errors
    newTemplate {
      id
      name
      columnsName
      columnsNameRu
      columnsNameUk
      columnsNameEn
      columnsNamePl
      columnsNameRo
      columnsNameAz
      forsageName
      forsageNameRu
      forsageNameUk
      forsageNameEn
      forsageNamePl
      forsageNameRo
      forsageNameAz
    }
  }
}
    `;
export type DuplicateTemplateMutationFn = Apollo.MutationFunction<DuplicateTemplateMutation, DuplicateTemplateMutationVariables>;

/**
 * __useDuplicateTemplateMutation__
 *
 * To run a mutation, you first call `useDuplicateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateTemplateMutation, { data, loading, error }] = useDuplicateTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useDuplicateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateTemplateMutation, DuplicateTemplateMutationVariables>) {
        return Apollo.useMutation<DuplicateTemplateMutation, DuplicateTemplateMutationVariables>(DuplicateTemplateDocument, baseOptions);
      }
export type DuplicateTemplateMutationHookResult = ReturnType<typeof useDuplicateTemplateMutation>;
export type DuplicateTemplateMutationResult = Apollo.MutationResult<DuplicateTemplateMutation>;
export type DuplicateTemplateMutationOptions = Apollo.BaseMutationOptions<DuplicateTemplateMutation, DuplicateTemplateMutationVariables>;
export const ParticipateDocument = gql`
    mutation Participate($code: String!, $email: String!, $isTest: Boolean) {
  participate(code: $code, email: $email, isTest: $isTest) {
    participant {
      id
      code
      testCode
      testsDaily
      testsPlayedToday
    }
    errors
  }
}
    `;
export type ParticipateMutationFn = Apollo.MutationFunction<ParticipateMutation, ParticipateMutationVariables>;

/**
 * __useParticipateMutation__
 *
 * To run a mutation, you first call `useParticipateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParticipateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [participateMutation, { data, loading, error }] = useParticipateMutation({
 *   variables: {
 *      code: // value for 'code'
 *      email: // value for 'email'
 *      isTest: // value for 'isTest'
 *   },
 * });
 */
export function useParticipateMutation(baseOptions?: Apollo.MutationHookOptions<ParticipateMutation, ParticipateMutationVariables>) {
        return Apollo.useMutation<ParticipateMutation, ParticipateMutationVariables>(ParticipateDocument, baseOptions);
      }
export type ParticipateMutationHookResult = ReturnType<typeof useParticipateMutation>;
export type ParticipateMutationResult = Apollo.MutationResult<ParticipateMutation>;
export type ParticipateMutationOptions = Apollo.BaseMutationOptions<ParticipateMutation, ParticipateMutationVariables>;
export const ResetParticipantDocument = gql`
    mutation ResetParticipant($code: String!) {
  resetParticipant(code: $code) {
    participant {
      id
      code
    }
    errors
  }
}
    `;
export type ResetParticipantMutationFn = Apollo.MutationFunction<ResetParticipantMutation, ResetParticipantMutationVariables>;

/**
 * __useResetParticipantMutation__
 *
 * To run a mutation, you first call `useResetParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetParticipantMutation, { data, loading, error }] = useResetParticipantMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useResetParticipantMutation(baseOptions?: Apollo.MutationHookOptions<ResetParticipantMutation, ResetParticipantMutationVariables>) {
        return Apollo.useMutation<ResetParticipantMutation, ResetParticipantMutationVariables>(ResetParticipantDocument, baseOptions);
      }
export type ResetParticipantMutationHookResult = ReturnType<typeof useResetParticipantMutation>;
export type ResetParticipantMutationResult = Apollo.MutationResult<ResetParticipantMutation>;
export type ResetParticipantMutationOptions = Apollo.BaseMutationOptions<ResetParticipantMutation, ResetParticipantMutationVariables>;
export const SetAnswerDocument = gql`
    mutation SetAnswer($answer: [Int]!, $code: String!, $exerciseId: ID!, $isTest: Boolean!, $lang: String!, $seconds: Float) {
  setAnswer(
    answer: $answer
    code: $code
    exerciseId: $exerciseId
    lang: $lang
    isTest: $isTest
    seconds: $seconds
  ) {
    exercise {
      data
      exerciseId
      exerciseType
      isLastColumn
      columnNumber
      forsageNumber
      isLastForsage
      participant {
        id
      }
      errors
    }
  }
}
    `;
export type SetAnswerMutationFn = Apollo.MutationFunction<SetAnswerMutation, SetAnswerMutationVariables>;

/**
 * __useSetAnswerMutation__
 *
 * To run a mutation, you first call `useSetAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAnswerMutation, { data, loading, error }] = useSetAnswerMutation({
 *   variables: {
 *      answer: // value for 'answer'
 *      code: // value for 'code'
 *      exerciseId: // value for 'exerciseId'
 *      isTest: // value for 'isTest'
 *      lang: // value for 'lang'
 *      seconds: // value for 'seconds'
 *   },
 * });
 */
export function useSetAnswerMutation(baseOptions?: Apollo.MutationHookOptions<SetAnswerMutation, SetAnswerMutationVariables>) {
        return Apollo.useMutation<SetAnswerMutation, SetAnswerMutationVariables>(SetAnswerDocument, baseOptions);
      }
export type SetAnswerMutationHookResult = ReturnType<typeof useSetAnswerMutation>;
export type SetAnswerMutationResult = Apollo.MutationResult<SetAnswerMutation>;
export type SetAnswerMutationOptions = Apollo.BaseMutationOptions<SetAnswerMutation, SetAnswerMutationVariables>;
export const StartCountdownDocument = gql`
    mutation StartCountdown($code: String!) {
  startCountdown(code: $code) {
    errors
  }
}
    `;
export type StartCountdownMutationFn = Apollo.MutationFunction<StartCountdownMutation, StartCountdownMutationVariables>;

/**
 * __useStartCountdownMutation__
 *
 * To run a mutation, you first call `useStartCountdownMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartCountdownMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startCountdownMutation, { data, loading, error }] = useStartCountdownMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useStartCountdownMutation(baseOptions?: Apollo.MutationHookOptions<StartCountdownMutation, StartCountdownMutationVariables>) {
        return Apollo.useMutation<StartCountdownMutation, StartCountdownMutationVariables>(StartCountdownDocument, baseOptions);
      }
export type StartCountdownMutationHookResult = ReturnType<typeof useStartCountdownMutation>;
export type StartCountdownMutationResult = Apollo.MutationResult<StartCountdownMutation>;
export type StartCountdownMutationOptions = Apollo.BaseMutationOptions<StartCountdownMutation, StartCountdownMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken($token: String) {
  refreshToken(token: $token) {
    payload
    refreshExpiresIn
    token
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, baseOptions);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const SetContestAboutDocument = gql`
    mutation SetContestAbout($file: Upload!) {
  setContestAbout(file: $file) {
    configuration {
      id
      about
    }
    errors
  }
}
    `;
export type SetContestAboutMutationFn = Apollo.MutationFunction<SetContestAboutMutation, SetContestAboutMutationVariables>;

/**
 * __useSetContestAboutMutation__
 *
 * To run a mutation, you first call `useSetContestAboutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetContestAboutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setContestAboutMutation, { data, loading, error }] = useSetContestAboutMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useSetContestAboutMutation(baseOptions?: Apollo.MutationHookOptions<SetContestAboutMutation, SetContestAboutMutationVariables>) {
        return Apollo.useMutation<SetContestAboutMutation, SetContestAboutMutationVariables>(SetContestAboutDocument, baseOptions);
      }
export type SetContestAboutMutationHookResult = ReturnType<typeof useSetContestAboutMutation>;
export type SetContestAboutMutationResult = Apollo.MutationResult<SetContestAboutMutation>;
export type SetContestAboutMutationOptions = Apollo.BaseMutationOptions<SetContestAboutMutation, SetContestAboutMutationVariables>;
export const SetGaDocument = gql`
    mutation SetGA($googleAnalytics: String!) {
  setGA(googleAnalytics: $googleAnalytics) {
    errors
    configuration {
      id
      gaData
      pixelData
      agreementText
    }
  }
}
    `;
export type SetGaMutationFn = Apollo.MutationFunction<SetGaMutation, SetGaMutationVariables>;

/**
 * __useSetGaMutation__
 *
 * To run a mutation, you first call `useSetGaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGaMutation, { data, loading, error }] = useSetGaMutation({
 *   variables: {
 *      googleAnalytics: // value for 'googleAnalytics'
 *   },
 * });
 */
export function useSetGaMutation(baseOptions?: Apollo.MutationHookOptions<SetGaMutation, SetGaMutationVariables>) {
        return Apollo.useMutation<SetGaMutation, SetGaMutationVariables>(SetGaDocument, baseOptions);
      }
export type SetGaMutationHookResult = ReturnType<typeof useSetGaMutation>;
export type SetGaMutationResult = Apollo.MutationResult<SetGaMutation>;
export type SetGaMutationOptions = Apollo.BaseMutationOptions<SetGaMutation, SetGaMutationVariables>;
export const SetPixelDocument = gql`
    mutation SetPixel($facebookPixel: String!) {
  setPixel(facebookPixel: $facebookPixel) {
    errors
    configuration {
      id
      gaData
      pixelData
      agreementText
    }
  }
}
    `;
export type SetPixelMutationFn = Apollo.MutationFunction<SetPixelMutation, SetPixelMutationVariables>;

/**
 * __useSetPixelMutation__
 *
 * To run a mutation, you first call `useSetPixelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPixelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPixelMutation, { data, loading, error }] = useSetPixelMutation({
 *   variables: {
 *      facebookPixel: // value for 'facebookPixel'
 *   },
 * });
 */
export function useSetPixelMutation(baseOptions?: Apollo.MutationHookOptions<SetPixelMutation, SetPixelMutationVariables>) {
        return Apollo.useMutation<SetPixelMutation, SetPixelMutationVariables>(SetPixelDocument, baseOptions);
      }
export type SetPixelMutationHookResult = ReturnType<typeof useSetPixelMutation>;
export type SetPixelMutationResult = Apollo.MutationResult<SetPixelMutation>;
export type SetPixelMutationOptions = Apollo.BaseMutationOptions<SetPixelMutation, SetPixelMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($participantInput: ParticipantInput!) {
  signUp(participantInput: $participantInput) {
    participant {
      id
    }
    errors
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      participantInput: // value for 'participantInput'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, baseOptions);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const TokenAuthDocument = gql`
    mutation TokenAuth($email: String!, $password: String!) {
  tokenAuth(email: $email, password: $password) {
    payload
    refreshExpiresIn
    token
  }
}
    `;
export type TokenAuthMutationFn = Apollo.MutationFunction<TokenAuthMutation, TokenAuthMutationVariables>;

/**
 * __useTokenAuthMutation__
 *
 * To run a mutation, you first call `useTokenAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenAuthMutation, { data, loading, error }] = useTokenAuthMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useTokenAuthMutation(baseOptions?: Apollo.MutationHookOptions<TokenAuthMutation, TokenAuthMutationVariables>) {
        return Apollo.useMutation<TokenAuthMutation, TokenAuthMutationVariables>(TokenAuthDocument, baseOptions);
      }
export type TokenAuthMutationHookResult = ReturnType<typeof useTokenAuthMutation>;
export type TokenAuthMutationResult = Apollo.MutationResult<TokenAuthMutation>;
export type TokenAuthMutationOptions = Apollo.BaseMutationOptions<TokenAuthMutation, TokenAuthMutationVariables>;
export const UpdateContestDocument = gql`
    mutation UpdateContest($contest: UpdateContestInput!) {
  updateContest(contest: $contest) {
    errors
    contest {
      id
      prices {
        id
        amount
        currency
      }
    }
  }
}
    `;
export type UpdateContestMutationFn = Apollo.MutationFunction<UpdateContestMutation, UpdateContestMutationVariables>;

/**
 * __useUpdateContestMutation__
 *
 * To run a mutation, you first call `useUpdateContestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContestMutation, { data, loading, error }] = useUpdateContestMutation({
 *   variables: {
 *      contest: // value for 'contest'
 *   },
 * });
 */
export function useUpdateContestMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContestMutation, UpdateContestMutationVariables>) {
        return Apollo.useMutation<UpdateContestMutation, UpdateContestMutationVariables>(UpdateContestDocument, baseOptions);
      }
export type UpdateContestMutationHookResult = ReturnType<typeof useUpdateContestMutation>;
export type UpdateContestMutationResult = Apollo.MutationResult<UpdateContestMutation>;
export type UpdateContestMutationOptions = Apollo.BaseMutationOptions<UpdateContestMutation, UpdateContestMutationVariables>;
export const UpdateDataDocument = gql`
    mutation UpdateData($configInput: ConfigurationInput, $lang: String!) {
  updateData(configurationInput: $configInput, lang: $lang) {
    errors
    configuration {
      ...ConfigurationFragment
      countryZone {
        ...CountryZone
      }
      currentContest {
        startDate
        endDate
      }
    }
  }
}
    ${ConfigurationFragmentFragmentDoc}
${CountryZoneFragmentDoc}`;
export type UpdateDataMutationFn = Apollo.MutationFunction<UpdateDataMutation, UpdateDataMutationVariables>;

/**
 * __useUpdateDataMutation__
 *
 * To run a mutation, you first call `useUpdateDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataMutation, { data, loading, error }] = useUpdateDataMutation({
 *   variables: {
 *      configInput: // value for 'configInput'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useUpdateDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDataMutation, UpdateDataMutationVariables>) {
        return Apollo.useMutation<UpdateDataMutation, UpdateDataMutationVariables>(UpdateDataDocument, baseOptions);
      }
export type UpdateDataMutationHookResult = ReturnType<typeof useUpdateDataMutation>;
export type UpdateDataMutationResult = Apollo.MutationResult<UpdateDataMutation>;
export type UpdateDataMutationOptions = Apollo.BaseMutationOptions<UpdateDataMutation, UpdateDataMutationVariables>;
export const UpdateTemplateDocument = gql`
    mutation UpdateTemplate($template: TemplateInput!, $lang: String) {
  updateTemplate(template: $template, lang: $lang) {
    errors
    template {
      id
      name
      columnsName
      columnsText
      forsageName
      forsageText
      year {
        ...YearFragment
      }
      difficulty {
        ...DifficultyFragment
      }
      rounds {
        ...Round
      }
      blocks {
        ...Block
      }
    }
  }
}
    ${YearFragmentFragmentDoc}
${DifficultyFragmentFragmentDoc}
${RoundFragmentDoc}
${BlockFragmentDoc}`;
export type UpdateTemplateMutationFn = Apollo.MutationFunction<UpdateTemplateMutation, UpdateTemplateMutationVariables>;

/**
 * __useUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateMutation, { data, loading, error }] = useUpdateTemplateMutation({
 *   variables: {
 *      template: // value for 'template'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useUpdateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>) {
        return Apollo.useMutation<UpdateTemplateMutation, UpdateTemplateMutationVariables>(UpdateTemplateDocument, baseOptions);
      }
export type UpdateTemplateMutationHookResult = ReturnType<typeof useUpdateTemplateMutation>;
export type UpdateTemplateMutationResult = Apollo.MutationResult<UpdateTemplateMutation>;
export type UpdateTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>;
export const UploadDeckDocument = gql`
    mutation UploadDeck($file: Upload!, $lang: String!) {
  uploadDeck(file: $file, lang: $lang) {
    errors
  }
}
    `;
export type UploadDeckMutationFn = Apollo.MutationFunction<UploadDeckMutation, UploadDeckMutationVariables>;

/**
 * __useUploadDeckMutation__
 *
 * To run a mutation, you first call `useUploadDeckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDeckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDeckMutation, { data, loading, error }] = useUploadDeckMutation({
 *   variables: {
 *      file: // value for 'file'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useUploadDeckMutation(baseOptions?: Apollo.MutationHookOptions<UploadDeckMutation, UploadDeckMutationVariables>) {
        return Apollo.useMutation<UploadDeckMutation, UploadDeckMutationVariables>(UploadDeckDocument, baseOptions);
      }
export type UploadDeckMutationHookResult = ReturnType<typeof useUploadDeckMutation>;
export type UploadDeckMutationResult = Apollo.MutationResult<UploadDeckMutation>;
export type UploadDeckMutationOptions = Apollo.BaseMutationOptions<UploadDeckMutation, UploadDeckMutationVariables>;
export const UploadImagesDocument = gql`
    mutation UploadImages($file: Upload!, $id: ID) {
  uploadPhoto(file: $file, id: $id) {
    success
  }
}
    `;
export type UploadImagesMutationFn = Apollo.MutationFunction<UploadImagesMutation, UploadImagesMutationVariables>;

/**
 * __useUploadImagesMutation__
 *
 * To run a mutation, you first call `useUploadImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImagesMutation, { data, loading, error }] = useUploadImagesMutation({
 *   variables: {
 *      file: // value for 'file'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUploadImagesMutation(baseOptions?: Apollo.MutationHookOptions<UploadImagesMutation, UploadImagesMutationVariables>) {
        return Apollo.useMutation<UploadImagesMutation, UploadImagesMutationVariables>(UploadImagesDocument, baseOptions);
      }
export type UploadImagesMutationHookResult = ReturnType<typeof useUploadImagesMutation>;
export type UploadImagesMutationResult = Apollo.MutationResult<UploadImagesMutation>;
export type UploadImagesMutationOptions = Apollo.BaseMutationOptions<UploadImagesMutation, UploadImagesMutationVariables>;
export const VerifyTokenDocument = gql`
    mutation VerifyToken($token: String) {
  verifyToken(token: $token) {
    payload
  }
}
    `;
export type VerifyTokenMutationFn = Apollo.MutationFunction<VerifyTokenMutation, VerifyTokenMutationVariables>;

/**
 * __useVerifyTokenMutation__
 *
 * To run a mutation, you first call `useVerifyTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTokenMutation, { data, loading, error }] = useVerifyTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyTokenMutation(baseOptions?: Apollo.MutationHookOptions<VerifyTokenMutation, VerifyTokenMutationVariables>) {
        return Apollo.useMutation<VerifyTokenMutation, VerifyTokenMutationVariables>(VerifyTokenDocument, baseOptions);
      }
export type VerifyTokenMutationHookResult = ReturnType<typeof useVerifyTokenMutation>;
export type VerifyTokenMutationResult = Apollo.MutationResult<VerifyTokenMutation>;
export type VerifyTokenMutationOptions = Apollo.BaseMutationOptions<VerifyTokenMutation, VerifyTokenMutationVariables>;
export const CitiesDocument = gql`
    query Cities($countryId: Int, $lang: String) {
  cities(countryId: $countryId, lang: $lang) {
    name
    id
  }
}
    `;

/**
 * __useCitiesQuery__
 *
 * To run a query within a React component, call `useCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useCitiesQuery(baseOptions?: Apollo.QueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
        return Apollo.useQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, baseOptions);
      }
export function useCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
          return Apollo.useLazyQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, baseOptions);
        }
export type CitiesQueryHookResult = ReturnType<typeof useCitiesQuery>;
export type CitiesLazyQueryHookResult = ReturnType<typeof useCitiesLazyQuery>;
export type CitiesQueryResult = Apollo.QueryResult<CitiesQuery, CitiesQueryVariables>;
export const ConfigurationDocument = gql`
    query Configuration($lang: String!) {
  configuration(lang: $lang) {
    fondyTerms
    photos {
      file
      url
      id
    }
    id
    contestState
    ...ConfigurationFragment
    countryZone {
      ...CountryZone
    }
    timezone
    about
    decks {
      id
      lang
      file
      url
    }
    lastContest {
      yearsList
      difficultiesList
      id
    }
  }
}
    ${ConfigurationFragmentFragmentDoc}
${CountryZoneFragmentDoc}`;

/**
 * __useConfigurationQuery__
 *
 * To run a query within a React component, call `useConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigurationQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useConfigurationQuery(baseOptions: Apollo.QueryHookOptions<ConfigurationQuery, ConfigurationQueryVariables>) {
        return Apollo.useQuery<ConfigurationQuery, ConfigurationQueryVariables>(ConfigurationDocument, baseOptions);
      }
export function useConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfigurationQuery, ConfigurationQueryVariables>) {
          return Apollo.useLazyQuery<ConfigurationQuery, ConfigurationQueryVariables>(ConfigurationDocument, baseOptions);
        }
export type ConfigurationQueryHookResult = ReturnType<typeof useConfigurationQuery>;
export type ConfigurationLazyQueryHookResult = ReturnType<typeof useConfigurationLazyQuery>;
export type ConfigurationQueryResult = Apollo.QueryResult<ConfigurationQuery, ConfigurationQueryVariables>;
export const ConfigurationMinDocument = gql`
    query ConfigurationMin($lang: String!) {
  configuration(lang: $lang) {
    lastContest {
      id
      yearsList
      difficultiesList
    }
    contestState
    totalContestsPassed
    totalContestsFuture
    countryCode
    currency
    countryZone {
      name
      code
    }
  }
}
    `;

/**
 * __useConfigurationMinQuery__
 *
 * To run a query within a React component, call `useConfigurationMinQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigurationMinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigurationMinQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useConfigurationMinQuery(baseOptions: Apollo.QueryHookOptions<ConfigurationMinQuery, ConfigurationMinQueryVariables>) {
        return Apollo.useQuery<ConfigurationMinQuery, ConfigurationMinQueryVariables>(ConfigurationMinDocument, baseOptions);
      }
export function useConfigurationMinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfigurationMinQuery, ConfigurationMinQueryVariables>) {
          return Apollo.useLazyQuery<ConfigurationMinQuery, ConfigurationMinQueryVariables>(ConfigurationMinDocument, baseOptions);
        }
export type ConfigurationMinQueryHookResult = ReturnType<typeof useConfigurationMinQuery>;
export type ConfigurationMinLazyQueryHookResult = ReturnType<typeof useConfigurationMinLazyQuery>;
export type ConfigurationMinQueryResult = Apollo.QueryResult<ConfigurationMinQuery, ConfigurationMinQueryVariables>;
export const ContestResultsDocument = gql`
    query ContestResults($contestId: UUID!, $yearName: String!, $difficultyName: String!, $lang: String!, $cityId: ID, $countryId: ID) {
  contestResults(
    lang: $lang
    contestId: $contestId
    yearName: $yearName
    difficultyName: $difficultyName
    cityId: $cityId
    countryId: $countryId
  ) {
    lastName
    firstName
    points
    country {
      name
    }
    city
    livingCity {
      name
      id
    }
  }
}
    `;

/**
 * __useContestResultsQuery__
 *
 * To run a query within a React component, call `useContestResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContestResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContestResultsQuery({
 *   variables: {
 *      contestId: // value for 'contestId'
 *      yearName: // value for 'yearName'
 *      difficultyName: // value for 'difficultyName'
 *      lang: // value for 'lang'
 *      cityId: // value for 'cityId'
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useContestResultsQuery(baseOptions: Apollo.QueryHookOptions<ContestResultsQuery, ContestResultsQueryVariables>) {
        return Apollo.useQuery<ContestResultsQuery, ContestResultsQueryVariables>(ContestResultsDocument, baseOptions);
      }
export function useContestResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContestResultsQuery, ContestResultsQueryVariables>) {
          return Apollo.useLazyQuery<ContestResultsQuery, ContestResultsQueryVariables>(ContestResultsDocument, baseOptions);
        }
export type ContestResultsQueryHookResult = ReturnType<typeof useContestResultsQuery>;
export type ContestResultsLazyQueryHookResult = ReturnType<typeof useContestResultsLazyQuery>;
export type ContestResultsQueryResult = Apollo.QueryResult<ContestResultsQuery, ContestResultsQueryVariables>;
export const ContestsDocument = gql`
    query Contests($lang: String!, $state: ContestStateEnum, $offset: Int, $limit: Int, $sorting: [String]) {
  contests(
    lang: $lang
    state: $state
    limit: $limit
    offset: $offset
    sorting: $sorting
  ) {
    contests {
      id
      startDate
      endDate
      name
      templates {
        id
        name
      }
      participants {
        id
        firstName
      }
      prices {
        id
        amount
        currency
      }
      participantsPaid {
        id
      }
      participantsTest {
        id
      }
      yearsList
      years {
        ...YearFragment
      }
      difficultiesList
      difficulties {
        ...DifficultyFragment
      }
    }
    meta {
      pagesTotal
      page
      limit
      itemsTotal
    }
  }
}
    ${YearFragmentFragmentDoc}
${DifficultyFragmentFragmentDoc}`;

/**
 * __useContestsQuery__
 *
 * To run a query within a React component, call `useContestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContestsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      state: // value for 'state'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useContestsQuery(baseOptions: Apollo.QueryHookOptions<ContestsQuery, ContestsQueryVariables>) {
        return Apollo.useQuery<ContestsQuery, ContestsQueryVariables>(ContestsDocument, baseOptions);
      }
export function useContestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContestsQuery, ContestsQueryVariables>) {
          return Apollo.useLazyQuery<ContestsQuery, ContestsQueryVariables>(ContestsDocument, baseOptions);
        }
export type ContestsQueryHookResult = ReturnType<typeof useContestsQuery>;
export type ContestsLazyQueryHookResult = ReturnType<typeof useContestsLazyQuery>;
export type ContestsQueryResult = Apollo.QueryResult<ContestsQuery, ContestsQueryVariables>;
export const ShortContestsDocument = gql`
    query ShortContests($lang: String!, $state: ContestStateEnum, $offset: Int, $limit: Int) {
  contests(lang: $lang, state: $state, limit: $limit, offset: $offset) {
    meta {
      pagesTotal
      page
      limit
      itemsTotal
    }
    contests {
      id
      name
    }
  }
}
    `;

/**
 * __useShortContestsQuery__
 *
 * To run a query within a React component, call `useShortContestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShortContestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShortContestsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      state: // value for 'state'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useShortContestsQuery(baseOptions: Apollo.QueryHookOptions<ShortContestsQuery, ShortContestsQueryVariables>) {
        return Apollo.useQuery<ShortContestsQuery, ShortContestsQueryVariables>(ShortContestsDocument, baseOptions);
      }
export function useShortContestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShortContestsQuery, ShortContestsQueryVariables>) {
          return Apollo.useLazyQuery<ShortContestsQuery, ShortContestsQueryVariables>(ShortContestsDocument, baseOptions);
        }
export type ShortContestsQueryHookResult = ReturnType<typeof useShortContestsQuery>;
export type ShortContestsLazyQueryHookResult = ReturnType<typeof useShortContestsLazyQuery>;
export type ShortContestsQueryResult = Apollo.QueryResult<ShortContestsQuery, ShortContestsQueryVariables>;
export const CountriesDocument = gql`
    query Countries($lang: String) {
  countries(lang: $lang) {
    id
    name
    nameRu
    nameUk
    nameEn
  }
}
    `;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
        return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, baseOptions);
      }
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, baseOptions);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const CurrentContestDocument = gql`
    query CurrentContest($lang: String!) {
  currentContest(lang: $lang) {
    agreement
    startDate
    endDate
    id
    prices {
      id
      currency
      amount
    }
    yearsList
    difficultiesList
    years {
      ...YearFragment
    }
    difficulties {
      ...DifficultyFragment
    }
  }
}
    ${YearFragmentFragmentDoc}
${DifficultyFragmentFragmentDoc}`;

/**
 * __useCurrentContestQuery__
 *
 * To run a query within a React component, call `useCurrentContestQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentContestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentContestQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useCurrentContestQuery(baseOptions: Apollo.QueryHookOptions<CurrentContestQuery, CurrentContestQueryVariables>) {
        return Apollo.useQuery<CurrentContestQuery, CurrentContestQueryVariables>(CurrentContestDocument, baseOptions);
      }
export function useCurrentContestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentContestQuery, CurrentContestQueryVariables>) {
          return Apollo.useLazyQuery<CurrentContestQuery, CurrentContestQueryVariables>(CurrentContestDocument, baseOptions);
        }
export type CurrentContestQueryHookResult = ReturnType<typeof useCurrentContestQuery>;
export type CurrentContestLazyQueryHookResult = ReturnType<typeof useCurrentContestLazyQuery>;
export type CurrentContestQueryResult = Apollo.QueryResult<CurrentContestQuery, CurrentContestQueryVariables>;
export const DifficultiesDocument = gql`
    query Difficulties($yearName: String!, $lang: String!) {
  difficulties(yearName: $yearName, lang: $lang) {
    name
    id
  }
}
    `;

/**
 * __useDifficultiesQuery__
 *
 * To run a query within a React component, call `useDifficultiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDifficultiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDifficultiesQuery({
 *   variables: {
 *      yearName: // value for 'yearName'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useDifficultiesQuery(baseOptions: Apollo.QueryHookOptions<DifficultiesQuery, DifficultiesQueryVariables>) {
        return Apollo.useQuery<DifficultiesQuery, DifficultiesQueryVariables>(DifficultiesDocument, baseOptions);
      }
export function useDifficultiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DifficultiesQuery, DifficultiesQueryVariables>) {
          return Apollo.useLazyQuery<DifficultiesQuery, DifficultiesQueryVariables>(DifficultiesDocument, baseOptions);
        }
export type DifficultiesQueryHookResult = ReturnType<typeof useDifficultiesQuery>;
export type DifficultiesLazyQueryHookResult = ReturnType<typeof useDifficultiesLazyQuery>;
export type DifficultiesQueryResult = Apollo.QueryResult<DifficultiesQuery, DifficultiesQueryVariables>;
export const GetExerciseDocument = gql`
    query GetExercise($code: String, $lang: String) {
  getExercise(code: $code, lang: $lang) {
    data
    exerciseId
    exerciseType
    exerciseOrderNumber
    exerciseOrderNumbersTotal
    isLastColumn
    columnNumber
    forsageNumber
    isLastForsage
    errors
    exercise {
      points
      template {
        year {
          id
          name
        }
        difficulty {
          id
          name
        }
        columnsName
        columnsText
        forsageName
        forsageText
        blocks {
          id
          timeout
          number
          examples
          difficulty
        }
        rounds {
          id
          timeout
        }
      }
    }
    participant {
      code
      points
      testCode
    }
  }
}
    `;

/**
 * __useGetExerciseQuery__
 *
 * To run a query within a React component, call `useGetExerciseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExerciseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExerciseQuery({
 *   variables: {
 *      code: // value for 'code'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetExerciseQuery(baseOptions?: Apollo.QueryHookOptions<GetExerciseQuery, GetExerciseQueryVariables>) {
        return Apollo.useQuery<GetExerciseQuery, GetExerciseQueryVariables>(GetExerciseDocument, baseOptions);
      }
export function useGetExerciseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExerciseQuery, GetExerciseQueryVariables>) {
          return Apollo.useLazyQuery<GetExerciseQuery, GetExerciseQueryVariables>(GetExerciseDocument, baseOptions);
        }
export type GetExerciseQueryHookResult = ReturnType<typeof useGetExerciseQuery>;
export type GetExerciseLazyQueryHookResult = ReturnType<typeof useGetExerciseLazyQuery>;
export type GetExerciseQueryResult = Apollo.QueryResult<GetExerciseQuery, GetExerciseQueryVariables>;
export const GetParticipantDocument = gql`
    query GetParticipant($email: String!, $code: String!) {
  getParticipant(email: $email, code: $code) {
    points
    results
  }
}
    `;

/**
 * __useGetParticipantQuery__
 *
 * To run a query within a React component, call `useGetParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantQuery({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetParticipantQuery(baseOptions: Apollo.QueryHookOptions<GetParticipantQuery, GetParticipantQueryVariables>) {
        return Apollo.useQuery<GetParticipantQuery, GetParticipantQueryVariables>(GetParticipantDocument, baseOptions);
      }
export function useGetParticipantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParticipantQuery, GetParticipantQueryVariables>) {
          return Apollo.useLazyQuery<GetParticipantQuery, GetParticipantQueryVariables>(GetParticipantDocument, baseOptions);
        }
export type GetParticipantQueryHookResult = ReturnType<typeof useGetParticipantQuery>;
export type GetParticipantLazyQueryHookResult = ReturnType<typeof useGetParticipantLazyQuery>;
export type GetParticipantQueryResult = Apollo.QueryResult<GetParticipantQuery, GetParticipantQueryVariables>;
export const ParticipantsDocument = gql`
    query Participants($page: Int, $limit: Int, $contestId: UUID!, $difficultyName: ID, $yearName: ID, $search: String, $lang: String!, $sorting: [String]) {
  participants(
    lang: $lang
    page: $page
    limit: $limit
    contestId: $contestId
    difficultyName: $difficultyName
    yearName: $yearName
    search: $search
    sorting: $sorting
  ) {
    participants {
      lastName
      firstName
      points
      id
      schoolName
      results
      country {
        ...CountryZone
      }
      city
    }
    meta {
      pagesTotal
      page
      limit
      itemsTotal
    }
    errors
  }
}
    ${CountryZoneFragmentDoc}`;

/**
 * __useParticipantsQuery__
 *
 * To run a query within a React component, call `useParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipantsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      contestId: // value for 'contestId'
 *      difficultyName: // value for 'difficultyName'
 *      yearName: // value for 'yearName'
 *      search: // value for 'search'
 *      lang: // value for 'lang'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useParticipantsQuery(baseOptions: Apollo.QueryHookOptions<ParticipantsQuery, ParticipantsQueryVariables>) {
        return Apollo.useQuery<ParticipantsQuery, ParticipantsQueryVariables>(ParticipantsDocument, baseOptions);
      }
export function useParticipantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParticipantsQuery, ParticipantsQueryVariables>) {
          return Apollo.useLazyQuery<ParticipantsQuery, ParticipantsQueryVariables>(ParticipantsDocument, baseOptions);
        }
export type ParticipantsQueryHookResult = ReturnType<typeof useParticipantsQuery>;
export type ParticipantsLazyQueryHookResult = ReturnType<typeof useParticipantsLazyQuery>;
export type ParticipantsQueryResult = Apollo.QueryResult<ParticipantsQuery, ParticipantsQueryVariables>;
export const TemplateDocument = gql`
    query Template($id: ID!, $lang: String) {
  template(id: $id, lang: $lang) {
    id
    name
    columnsName
    columnsText
    forsageName
    forsageText
    rounds {
      ...Round
    }
    blocks {
      ...Block
    }
    year {
      id
      name
    }
    difficulty {
      id
      name
    }
  }
}
    ${RoundFragmentDoc}
${BlockFragmentDoc}`;

/**
 * __useTemplateQuery__
 *
 * To run a query within a React component, call `useTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useTemplateQuery(baseOptions: Apollo.QueryHookOptions<TemplateQuery, TemplateQueryVariables>) {
        return Apollo.useQuery<TemplateQuery, TemplateQueryVariables>(TemplateDocument, baseOptions);
      }
export function useTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateQuery, TemplateQueryVariables>) {
          return Apollo.useLazyQuery<TemplateQuery, TemplateQueryVariables>(TemplateDocument, baseOptions);
        }
export type TemplateQueryHookResult = ReturnType<typeof useTemplateQuery>;
export type TemplateLazyQueryHookResult = ReturnType<typeof useTemplateLazyQuery>;
export type TemplateQueryResult = Apollo.QueryResult<TemplateQuery, TemplateQueryVariables>;
export const TemplatesDocument = gql`
    query Templates($lang: String) {
  templates(lang: $lang) {
    id
    name
    columnsName
    columnsText
    forsageName
    forsageText
    columnsNameRu
    columnsNameUk
    columnsNameEn
    columnsNamePl
    columnsNameRo
    columnsNameAz
    columnsNameHy
    columnsTextRu
    columnsTextUk
    columnsTextEn
    columnsTextPl
    columnsTextRo
    columnsTextAz
    columnsTextHy
    forsageNameRu
    forsageNameUk
    forsageNameEn
    forsageNamePl
    forsageNameRo
    forsageNameAz
    forsageNameHy
    forsageTextRu
    forsageTextUk
    forsageTextEn
    forsageTextPl
    forsageTextRo
    forsageTextAz
    forsageTextHy
    rounds {
      ...Round
    }
    blocks {
      ...Block
    }
    year {
      id
      name
    }
    difficulty {
      id
      name
    }
  }
}
    ${RoundFragmentDoc}
${BlockFragmentDoc}`;

/**
 * __useTemplatesQuery__
 *
 * To run a query within a React component, call `useTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<TemplatesQuery, TemplatesQueryVariables>) {
        return Apollo.useQuery<TemplatesQuery, TemplatesQueryVariables>(TemplatesDocument, baseOptions);
      }
export function useTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplatesQuery, TemplatesQueryVariables>) {
          return Apollo.useLazyQuery<TemplatesQuery, TemplatesQueryVariables>(TemplatesDocument, baseOptions);
        }
export type TemplatesQueryHookResult = ReturnType<typeof useTemplatesQuery>;
export type TemplatesLazyQueryHookResult = ReturnType<typeof useTemplatesLazyQuery>;
export type TemplatesQueryResult = Apollo.QueryResult<TemplatesQuery, TemplatesQueryVariables>;
export const TemplatesForSelectDocument = gql`
    query TemplatesForSelect($lang: String) {
  templates(lang: $lang) {
    id
    name
  }
}
    `;

/**
 * __useTemplatesForSelectQuery__
 *
 * To run a query within a React component, call `useTemplatesForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesForSelectQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useTemplatesForSelectQuery(baseOptions?: Apollo.QueryHookOptions<TemplatesForSelectQuery, TemplatesForSelectQueryVariables>) {
        return Apollo.useQuery<TemplatesForSelectQuery, TemplatesForSelectQueryVariables>(TemplatesForSelectDocument, baseOptions);
      }
export function useTemplatesForSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplatesForSelectQuery, TemplatesForSelectQueryVariables>) {
          return Apollo.useLazyQuery<TemplatesForSelectQuery, TemplatesForSelectQueryVariables>(TemplatesForSelectDocument, baseOptions);
        }
export type TemplatesForSelectQueryHookResult = ReturnType<typeof useTemplatesForSelectQuery>;
export type TemplatesForSelectLazyQueryHookResult = ReturnType<typeof useTemplatesForSelectLazyQuery>;
export type TemplatesForSelectQueryResult = Apollo.QueryResult<TemplatesForSelectQuery, TemplatesForSelectQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    