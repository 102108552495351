export default {
	exit: 'Выйти',
	warning: `<div>Будьте внимательны! </br> Есть только <strong>одна попытка</strong> ввода </br> регистрационного <strong>кода</strong> на следущем шаге</div>`,
	inputCode: 'Ввести код регистрации',
	startLater: 'Начать позже',
	olympiadIndigo: 'Онлайн-олимпиада INDIGO',
	olympiadIndigoTest: 'Протестируйте олимпиаду INDIGO',
	welcome: 'Добро пожаловать на Олимпиаду!',
	access: `Для доступа к заданию необходимо указать <br/><strong>e-mail</strong> и <strong>уникальный код</strong>`,
	accessTest: `Для доступа к заданиям необходимо указать <strong>электронную почту</strong>`,
	uniqueCode: 'Уникальный код',
	example: 'Например',
	auth: 'Авторизоваться',
	authTest: 'Авторизоваться',
	readyDescription: `Ты готов? </br><strong>Желаем удачи!</strong>`,
	goToExecution: 'Перейти к выполнению',
	ready: 'Приготовьтесь',
	nextStage: 'Следующий этап',
	finishStage: 'Следующий',
	finishAtALl: 'Завершить',
	start: 'Старт',
	startTask: 'Начать задание',
	answerAllExample: 'Ответ',
	inputAnswerInCell: `Введите ответ в ячейку. <br/> Если не успели посчитать, введите любой ответ.`,
	youDidIt: 'Поздравляем, ты прошел Олимпиаду!',
	youDidItTest: 'Ура, тестовое задание пройдено!',
	yourRecord: 'Твой результат:',
	yourRecordTest: 'Твой результат:',
	points: 'Баллов',
	pointsTest: 'Баллов',
	downloadResult: 'Скачать результат',
	endParticipation: 'Завершить участие',
	congratulation: `Поздравляем, ты  <strong>прошел</strong> Олимпиаду! <br/>Твой диплом участника на <strong>электронной почте</strong>. <br/>Ожидай завершения соревнований. </br>Желаем занять призовое место!`,
	payment: `Поздравляем, <strong>оплата</strong> прошла успешно!</br> Проверьте <strong>электронную почту</strong> </br>
	(если письмо не пришло, проверьте папку</br>
	 <strong>Спам или Промоакции</strong>) </br>и жди начала <strong>Олимпиады.</strong>`,
	email: 'email',
	attempt: 'Попытка',
	takeParticipate: `Примите участие в <strong>Олимпиаде</strong> и проверьте свои способности в соревновании с другими!`,
	tryAgain: 'Попробовать ещё',
	leaveTesting: 'Выход из тестирования',
	lookItUpResultPrev: `Ознакомьтесь с <strong>результатами</strong> Олимпиады`,
	showResult: 'Посмотреть результаты',
	ONE: 'Однозначные',
	TEN: 'Двузначные',
	HUNDRED: 'Трехзначные',
	THOUSAND: 'Четырехзначные',
	TEN_THOUSANDS: 'Пятизначные',
	actions: 'действий',
	speed: 'скорость',
	sec: 'сек',
	mental: '“Ментальная Арифметика”',
	prev: 'Назад',
	next: 'Следующий',
	completedStage: 'Завершить этап'
}
