export default {
	allCountry: 'Все страны',
	allCities: 'Все города',
	title: 'Регистрация',
	label: {
		email: 'Электронная почта',
		lastName: 'Фамилия ребёнка',
		firstName: 'Имя ребёнка',
		gender: 'Пол',
		country: 'Страна',
		city: 'Город',
		school: 'Школа ментальной арифметики',
		phone: 'Контактный телефон',
		level: 'Уровень сложности',
		birthday: 'Год рождения',
	},
	gender: {
		boy: 'Мальчик',
		girl: 'Девочка',
		notSay: 'Не хочу указывать',
	},
	pay: 'Оплатить',
	select: {
		selectCountry: 'Выберите страну',
		selectCity: 'Выберите город',
		selectYear: 'Выберите год рождения',
		selectLevel: 'Выберите уровень сложности',

	},
	notify: {
		title: 'Обратите внимание!',
		description: 'Указывайте только настоящие данные. Они будут опубликованы в результатах олимпиады, а также использованы при создании дипломов.',
	},
	notifyPhone: {
		title: 'Обратите внимание!',
		description: `Для обеспечения удобства и беспрерывной работы Олимпиада доступна к прохождению только на планшетах и компьютерах.`,
		hide: 'Перейти к игре',
	},
	errors: {
		globalError: 'Пожалуйста, заполните все поля выше!',
		fieldIsRequired: 'Поле обязательное для заполнения',
		numeric: 'Поле должно содержать только числа',
		minLength: 'Количесто символов должно быть больше чем ',
		maxLength: 'Количество символов должно быть меньше, чем',

		not_numeric: 'Строка не может содержать числа',
		email: 'Невалидный email',
		password: 'Поле должно содержать как минимум одну букву и одну цифру',
		notSymbol: 'Поле не должно содержать специальные символы',

		//Olympiad
		INVALID_CODE: 'Некорректный код',
		ALREADY_PARTICIPATED: 'Ты уже использовал свой код! Если ты хочешь пройти Олимпиаду повторно, нужно зарегистрироваться еще раз.',
		TIME_EXPIRED: 'Время истекло',
		FIRST_NAME_REQUIRED: 'Требуется поле "Имя"',
		LAST_NAME_REQUIRED: 'Требуется поле "Фамилия"',
		INVALID_EMAIL: 'Некорректный Email',
		INVALID_PHONE: 'Некорректный Телефон',
		INVALID_YEAR: 'Некорректная возрастная группа',
		INVALID_DIFFICULTY: 'Некорректный уровень сложности',
		INVALID_COUNTRY: 'Некорректная страна',
		NOT_AUTHENTICATED: 'Ошибка авторизированный',
		NOT_ADMIN: 'Вы не являетесь администратором',
		NOT_FOUND: 'Не найден',
		SHORT_PHONE: 'Указанная строка слишком коротка, чтобы быть номером телефона',
		'invalid phone': 'Неправильный номер телефона',
		LONG_PHONE: 'Указанная строка слишком длинна, чтобы быть номером телефона',
		'invalid email': 'Неверный адрес электронной почты',
		DAILY_TESTS_LIMIT: 'На сегодня твои попытки закончились, ты можешь приступить к тренировкам завтра',
		TEMPLATE_NOT_FOUND: 'Шаблон не найден',
	},
}
