export default {
  title: "Olimpiada 2021",
  subTitle: 'mental </br> hesabla',
  info: 'Olimpiadanın keçirilmə tarixləri: \n',
  info2: 'Tapşırıqları edə bilərsiniz \n' +
    'istənilən vaxt',
  moreAboutOlympiad: "Olimpiada haqqında daha ətraflı",
  testingOlympiad: "Olimpiadanı sınaqdan keçirtmək ",
  resultOlympiad: "Olimpiadanın nəticələri",
  olympiadNotStarted: 'Olimpiada hələ başlamayıb!',
  waitFinishedOlympiad: 'Nəticələr olimpiada bitdikdən sonra əldə edilə biləcək.'
}
