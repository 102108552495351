import {InMemoryCache} from "@apollo/client";
import {showMenuMobile, contestId} from "./state";

export const cache = new InMemoryCache({
  // addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        showMenuMobile: {
          read() {
            return showMenuMobile()
          }
        },
        contestId: {
          read(){
            return contestId()
          }
        }
      }
    }
  }
});
