export default {
	allCountry: 'Всі країни',
	allCities: 'Всі міста',
	title: 'Реєстрація',

	label: {
		email: 'Електронна пошта',
		lastName: 'Прізвище дитини',
		firstName: 'Iм\'я дитини',
		gender: 'Стать',
		country: 'Країна',
		city: 'Місто',
		school: 'Школа ментальної арифметики',
		phone: 'Контактний телефон',
		level: 'Рівень складності',
		birthday: 'Рік народження',
	},
	gender: {
		boy: 'Хлопчик',
		girl: 'Дівчинка',
		notSay: 'Не хочу вказувати',

	},
	pay: 'Оплатити',
	select: {
		selectCountry: 'Виберіть країну',
		selectCity: 'Виберіть місто',
		selectYear: 'Виберіть рік народження',
		selectLevel: 'Виберіть рівень складності',


	},
	notify: {
		title: 'Зверніть увагу!',
		description: 'Вказуйте тільки справжні дані. Вони будуть опубліковані в результатах олімпіади, а також використані при створенні дипломів.',
	},
	notifyPhone: {
		title: 'Зверніть увагу!',
		description: `Для забезпечення зручності та безперервної роботи Олімпіада доступна до проходження тільки на планшетах і комп'ютерах.`,
		hide: 'Перейти до гри',
	},
	errors: {
		globalError: 'Будь ласка, заповніть всі поля вище!',
		fieldIsRequired: 'Поле обов\'язкове для заповнення',
		numeric: 'Поле має містити тільки числа',
		minLength: 'Кількість символів має бути більше ніж ',
		maxLength: 'Кількість символов должно быть меньше, чем',
		not_numeric: 'Рядок не може містити числа',
		email: 'Невалідний email',
		password: 'Поле має містити як мінімум одну букву і одну цифру',
		notSymbol: 'Поле не повинно містити спеціальні символи',

		//Olympiad
		INVALID_CODE: 'Невірний код',
		ALREADY_PARTICIPATED: 'Ти вже використав свій код! Якщо ти хочеш пройти Олімпіаду повторно, потрібно зареєструватися ще раз.',
		TIME_EXPIRED: 'Час минув',
		FIRST_NAME_REQUIRED: 'Обов\'язкове поле "Ім\'я"',
		LAST_NAME_REQUIRED: 'Обов\'язкове поле "Прізвище"',
		INVALID_EMAIL: 'Недійсна електронна адреса',
		INVALID_PHONE: 'Недійсний телефон',
		INVALID_YEAR: 'Недійсна вікова група',
		INVALID_DIFFICULTY: 'Неправильна складність',
		INVALID_COUNTRY: 'Недійсний округ',
		NOT_AUTHENTICATED: 'Не автентифіковано',
		NOT_ADMIN: 'Ви не адміністратор',
		NOT_FOUND: 'Не знайдено',
		SHORT_PHONE: 'Рядок, поданий занадто короткий, щоб бути номером телефону',
		LONG_PHONE: 'Рядок, який надається, задовгий, щоб бути номером телефону',
		DAILY_TESTS_LIMIT: 'На сьогодні твої тренування закінчились ти можеш пройти тренування завтра',
		TEMPLATE_NOT_FOUND: 'Шаблон не знайдений',
	},
}
