import landing from './landing'
import forms from './forms'
import modal from './modal'
import admin from './admin/index'
import notification from './notification'
import olympiad from './olympiad/index'
import date from './date.js'

export default {
	landing,
	forms,
	modal,
	date,
	...olympiad,
	...admin,
	notification,
	fromNumber: '-ից',
	loading: 'Բեռնվում է ...',
	show: 'Ցուցադրում',
	category: 'Արդյունքները ըստ անվանակարգերի',
	top: 'Թոփ 10 անվանակարգում',
	saveResults: 'Ներբեռնել արդյունքները',
	results: 'Արդյունքներ',
	checkoutResult: `Ծանոթանալ օլիմպիադայի <strong>արդյունքներին</strong>`,
	templates: 'Աշխատանքի ձևանմուշներ (ըստ տարիքային խմբի և դժվարության մակարդակի)',
	mail: 'Էլ. հասցե',
	password: 'Գաղտնաբառ',
	search: 'Որոնում',
	logIn: 'Մուտք',
	lng: {
		ru: 'ռուսերեն',
		uk: 'ուկրաիներեն',
		pl: 'լեհերեն',
		en: 'անգլերեն',
		ro: 'Ռումիներեն',
		az: 'ադրբեջանական',
		hy: 'հայերեն',


	},
	currency: {
		full: {
			PLN: 'Zloty',
			USD: 'դոլար',
			UAH: 'UAH',
			RUB: 'Ռուբլի',
			RON: 'Լեյ',
			AZN: 'Մանաթ',
			AMD: 'Դրամ',


		},
		short: {
			PLN: 'Zlt',
			USD: 'դոլար',
			UAH: 'UAH',
			RUB: 'Ռուբ',
			RON: 'Լեյ',
			AZN: 'Մանաթ',
			AMD: 'Դրամ',

		},
	},
	demoLesson: 'Դեմո դաս',
	registration: 'Գրանցում',
	startOlympic: 'Սկսել օլիմպիադան',
	franchise: 'Ֆրանշիզա',
	personalArea: 'Անձնական էջ',
	menu: 'Ցանկ',
	backDown: 'Վերադարձիր',
	giveAgree: 'Ես տալիս եմ իմ համաձայնությունը',
	goHome: 'Տուն',
	reload: 'Թարմացնել',
	delete: 'Նջել',
	save: 'Պահել',
	errors: {
		status: {
			404: `<p> Էջը չի գտնվել </p> <p> Սկսել տնից: </p>`,
			500: `<p> Սխալ առաջացավ: </p> <p> Թարմացրեք էջը: </p>`,
			503: `<p> Սերվերի սխալ է տեղի ունեցել: </p> <p> Թարմացրեք էջը: </p>`,
		},
	},
	condition: {
		fondy: 'Fondy պայմանագիր',
		title: 'Վճարելուց առաջ կարդացեք <strong> Իրադարձության պայմանները </strong>',
		description: 'Նմանապես, անընդհատ քանակական աճը և մեր գործունեության շրջանակը մեծապես որոշում են առաջընթաց զարգացման ուղղությունների ստեղծումը: Չպետք է մոռանալ, սակայն, որ դիրքորոշման ձևավորման ամենօրյա աշխատանքի սկիզբը մեծապես որոշում է զարգացման ձևերի ստեղծումը: Կառուցվածքի ամրապնդման և զարգացման բազմազան և հարուստ փորձը մեզանից պահանջում է վերլուծել ֆինանսական և վարչական նշանակալի պայմանները: Նմանապես, դիրքորոշման ձևավորման ամենօրյա աշխատանքի սկիզբը պահանջում է զարգացման հետագա ուղղությունների սահմանում և հստակեցում: Բազմազան և հարուստ փորձը, մեր գործունեության անընդհատ տեղեկատվական և քարոզչական աջակցությունը թույլ են տալիս իրականացնել կարևոր խնդիրներ նոր առաջարկների մշակման համար: Ամենօրյա պրակտիկան ցույց է տալիս, որ մշտական ​​քանակական աճը և մեր գործունեության շրջանակը կարևոր դեր են խաղում անձնակազմի վերապատրաստման համակարգի ձևավորման գործում, բավարարում են հրատապ կարիքները: <br/> '
			+ '<br/>' +
			'«Կառուցվածքի ամրապնդման և զարգացման բազմազան և հարուստ փորձը պահանջում է զարգացման հետագա ուղղությունների սահմանում և հստակեցում: Պլանային թիրախների իրականացման բազմազան և հարուստ փորձը նպաստում է համապատասխան ակտիվացման պայմանների նախապատրաստմանը և իրականացմանը: Մյուս կողմից, կազմակերպության գոյություն ունեցող կառուցվածքը պահանջում է զարգացման մոդելի սահմանում և կատարելագործում:' + '<br/>' +
			'- Ընկերնե՛ր: Կազմակերպության առկա կառուցվածքը նպաստում է զարգացման հետագա ուղղությունների նախապատրաստմանը և իրականացմանը: Կազմակերպության խնդիրը, հատկապես կառույցի ամրապնդումն ու զարգացումը, կարևոր դեր են խաղում զգալի ֆինանսական և վարչական պայմանների ձևավորման գործում: Ընկերնե՛ր: Գործունեության տարբեր ձևերի հետագա զարգացումը հնարավորություն է տալիս գնահատել մասնակցային համակարգերի կարևորությունը: ' + '</br>' +
			'<br/>' +
			'«Կառուցվածքի ամրապնդման և զարգացման բազմազան և հարուստ փորձը մեզ թույլ է տալիս կատարել կարևոր խնդիրներ զարգացման հետագա ուղղությունների զարգացման համար: Նմանապես, գործունեության տարբեր ձևերի հետագա զարգացումը մեծապես որոշում է զանգվածային մասնակցության համակարգերի ստեղծումը: ' + '<br/>' +
			'<br/>' +
			'«Կազմակերպության խնդիրը, մասնավորապես մեր գործունեության անընդհատ տեղեկատվական և քարոզչական աջակցությունը, մեզ թույլ է տալիս իրականացնել կարևոր առաջադրանքներ` առաջադեմ զարգացման ուղղություններ մշակելու համար: Բազմազան և հարուստ փորձ `կազմակերպության գոյություն ունեցող կառուցվածքը կարևոր դեր ունի զարգացման հետագա ուղղությունների ձևավորման գործում:' + '<br/>' +
			'<br/>' +
			'- Ընկերնե՛ր: գործունեության տարբեր ձևերի հետագա զարգացումը պահանջում է առաջադեմ զարգացման ուղղությունների սահմանում և հստակեցում: Նմանապես, գործունեության տարբեր ձևերի հետագա զարգացումը պահանջում է զարգացման մոդելի սահմանում և կատարելագործում: Կազմակերպության խնդիրը, հատկապես ուժեղացումը: <br/>',
	},
}
