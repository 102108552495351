import i18n from 'i18next'
import { LANGUAGES } from '../react_constants'
import { client } from '../api/apollo'
import { gql } from '@apollo/client'

export function storage(key: string, value?: any): string | null | undefined {
  if (!value) {
    return localStorage.getItem(key)
  } else if (value && key) {
    localStorage.setItem(key, value)
  } else return;
}

export const getDefaultLanguage = (toCapitalize: boolean = false) => {
  let savedLng = storage('lng')
  const browserLng = (navigator?.language).slice(0, 2)
  if (savedLng && !LANGUAGES.includes(savedLng)) {
    storage('lng', 'en')
    savedLng = 'en'
  }

  const defaultLanguage = savedLng || (LANGUAGES.includes(browserLng) ? browserLng : 'en')

  return toCapitalize ? `${defaultLanguage[0].toUpperCase()}${defaultLanguage[1]}` : defaultLanguage
}

export const getLanguage = () => {
  return i18n.language || getDefaultLanguage();
};
