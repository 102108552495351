export default {
  title: "Олимпиада 2021",
  subTitle: 'по ментальной </br> арифметике',
  info: 'Олимпиада пройдет:\n',
  info2: 'Вы можете выполнять задания\n' +
    'в любое удобное время',
  moreAboutOlympiad: "Подробнее об олимпиаде",
  testingOlympiad: "Протестировать олимпиаду",
  resultOlympiad: "Результаты олимпиады",
  olympiadNotStarted: 'Олимпиада еще не началась!',
  waitFinishedOlympiad: 'Результаты будут доступны после окончания Олимпиады.'
}
