export default {
	task: 'Sarcini',
	col: 'Coloane',
	forsage: 'Dictare matematică (Rapid și Furios)',
	trans: 'Traduceri',

	timeIsOver: 'Timpul s-a terminat',
	uploadForDetail: 'Descărcați fișierul pentru "mai multe detalii despre Olimpiadă"',
	pasteFile: 'Lipiți fișierul',
	openResult: 'Rezultate deschise',
	countParticipant: 'Membrii dintr-o categorie',
	place: 'Loc',
	fullName: 'Nume, Prenume',
	club: 'Club MA',
	listIsEmpty: 'Lista este goală',
	notIndicated: 'Nu este indicat',
	yearNotIndicated: 'Greşeală. Lista grupurilor de vârstă este goală',
	difficultyNotIndicated: 'Greşeală. Lista de dificultăți este goală',

	currencyText: 'Monedă',
	sidebar: {
		general: 'Acasă',
		constructor: 'Constructor',
		analytic: 'Analitic',
		setting: 'Personalizare',
	},
	template: {
		addTemplate: 'Adăugați șablon',
		fillBlocks: 'Completați toate câmpurile blocului',
		fillRounds: 'Completați toate câmpurile rundei',
	},
	olympic: {
		finishIn: 'Olimpiada se va desfășura în perioada',
		newTemplate: 'Noul șablon de olimpiadă',
		editTemplate: 'Schimbarea șablonului Olimpiada',
		tab: {
			CURRENT: 'Actual',
			FUTURE: 'Planificat',
			PASSED: 'Trecut',
			NEW: 'Noua olimpiadă',
		},
		theDateOfThe: 'Data',
		name: 'Nume',
		participate: 'A participat',
		paid: 'Plătit',
		tested: 'Testat',
		show: 'Arată',
		newOlympic: 'Noi Olimpice',
		editOlympic: 'Edit Olympic',
		theTimeOfThe: 'Timpul petrecut',
		price: 'Preț',
		add: 'Adaugă la',
		templateOfTasks: 'Șablon de activitate',
		cancelOlympic: 'Anulați șablonul',
		groupAge: 'Grupe de vârstă',
		levelDifficultySimple: 'Nivele de dificultate',
		levelDifficulty: 'Nivele de dificultate (pentru înregistrare)',

		tasks: {
			column: 'Misiunea „COLUMNE”',
			furious: 'Căutare rapidă și furioasă',
		},
		round: 'Rundă',
		level: 'Nivel',
		columns: 'Coloane',
		number: 'Număr',
		difficulty: 'Complexitate',
		timeRound: 'Timp rotund (sec)',
		block: 'Bloc',
		numberOfExample: 'Număr de exemple',
		speed: 'Viteza',
		addBlock: 'Adăugați bloc',
		addRound: 'Adăugați round',
		saveTemplate: 'Salvați șablonul',
		saveOlympiad: 'Salvați olimpiada',
		cancelOlympiad: 'Anulați Jocurile Olimpice',
		setting: {
			frontPage: 'Prima pagina',
			titleBold: 'Titlu (aldin)',
			titleRegular: 'Titlu (regulat)',
			descriptionText: 'Text de descriere',
			timeRegion: 'Regiunea timpului',
			agreement: 'Acord de participare',
			testingOlympiad: 'Olimpiada de testare',
			numberOfTests: 'Numărul de teste',
			gallery: 'Galerie foto pe pagina principală (1-3 fișiere)',
			cancel: 'Anulare',
			passwordSetting: 'Setări parolă',
			oldPassword: 'Parola veche',
			newPassword: 'Parolă Nouă',

		},

	},
	allFieldsAreRequired: 'toate campurile sunt necesare',
	agree: {
		isDelete: 'Sigur doriți să ștergeți această olimpiadă?',
	},
}
