import React, { memo } from 'react'
import s from './index.module.scss'
import classNamesBinds from 'classnames/bind'
import Button from 'components/Button'
import { CloseIcon } from '../../assets/icons'
import { availableNewVersion } from '../../api/apollo/state'

const cx = classNamesBinds.bind(s)
const NewVersionModal = () => {
	const reload = () => {
		availableNewVersion(false)
		window.location.reload()
	}
	return <div className={cx('Overlay')} onClick={reload}>
		<div className={cx('popup', 'Popup')}>
			<span className={'popup__close'} onClick={reload}><CloseIcon fill={'var(--blue)'} /></span>
			<div className={cx('popup__icon')}>
				<svg width='80' height='74' viewBox='0 0 80 74' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M70 0.333252H10C4.48667 0.333252 0 4.81992 0 10.3333V63.6666C0 69.1799 4.48667 73.6666 10 73.6666H70C75.5133 73.6666 80 69.1799 80 63.6666V10.3333C80 4.81992 75.5133 0.333252 70 0.333252Z'
						fill='#D8658A' />
					<path
						d='M70.0003 67H10.0003C8.16366 67 6.66699 65.5067 6.66699 63.6667V17H73.3337V63.6667C73.3337 65.5067 71.837 67 70.0003 67Z'
						fill='white' />
					<path
						d='M24.7637 40.3334C24.3237 40.3334 23.8737 40.2434 23.4437 40.0601C21.7537 39.3301 20.977 37.3667 21.707 35.6767C24.8603 28.3834 32.0403 23.6667 40.0003 23.6667C47.917 23.6667 55.1203 28.3701 58.347 35.6501C59.0937 37.3334 52.997 40.0334 52.2537 38.3534C50.0903 33.4801 45.2837 30.3334 40.0003 30.3334C34.7037 30.3334 29.9237 33.4701 27.827 38.3234C27.2837 39.5801 26.0537 40.3334 24.7637 40.3334Z'
						fill='#4A4DA4' />
					<path
						d='M40.0003 63.6666C32.037 63.6666 24.8337 58.9566 21.647 51.6699C20.907 49.9833 27.017 47.3133 27.7537 48.9966C29.8803 53.8599 34.687 56.9999 40.0003 56.9999C45.2836 56.9999 50.0903 53.8533 52.2536 48.9833C53.0003 47.3066 54.967 46.5399 56.6503 47.2866C58.3336 48.0333 59.0903 49.9999 58.347 51.6833C55.1203 58.9633 47.917 63.6666 40.0003 63.6666Z'
						fill='#4A4DA4' />
					<path
						d='M56.6663 40.3334H48.333C46.9863 40.3334 45.7663 39.5201 45.253 38.2767C44.7363 37.0301 45.023 35.5967 45.9763 34.6434L54.3097 26.3101C55.263 25.3534 56.6963 25.0701 57.943 25.5867C59.1897 26.1034 59.9997 27.3201 59.9997 28.6667V37.0001C59.9997 38.8401 58.5097 40.3334 56.6663 40.3334Z'
						fill='#4A4DA4' />
					<path
						d='M23.3333 62C22.9033 62 22.47 61.92 22.0567 61.7467C20.81 61.2333 20 60.0133 20 58.6667V50.3333C20 48.49 21.49 47 23.3333 47H31.6667C33.0133 47 34.2333 47.81 34.7467 49.0567C35.2633 50.3033 34.9767 51.7367 34.0233 52.69L25.69 61.0233C25.0533 61.66 24.2 62 23.3333 62Z'
						fill='#4A4DA4' />
					<path
						d='M40 0.333252H10C4.48667 0.333252 0 4.81992 0 10.3333V63.6666C0 69.1799 4.48667 73.6666 10 73.6666H40V66.9999H10C8.16333 66.9999 6.66667 65.5066 6.66667 63.6666V16.9999H40V0.333252Z'
						fill='#E9407A' />
					<path
						d='M40.0003 17H6.66699V63.6667C6.66699 65.5067 8.16366 67 10.0003 67H40.0003V63.6667C35.3403 63.6667 30.9437 62.0533 27.457 59.2567L25.6903 61.0233C25.0537 61.66 24.2003 62 23.3337 62C22.9037 62 22.4703 61.92 22.057 61.7467C20.8103 61.2333 20.0003 60.0133 20.0003 58.6667V50.3333C20.0003 48.49 21.4903 47 23.3337 47H31.667C33.0137 47 34.2337 47.81 34.747 49.0567C35.2637 50.3033 34.977 51.7367 34.0237 52.69L32.2137 54.5C34.437 56.0933 37.147 57 40.0003 57V30.3333C34.7037 30.3333 29.9237 33.47 27.827 38.3233C27.2837 39.58 26.0537 40.3333 24.7637 40.3333C24.3237 40.3333 23.8737 40.2433 23.4437 40.06C21.7537 39.33 20.977 37.3667 21.707 35.6767C24.8603 28.3833 32.0403 23.6667 40.0003 23.6667V17Z'
						fill='#DEDEDE' />
					<path
						d='M39.9998 23.6667C32.0398 23.6667 24.8598 28.3834 21.7065 35.6767C20.9765 37.3667 21.7565 39.3301 23.4432 40.0601C23.8732 40.2467 24.3232 40.3334 24.7632 40.3334C26.0565 40.3334 27.2832 39.5801 27.8265 38.3201C29.9232 33.4701 34.7032 30.3334 39.9998 30.3334V23.6667Z'
						fill='#6A5EA4' />
					<path
						d='M32.2137 54.5L27.457 59.2567C30.9437 62.0533 35.3404 63.6667 40.0004 63.6667V57C37.147 57 34.437 56.0933 32.2137 54.5Z'
						fill='#6A5EA4' />
					<path
						d='M31.6667 47H23.3333C21.49 47 20 48.49 20 50.3333V58.6667C20 60.0133 20.81 61.2333 22.0567 61.7467C22.47 61.92 22.9033 62 23.3333 62C24.2 62 25.0533 61.66 25.69 61.0233L27.4567 59.2567L32.2133 54.5L34.0233 52.69C34.9767 51.7367 35.2633 50.3033 34.7467 49.0567C34.2333 47.81 33.0133 47 31.6667 47Z'
						fill='#6A5EA4' />
				</svg>

			</div>
			<p className={cx('popup__text')}>
				Вы используете устаревшую версию сайта.
			</p>
			<Button label={'Обновить страницу'} color={'white'} onClick={reload} />
		</div>
	</div>
}
export default memo(NewVersionModal)