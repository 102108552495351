import landing from './landing'
import forms from './forms'
import modal from './modal'
import admin from './admin/index'
import notification from './notification'
import olympiad from './olympiad/index'
import date from './date'

export default {
	landing,
	forms,
	modal,
	notification,
	date,
	...admin,
	...olympiad,
	fromNumber: ' din',
	loading: 'Se încarcă...',
	show: 'Arată',
	category: 'Categoria rezultatelor',
	top: 'TOP 10 în categorii',
	saveResults: 'Descarcă rezultatele',
	results: 'Rezultate',
	checkoutResult: `Faceți cunoștință cu <strong> rezultatele </strong> Olimpiadei`,
	templates: 'Șabloane de locuri de muncă (pe grupe de vârstă și nivel de dificultate)',
	mail: 'Email',
	password: 'Parola',
	search: 'Căutare',
	logIn: 'A intra',
	lng: {
		ru: 'Rusă',
		uk: 'Ucrainean',
		pl: 'Polonez',
		en: 'Engleză',
		ro: 'Română',
		az: 'Azeră',
		hy: 'Armean',
	},
	currency: {
		full: {
			PLN: 'Zloty',
			USD: 'Dolari',
			UAH: 'UAH',
			RUB: 'Ruble',
			RON: 'Lei',
			AZN: 'AZN',
			AMD: 'Dram',

		},
		short: {
			PLN: 'Zl',
			USD: 'USD',
			UAH: 'UAH',
			RUB: 'RUB',
			RON: 'RON',
			AZN: 'AZN',
			AMD: 'Dram',

		},
	},
	demoLesson: 'Lecție demonstrativă',
	registration: 'Înregistrare',
	startOlympic: 'Start Olimpiadă',
	franchise: 'Franciză',
	personalArea: 'Zona personală',
	menu: 'Meniul',
	backDown: 'Întoarce-te',
	giveAgree: 'Sunt de acord',
	goHome: 'Pe cap',
	reload: 'Onoviti',
	delete: 'Șterge',
	save: 'Salva',
	errors: {
		status: {
			404: `<p>Pagina nu este cunoscută.</p> <p>Aflați din partea capului.</p>`,
			500: `<p>A devenit un mormânt.</p> <p>Actualizați pagina.</p>`,
			503: `<p>Serverul a devenit un mormânt.</p> <p>Actualizați pagina.</p>`,
		},
	},
	condition: {
		fondy: 'Fondy contracta',
		title: 'Vă rugăm să citiți <strong> Termenii evenimentului </strong> înainte de a plăti',
		description: 'La fel, creșterea cantitativă constantă și sfera activității noastre determină în mare măsură crearea direcțiilor pentru dezvoltarea progresivă. Cu toate acestea, nu ar trebui să uităm că începutul muncii zilnice pentru a forma o poziție determină în mare măsură crearea de forme de dezvoltare. Experiență diversă și bogată care întărește și dezvoltă structura ne solicită să analizăm condițiile financiare și administrative semnificative. La fel, începutul muncii de zi cu zi privind formarea unei poziții necesită identificarea și clarificarea unor direcții suplimentare de dezvoltare. Experiența diversă și bogată, informarea constantă și sprijinul propagandistic al activităților noastre ne permite să îndeplinim sarcini importante pentru a dezvolta noi propuneri. că creșterea cantitativă constantă și sfera activității noastre joacă un rol important în formarea sistemului de instruire a personalului, răspunde nevoilor urgente. <br/> ' +
			'<br/>' +
			'Experiența diversă și bogată, consolidarea și dezvoltarea structurii necesită definirea și clarificarea unor direcții suplimentare de dezvoltare. Experiența diversă și bogată în implementarea obiectivelor planificate contribuie la pregătirea și implementarea condițiilor adecvate pentru activare. Pe de altă parte, structura existentă a organizației necesită definirea și rafinarea modelului de dezvoltare. <br/>' +
			'<br/>' +
			'Tovarăși, structura existentă a organizației contribuie la pregătirea și implementarea unor direcții suplimentare de dezvoltare. Sarcina organizației, în special consolidarea și dezvoltarea structurii, joacă un rol important în formarea unor condiții financiare și administrative semnificative. Tovarăși, dezvoltarea în continuare a diferitelor forme de activitate ne permite să evaluăm importanța sistemelor de participare în masă. <br/> ' +
			'<br/>' +
			'Experiența diversă și bogată în consolidarea și dezvoltarea structurii ne permite să îndeplinim sarcini importante pentru dezvoltarea unor direcții de dezvoltare ulterioare. De asemenea, dezvoltarea în continuare a diferitelor forme de activitate determină în mare măsură crearea sistemelor de participare în masă. <br/>' +
			'<br/>' +
			'Sarcina organizației, în special informația constantă și sprijinul propagandistic al activităților noastre, ne permite să îndeplinim sarcini importante pentru a dezvolta direcții de dezvoltare progresivă. Experiență diversă și bogată, structura existentă a organizației joacă un rol important în conturarea direcțiilor de dezvoltare. <br/>' +
			'<br/>' +
			'Tovarăși, dezvoltarea în continuare a diferitelor forme de activitate necesită definirea și clarificarea direcțiilor de dezvoltare progresivă. La fel, dezvoltarea în continuare a diferitelor forme de activitate necesită definirea și rafinarea modelului de dezvoltare. Sarcina organizației, în special consolidarea',
	},


}
