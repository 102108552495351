export default {
	task: 'Завдання',
	col: 'Стовпці',
	forsage: 'Математичний диктант (Форсаж)',
	trans: 'Преклади',

	timeIsOver: 'Час минув',
	uploadForDetail: 'Завантажити файл для "детальніше про олімпіаду"',
	pasteFile: 'Вставте файл',
	openResult: 'Відкрити результати',
	countParticipant: 'Учасників в категорії',
	place: 'Місце',
	fullName: 'Прізвище, Ім\'я',
	club: 'Клуб МА',
	listIsEmpty: 'Список порожній',
	notIndicated: 'Не вказано',
	yearNotIndicated: 'Помилка. Список "Вікові групи" порожній',
	difficultyNotIndicated: 'Помилка. Список "Складність" порожній',
	currencyText: 'Валюта',
	sidebar: {
		general: 'Головна',
		constructor: 'Конструктор',
		analytic: 'Аналітика',
		setting: 'Налаштування',
	},
	template: {
		addTemplate: 'Додати шаблон',
		fillBlocks: 'Заповніть всі поля блоку',
		fillRounds: 'Заповніть всі поля раунду',
	},
	olympic: {
		finishIn: 'Олімпіада закінчується через',

		newTemplate: 'Новий шаблон Олімпіади',
		editTemplate: 'Зміна шаблону Олімпіади',
		tab: {
			CURRENT: 'Поточна',
			FUTURE: 'Заплановані',
			PASSED: 'Минулі',
			NEW: 'Нова олімпіада',
		},
		theDateOfThe: 'Дата проведення',
		name: 'Назва',
		participate: 'Брало участь',
		paid: 'Оплатили',
		tested: 'Тестували',
		show: 'Показати по',
		newOlympic: 'Нова Олімпіада',
		editOlympic: 'Змінити Олімпіаду',

		theTimeOfThe: 'Час проведення',
		price: 'Ціна',
		add: 'Додати',
		templateOfTasks: 'Шаблон завдань',
		cancelOlympic: 'Скасувати шаблон',
		groupAge: 'Вікові групи',
		levelDifficultySimple: 'Рівні складності',
		levelDifficulty: 'Рівні складності (для реєстрації)',

		tasks: {
			column: 'Завдання "Стовпці"',
			furious: 'Завдання "ФОРСАЖ"',
		},
		round: 'Раунд',
		level: 'Рівень',
		columns: 'Стовпців',
		number: 'Число',
		difficulty: 'Складність',
		timeRound: 'Час раунду (сек)',
		block: 'Блок',
		numberOfExample: 'К-ть прикладів',
		numberOfActions: 'К-ть дій',
		speed: 'Швидкість',
		addBlock: 'Додати блок',
		addRound: 'Додати раунд',
		saveTemplate: 'Зберегти шаблон',
		saveOlympiad: 'Зберегти Олімпіаду',
		cancelOlympiad: 'Відмінити Олімпіаду',
		setting: {
			frontPage: 'Титульна сторінка',
			titleBold: 'Заголовок (жирний)',
			titleRegular: 'Заголовок (звичайний)',
			descriptionText: 'Текст опису',
			timeRegion: 'Регіон відліку часу',
			agreement: 'Договір про участь',
			testingOlympiad: 'Встановити поточну олімпіаду',
			numberOfTests: 'Кількість тестувань',
			gallery: 'Фотогалерея на головній (1-3 файлу)',
			cancel: 'Скасувати',
			passwordSetting: 'Налаштування пароля',
			oldPassword: 'Старий пароль',
			newPassword: 'Новий пароль',

		},

	},
	allFieldsAreRequired: 'всі поля обов\'язкові до заповнення',
	agree: {
		isDelete: 'Ви дійсно хочете видалити цю Олімпіаду?',
	},
}
