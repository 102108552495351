export default {
  title: "Olympics 2021",
  subTitle: 'by mental </br> arithmetic',
  info: 'Olympiad will be opened from:\n',
  info2: 'You can complete the task \nat your convenience',
  moreAboutOlympiad: "More about the Olympiad",
  testingOlympiad: "Test the Olympiad",
  resultOlympiad: "Olympiad results",
  olympiadNotStarted: 'The Olympiad haven\'t started yet!',
  waitFinishedOlympiad: 'The results will be available after the end of the Olympiad.'
}
