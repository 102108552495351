export default {
	exit: 'Wychodzić',

	warning: `<div> Uwaga! </br> Jest tylko <strong> jedna próba </strong> wprowadzenia </br> <strong> kodu rejestracyjnego </strong> w następnym kroku </div>`,
	inputCode: 'Wpisz kod rejestracyjny',
	startLater: 'Rozpocznij później',
	olympiadIndigo: 'Olimpiada online INDIGO',
	olympiadIndigoTest: 'Testowanie Olimpiady INDIGO',
	welcome: 'Witamy na Igrzyskach Olimpijskich!',
	access: `Aby uzyskać dostęp do zadań, podaj <br/><strong>adres e-mail</strong> oraz swój <strong>unikalny kod</strong>`,
	accessTest: `Musisz podać adres <strong>e-mail</strong>, aby uzyskać dostęp do zadań`,
	uniqueCode: 'Unikalny kod',
	example: 'Na przykład',
	auth: 'Zaloguj się',
	authTest: 'Autoryzuj',
	readyDescription: `Jesteś gotowy?</br> <strong> Życzymy powodzenia! </strong>`,
	goToExecution: 'Przejdź do zadania',
	ready: 'Przygotuj się',
	nextStage: 'Następny etap',
	finishStage: 'Następny',
	finishAtALl: 'Zakończ',

	start: 'Start',
	startTask: 'Rozpocznij zadanie',
	answerAllExample: 'Odpowiedź',
	inputAnswerInCell: `Podaj odpowiedź w pustym polu. <br/> Jeśli nie jesteś pewny wyniku, wprowadź dowolną liczbę.`,
	youDidIt: 'Gratulacje, przeszedłeś olimpiadę!',
	youDidItTest: 'Brawo, poprawnie wykonałeś zadanie testowe!',
	yourRecord: 'Twój wynik:',
	points: 'pkt',
	pointsTest: 'punktów',
	downloadResult: 'Pobierz wynik',
	endParticipation: 'Zakończ',
	congratulation: `Gratulacje, <strong>przeszedłeś</strong> olimpiadę! <br/>Dyplom uczestnika zostanie przesłany <strong>e-mailem</strong>. <br/>Poczekaj, aż konkurs się zakończy. <br/> Chcemy wręczyć Twoją nagrodę!`,
	payment: `Gratulacje, płatność zakończyła się sukcesem! <br/> Sprawdź<strong> e-mail </strong> </br>(jeśli e-mail nie dotarł, <strong>sprawdź folder spamu lub promocje</strong>) <br/>  i poczekaj na rozpoczęcie  <strong> Olimpiady. </strong>`,

	email: 'email',
	attempt: 'Próba',
	takeParticipate: `Weź udział w <strong> Igrzyskach Olimpijskich </strong> i sprawdź swoje umiejętności!`,
	tryAgain: 'Spróbuj ponownie',
	leaveTesting: 'Zakończ test',
	lookItUpResultPrev: `Zapoznaj się z <strong>wynikami</strong> igrzysk olimpijskich`,
	showResult: 'Zobacz wyniki',
	ONE: 'Jedności',
	TEN: 'Dziesiątki',
	HUNDRED: 'Setki',
	THOUSAND: 'Czterocyfrowy',
	TEN_THOUSANDS: 'Pięć cyfr',
	actions: 'działań',
	action: 'działanie',
	speed: 'prędkość',
	sec: 'sek',
	mental: '“Arytmetyka mentalna”',
	prev: 'Wrócić do',
	next: 'Następujący',
	completedStage: 'Ukończ etap'

}
