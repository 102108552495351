export default {

	exit: 'Çölə çıxmaq',
	warning: `<div>Diqqətli olun! </br> Növbəti mərhələdə qeydiyyat <strong> kodunu </strong> </br> daxil etmək üçün yalnız <strong> bir cəhdiniz var </div>`,
	inputCode: 'Qeydiyyat kodunu daxil etmək',
	startLater: 'Daha sonra başla',
	olympiadIndigo: 'INDIGO Onlayn Olimpiadası',
	olympiadIndigoTest: 'INDIGO Olimpiadasını sınağdan keçirtmək',
	welcome: 'Olimpiadaya xoş gəlmisiniz!',
	access: `Tapşırığa başlamaq üçün <br/><strong>e-mail</strong> və <strong>unikal kod</strong> tələb olunur`,
	accessTest: `Tapşırıqlara keçmək üçün <strong>e-mail</strong> ünvan göstərilməlidir`,
	uniqueCode: 'Unikal kod',
	example: 'Məsələn',
	auth: 'Giriş',
	authTest: 'Daxil ol',
	readyDescription: `Sən hazırsan? </br> <strong> Sənə uğurlar arzulayırıq! </strong>`,
	goToExecution: 'Həll etməyə başla',
	ready: 'Hazır olun',
	nextStage: 'Növbəti mərhələ',
	finishStage: 'Növbəti',
	finishAtALl: 'Bitirmək',

	start: 'Start',
	startTask: 'Tapşırığı başla',
	answerAllExample: 'Cavab',
	inputAnswerInCell: `Cavabı xanaya daxil edin. <br/> Hesablamaq üçün vaxtınız çatmadısa, istənilən cavabı daxil edin.`,
	youDidIt: 'Təbrik edirik, Olimpiadanı keçdin!',
	youDidItTest: 'Əla, test tapşırığından keçdin!',
	yourRecord: 'Sizin nəticə:',
	points: 'bal',
	pointsTest: 'bal',
	downloadResult: 'Nəticəni yükləyin',
	endParticipation: 'İştirakı tamamlamaq',
	congratulation: `Təbrik edirik, <strong>Olimpiadanı</strong> keçdin! <br/> Sənin İştirakçı diplomun <strong>elektron</strong> poçtundadır. <br/>Yarışın bitməsini gözlə. <br/>Mükafat yeri tutmağını arzu edirik!`,
	payment: `Təbrik edirik,ödəməniz uğurla həyata keçirildi!
	 </br> <strong>E-mail ünvanınızı </strong> yoxlayın</br>  (əgər məktub gəlməyibsə,<strong> Spam və ya Promoaksiyalar</strong> qovluqlarını yoxlayın)  </br> və <strong>Olimpiadanın</strong> başlanmasını gözləyin</strong>`,
	email: 'e-mail',
	attempt: 'cəhd',
	takeParticipate: `<strong>Olimpiadada</strong> iştirak edin və öz bacarıqlarınızı başqaları ilə yarışmada sınayın!

`,
	tryAgain: 'Yenidən cəhd etmək',
	leaveTesting: 'Sınağı bitirmək',
	lookItUpResultPrev: `Olimpiadanın <strong>nəticələri</strong> ilə tanış olun`,
	showResult: 'Nəticələrə baxın',
	ONE: 'Birrəqəmli',
	TEN: 'İkirəqəmli',
	HUNDRED: 'Üçrəqəmli',
	THOUSAND: 'Dörd rəqəmli',
	TEN_THOUSANDS: 'Beş rəqəmli',
	actions: 'hərəkət',
	action: 'hərəkət',
	speed: 'sürət',
	sec: 'saniyə',
	mental: '“Mental Arifmetika”',

	prev: 'Geriyə',
	next: 'İzləyirəm',
	completedStage: 'Tam mərhələ'

}
