import React, { memo } from 'react'
import classNames from 'classnames/bind'
import s from './index.module.scss'
import { Icons } from 'components/ErrorPage'
import './index.scss'

const cx = classNames.bind(s)

const Preloader = () => (
	<div className={cx('PreloaderPageWrap')}>
		<svg
			className="loader"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 64 64"
			width="512"
			height="512"
		>
			<g id="flat">
				<g className="border">
					<rect rx="3" x="5" y="8" width="54" height="48"></rect>
				</g>
				<g className="bg">
					<rect x="7" y="10" width="50" height="44"></rect>
				</g>
				<g className="separator">
					<rect x="7" y="27" width="50" height="2"></rect>
				</g>
				<g className="section-separator">
					<rect x="31" y="10" width="2" height="44"></rect>
					<rect x="15" y="10" width="2" height="44"></rect>
					<rect x="46" y="10" width="2" height="44"></rect>
				</g>

				<g className="small-1">
					<rect x="11" y="38" width="10" height="4" rx="2" ry="2"></rect>
					<rect x="11" y="46" width="10" height="4" rx="2" ry="2"></rect>
					<rect x="11" y="42" width="10" height="4" rx="2" ry="2"></rect>
					<rect x="11" y="50" width="10" height="4" rx="2" ry="2"></rect>
				</g>
				<g className="small-2">
					<rect x="27" y="46" width="10" height="4" rx="2" ry="2"></rect>
					<rect x="27" y="42" width="10" height="4" rx="2" ry="2"></rect>
					<rect x="27" y="38" width="10" height="4" rx="2" ry="2"></rect>
					<rect x="27" y="34" width="10" height="4" rx="2" ry="2"></rect>
					<rect x="27" y="50" width="10" height="4" rx="2" ry="2"></rect>
				</g>
				<g className="small-3">
					<rect x="42" y="46" width="10" height="4" rx="2" ry="2"></rect>
					<rect x="42" y="42" width="10" height="4" rx="2" ry="2"></rect>
					<rect x="42" y="50" width="10" height="4" rx="2" ry="2"></rect>
				</g>
				<g className="big-1">
					<rect x="11" y="23" width="10" height="4" rx="2" ry="2"></rect>
				</g>
				<g className="big-2">
					<rect x="27" y="23" width="10" height="4" rx="2" ry="2"></rect>
					<rect x="27" y="19" width="10" height="4" rx="2" ry="2"></rect>
				</g>
				<g className="big-3">
					<rect x="42" y="15" width="10" height="4" rx="2" ry="2"></rect>
					<rect x="42" y="19" width="10" height="4" rx="2" ry="2"></rect>
					<rect x="42" y="23" width="10" height="4" rx="2" ry="2"></rect>
				</g>
			</g></svg>
		<Icons />
	</div>
)

export default memo(Preloader)

