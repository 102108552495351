export default {
	exit: 'Вийти',

	warning: `<div> Будьте уважні! </br> Є тільки <strong> одна спроба </strong> введення </br> реєстраційного <strong> коду </strong> на наступному кроці </div>`,
	inputCode: 'Ввести код реєстрації',
	startLater: 'Почати пізніше',
	olympiadIndigo: 'Онлайн-олімпіада INDIGO',
	olympiadIndigoTest: 'Тестування	Олімпіади INDIGO',
	welcome: 'Ласкаво просимо на Олімпіаду!',
	access: `Для доступу до завдань необхідно вказати <br/> <strong> e-mail </strong> та <strong> унікальний код </strong>`,
	accessTest: `Для доступу до завдань необхідно вказати <strong> електронну пошту </strong>`,
	uniqueCode: 'Унікальний код',
	example: 'Наприклад',
	auth: 'Авторизуватися',
	authTest: 'Авторизуватися',
	readyDescription: `Ти готовий? </br> <strong> Бажаємо успіху! </strong>`,
	goToExecution: 'Перейти до виконання',
	ready: 'Приготуйтесь',
	nextStage: 'Наступний етап',
	finishStage: 'Наступний',
	finishAtALl: 'Завершити',
	start: 'Старт',
	startTask: 'Почати завдання',
	answerAllExample: 'Відповідь',
	inputAnswerInCell: `Введіть відповідь в клітинку. <br/> Якщо не встигли порахувати, введіть будь-яку цифру.`,
	youDidIt: 'Вітаємо, ти пройшов Олімпіаду!',
	yourRecord: 'Твій результат:',
	yourRecordTest: 'Твій результат:',
	youDidItTest: 'Ура, тестове завдання пройдене!',
	points: 'Балів',
	pointsTest: 'Балів',
	downloadResult: 'Завантажити результат',
	endParticipation: 'Завершити участь',
	congratulation: `Вітаємо, ти <strong> пройшов </strong> Олімпіаду! <br/>Твій диплом учасника на <strong>електронній пошті. </strong> <br/>Чекай завершення змагань. <br/> Бажаємо зайняти призове місце!`,
	payment: `Вітаємо, <strong> оплата </strong> пройшла успішно! </br> Перевірте <strong> Електронну пошту </strong> </br> (якщо лист не прийшов, перевірте папку <strong> <br/>Спам або Промоакції </strong> ) </br> та чекайте початку <strong> Олімпіади. </strong>`,

	email: 'email',
	attempt: 'Спроба',
	takeParticipate: `Візьміть участь в <strong> Олімпіаді </strong> і перевірте свої здібності в змаганні з іншими!`,
	tryAgain: 'Спробувати ще',
	leaveTesting: 'Вийти з тестування',
	lookItUpResultPrev: `Ознайомтеся з <strong> результатами </strong> Олімпіади`,
	showResult: 'Подивитися результати',
	ONE: 'Одноцифрові',
	TEN: 'Двоцифрові',
	HUNDRED: 'Трицифрові',
	THOUSAND: 'Чотирицифрові',
	TEN_THOUSANDS: 'П\'ятицифрові',
	actions: 'дій',
	actions24: 'дії',
	action: 'дія',
	speed: 'швидкість',
	sec: 'сек',
	mental: '“Ментальна Арифметика”',

	prev: 'Назад',
	next: 'Наступний',
	completedStage: 'Завершити етап'
}
