import { ApolloClient, createHttpLink, from } from '@apollo/client'
import { URI as uri } from '../../react_constants/apollo'
import { cache } from './cache'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { networkError as ne } from './state'


const httpLink = createHttpLink({ uri, headers: {
		// 'client-version': '1.0.0'
	}})
const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem('token')
	ne && ne(null)
	return {
		headers: {
			...headers,
			authorization: token ? `JWT ${token}` : '',
		},
	}
})
const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(({ message, locations, path }) =>
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
			),
		)
	if (networkError) {
		ne(networkError)
		console.log(`[Network error]: ${networkError}`)
	}
})
export const client = new ApolloClient({

	link: from([errorLink, authLink, httpLink]),
	cache,
	connectToDevTools: true,
})


