export default {
  title: "Olimpiada 2021",
  subTitle: 'przez arytmetykę</br>umysłową',
  info: 'Olimpiada odbędzie się:\n',
  info2: 'Możesz wykonywać zadania w\n dowolnym i dogodnym dla Ciebie momencie',
  moreAboutOlympiad: "Więcej o Olimpiadzie",
  testingOlympiad: "Sprawdź zadania testowe",
  resultOlympiad: "Wyniki olimpiady",
  olympiadNotStarted: 'Olimpiada jeszcze się nie rozpoczęła!',
  waitFinishedOlympiad: 'Wyniki pojawią się po zakończeniu Olimpiady.'
}
