export default {
	'01': {
		'name': 'Yanvar',
		'short': 'Jan',
		'number': 1,
		'days': 31,
	},
	'02': {
		'name': 'Fevral',
		'short': 'Feb',
		'number': 2,
		'days': 28,
	},
	'03': {
		'name': 'Mart',
		'short': 'Mar',
		'number': 3,
		'days': 31,
	},
	'04': {
		'name': 'Aprel',
		'short': 'Apr',
		'number': 4,
		'days': 30,
	},
	'05': {
		'name': 'Bilər',
		'short': 'May',
		'number': 5,
		'days': 31,
	},
	'06': {
		'name': 'İyun',
		'short': 'Jun',
		'number': 6,
		'days': 30,
	},
	'07': {
		'name': 'İyul',
		'short': 'Jul',
		'number': 7,
		'days': 31,
	},
	'08': {
		'name': 'Avqust',
		'short': 'Aug',
		'number': 8,
		'days': 31,
	},
	'09': {
		'name': 'Sentyabr',
		'short': 'Sep',
		'number': 9,
		'days': 30,
	},
	'10': {
		'name': 'Oktyabr',
		'short': 'Oct',
		'number': 10,
		'days': 31,
	},
	'11': {
		'name': 'Noyabr',
		'short': 'Nov',
		'number': 11,
		'days': 30,
	},
	'12': {
		'name': 'Dekabr',
		'short': 'Dec',
		'number': 12,
		'days': 31,
	},
}
