export default {
  title: "Олімпіада 2021",
  subTitle: 'з ментальної </br> арифметики',
  info: 'Олімпіада пройде:\n',
  info2: 'Ви можете виконувати завдання \n' +
    'у зручний час',
  moreAboutOlympiad: "Детальніше про олімпіаду",
  testingOlympiad: "Протестувати олімпіаду",
  resultOlympiad: "Результати олімпіади",
  olympiadNotStarted: 'Олімпіада ще не почалася!',
  waitFinishedOlympiad: 'Результати будуть доступні після закінчення Олімпіади.'
}
