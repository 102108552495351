import React, { memo } from 'react'
import classNames from 'classnames/bind'
import s from './index.module.scss'

const cx = classNames.bind(s)

type CircleLoading = {
	isOverlay?: boolean,
	classNames?: string
}

export const CircleLoading = memo<CircleLoading>(({ isOverlay, classNames }) =>
	<div className={`${cx('Loading', { 'LoadingOverlay': isOverlay })} ${classNames ?? ''}`}>
		<svg version='1.1' viewBox='25 25 50 50'>
			<circle cx='50' cy='50' r='20' fill='none' strokeWidth='5' stroke='var(--blue)' strokeLinecap='round'
							strokeDashoffset='0' strokeDasharray='100, 200'>
				<animateTransform attributeName='transform' attributeType='XML' type='rotate' from='0 50 50' to='360 50 50'
													dur='2.5s' repeatCount='indefinite' />
				<animate attributeName='stroke-dashoffset' values='0;-30;-124' dur='1.25s' repeatCount='indefinite' />
				<animate attributeName='stroke-dasharray' values='0,200;110,200;110,200' dur='1.25s' repeatCount='indefinite' />
			</circle>
		</svg>
	</div>)