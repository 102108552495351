export default {
	task: 'Tasks',
	col: 'Columns',
	forsage: 'Mathematical dictation (Fast and Furious)',
	trans: 'Translations',

	timeIsOver: 'Time is up',
	uploadForDetail: 'Download file for “more details about the Olympiad“',
	pasteFile: 'Paste file',
	openResult: 'Open results',
	countParticipant: 'Members in a Category',
	place: 'Place',
	fullName: 'First and last name,',
	club: 'Club МА',
	currencyText: 'Currency',
	listIsEmpty: 'The list is empty',
	notIndicated: 'Not indicated',
	yearNotIndicated: 'Mistake. Age groups list is empty',
	difficultyNotIndicated: 'Mistake. Difficulty list is empty',
	sidebar: {
		general: 'General',
		constructor: 'Constructor',
		analytic: 'Analytic',
		setting: 'Setting',
	},
	template: {
		addTemplate: 'Add Template',
		fillBlocks: 'Fill in all the fields of the block',
		fillRounds: 'Fill in all fields of the round',
	},
	olympic: {
		finishIn: 'The Olympics ends in',
		newTemplate: 'New Olympiad template',
		editTemplate: 'Changing the Olympiad template',
		tab: {
			CURRENT: 'Current',
			FUTURE: 'Planned',
			PASSED: 'Past',
			NEW: 'New Olympiad',
		},
		theDateOfThe: 'The date of the',
		name: 'Name',
		participate: 'Participated',
		paid: 'Paid',
		tested: 'Tested',
		show: 'Show by',
		newOlympic: 'New Olympics',
		editOlympic: 'Edit Olympic',

		theTimeOfThe: 'Time spending',
		price: 'Price',
		add: 'Add to',
		templateOfTasks: 'Task template',
		cancelOlympic: 'Cancel template',
		groupAge: 'Age groups',
		levelDifficulty: 'Difficulty levels (for registration)',
		levelDifficultySimple: 'Difficulty levels',

		tasks: {
			column: 'Quest "COLUMNS"',
			furious: 'Fast & Furious Quest',
		},
		round: 'Round',
		level: 'Level',
		columns: 'Columns',
		number: 'Number',
		difficulty: 'Complexity',
		timeRound: 'Round time (sec)',
		block: 'Block',
		numberOfExample: 'Number of examples',
		speed: 'Speed',
		addBlock: 'Add block',
		addRound: 'Add round',

		saveTemplate: 'Save Template',
		saveOlympiad: 'Save the Olympiad',
		cancelOlympiad: 'Cancel the Olympiad',
		setting: {
			frontPage: 'Front page',
			titleBold: 'Title (bold)',
			titleRegular: 'Title (regular)',
			descriptionText: 'Description text',
			timeRegion: 'Time region',
			agreement: 'Participation agreement',
			testingOlympiad: 'Testing Olympiad',
			numberOfTests: 'Number of tests',
			gallery: 'Photo gallery on the main page (1-3 files)',
			cancel: 'Cancel',
			passwordSetting: 'Password settings',
			oldPassword: 'Old password',
			newPassword: 'New password',

		},

	},
	allFieldsAreRequired: 'all fields are required',
	agree: {
		isDelete: 'Are you sure you want to delete this Olympiad?',
	},
}
