export default {
	allCountry: 'All countries',
	allCities: 'All cities',
	title: 'Registration',

	label: {
		email: 'Email',
		lastName: 'Last name',
		firstName: 'First name',
		gender: 'Gender',
		country: 'Country',
		city: 'City',
		school: 'School of mental arithmetic',
		phone: 'Contact number',
		level: 'Level of complexity',
		birthday: 'Year of birth',

	},
	gender: {
		boy: 'Boy',
		girl: 'Girl',
		notSay: 'I don\'t want to indicate'

	},
	pay: 'Pay',
	select: {
		selectCountry: 'Select a country',
		selectCity: 'Select city',
		selectYear: 'Select the year of birth',
		selectLevel: 'Select the level of difficulty'
	},
	notify: {
		title: 'Attention!',
		description: 'Specify only real data. It will be published in the results of the Olympiad, and also used to create diploma’s',
	},
	notifyPhone: {
		title: 'Please Note!',
		description: `To ensure convenience and uninterrupted work, the Olympiad is available only on tablets and computers.`,
		hide: 'Go to the game',
	},
	errors: {
		globalError: 'Please complete all fields above!',
		fieldIsRequired: 'Required field',
		numeric: 'The field must contain only numbers',
		minLength: 'The number of characters must be greater than ',
		maxLength: 'The number of characters must be less than',
		not_numeric: 'String cannot contain numbers',
		email: 'Email invalid',
		password: 'The field must contain least one letter and one number',
		notSymbol: 'The field must not contain special characters',

		//Olympiad
		INVALID_CODE: 'Invalid code',
		ALREADY_PARTICIPATED: 'You have already used your code! If you want to go through the Olympiad one more time, you need to register again.',
		TIME_EXPIRED: 'Time expired',
		FIRST_NAME_REQUIRED: 'Required field "Name"',
		LAST_NAME_REQUIRED: 'Required field "Last Name"',
		INVALID_EMAIL: 'Invalid Email',
		INVALID_PHONE: 'Invalid Телефон',
		INVALID_YEAR: 'Invalid age group',
		INVALID_DIFFICULTY: 'Invalid difficulty',
		INVALID_COUNTRY: 'Invalid county',
		NOT_AUTHENTICATED: 'Not authenticated',
		NOT_ADMIN: 'You are not an administrator',
		NOT_FOUND: 'Not found',
		SHORT_PHONE: "The string supplied is too short to be a phone number",
		'invalid phone': 'Invalid phone number',
		LONG_PHONE: 'The string supplied is too long to be a phone number',
		'invalid email': "Invalid Email",
		DAILY_TESTS_LIMIT: 'Your attempts are over for today, you can start training again tomorrow',
		TEMPLATE_NOT_FOUND: 'Template not found'


	},
}
