export default {
  title: "Օլիմպիական խաղեր 2021 թ",
  subTitle: 'ըստ մտավոր  </br>թվաբանության',
  info: 'Օլիմպիադան տեղի կունենա \n',
  info2: 'Առաջադրանքները կարող եք կատարել \nՁեզ հարմար ցանկացած ժամանակ',
  moreAboutOlympiad: "Ավելին օլիմպիադայի մասին",
  testingOlympiad: "Փորձարկել օլիմպիադան",
  resultOlympiad: "Օլիմպիադայի արդյունքներ",
  olympiadNotStarted: 'Օլիմպիադան դեռ չի մեկնարկել:',
  waitFinishedOlympiad: 'Արդյունքները հասանելի կլինեն օլիմպիադայի ավարտից հետո:'
}
