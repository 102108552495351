export default {
	'01': {
		'name': 'January',
		'short': 'Jan',
		'number': 1,
		'days': 31,
	},
	'02': {
		'name': 'February',
		'short': 'Feb',
		'number': 2,
		'days': 28,
	},
	'03': {
		'name': 'March',
		'short': 'Mar',
		'number': 3,
		'days': 31,
	},
	'04': {
		'name': 'April',
		'short': 'Apr',
		'number': 4,
		'days': 30,
	},
	'05': {
		'name': 'May',
		'short': 'May',
		'number': 5,
		'days': 31,
	},
	'06': {
		'name': 'June',
		'short': 'Jun',
		'number': 6,
		'days': 30,
	},
	'07': {
		'name': 'July',
		'short': 'Jul',
		'number': 7,
		'days': 31,
	},
	'08': {
		'name': 'August',
		'short': 'Aug',
		'number': 8,
		'days': 31,
	},
	'09': {
		'name': 'September',
		'short': 'Sep',
		'number': 9,
		'days': 30,
	},
	'10': {
		'name': 'October',
		'short': 'Oct',
		'number': 10,
		'days': 31,
	},
	'11': {
		'name': 'November',
		'short': 'Nov',
		'number': 11,
		'days': 30,
	},
	'12': {
		'name': 'December',
		'short': 'Dec',
		'number': 12,
		'days': 31,
	},
}
