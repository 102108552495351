export default {
  title: "Olimpice 2021",
  subTitle: 'prin aritmetică </br> mentală ',
  info: 'Olimpiada se va desfășura în perioada:',
  info2: 'Puteți face sarcini' +
    'în orice moment convenabil',
  moreAboutOlympiad: "Mai multe despre Olympiadă",
  testingOlympiad: "Testează Olimpiadă",
  resultOlympiad: "Rezultatele Olimpiadei",
  olympiadNotStarted: 'Olimpiada nu a început !',
  waitFinishedOlympiad: 'Rezultatele vor fi disponibile după finisarea olimpiadei.'
}
