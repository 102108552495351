import React from 'react'
import { client } from '../api/apollo'
import App from '../App'
import { ApolloProvider } from '@apollo/client'
import 'assets/styles/main.scss'
import 'assets/locals/i18nextConf'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import ErrorPage from '../components/ErrorPage'

const AppContainer = () => {
	return (
		<Sentry.ErrorBoundary fallback={<ErrorPage status={404} />}>
			<ApolloProvider client={client}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</ApolloProvider>
		</Sentry.ErrorBoundary>
	)
}
export default AppContainer
