export default {
	allCountry: 'Toate tarile',
	allCities: 'Toate orașele',
	title: "Înregistrare",

	label : {
		email: 'Email',
		lastName: 'Numele de familie al copilului',
		firstName: 'Prenumele copilului',
		gender: 'Sex',
		country: 'Țară',
		city: 'Oraș',
		school: 'Școală de aritmetică mentală',
		phone: 'Număr de telefon de contact',
		level: 'Nivelul de complexitate',
		birthday: "Anul nasterii"
	},
	gender: {
		boy: "Băiat",
		girl: "Fată",
		notSay: 'Nu vreau să indic'

	},
	pay: "Plătiți",
	select: {
		selectCountry: "Selectați o țară",
		selectCity: "Selectați orașul",
		selectYear: "Selectați anul nașterii",
		selectLevel: 'Selectați nivelul de dificultate'


	},
	notify: {
		title: "Fii atent!",
		description: "Specificați numai date reale. Ele vor fi publicate în rezultatele olimpiadei, precum și utilizate pentru a crea diplome."
	},
	notifyPhone: {
		title: 'Fiți Atenți !',
		description: `Pentru comoditatea dumneavoastră și participarea neîntreruptă, Olimpiada este disponibilă doar pe tableta și computer.`,
		hide: 'Salt în sus gri',
	},
	errors: {
		globalError: 'Vă rugăm să completați toate câmpurile de mai sus!',
		fieldIsRequired: "Câmp obligatoriu",
		numeric: "Câmpul trebuie să conțină numai numere",
		minLength: "Numărul de caractere trebuie să fie mai mare decât ",
		maxLength: 'Numărul de caractere trebuie să fie mai mic de',
		not_numeric: 'Sirul nu poate conține numere',
		email: 'Email invalid',
		password: 'Câmpul trebuie să conțină cel puțin o literă și un număr',
		notSymbol: 'Câmpul nu trebuie să conțină caractere speciale',


		//Olympiad
		INVALID_CODE: 'Cod nevalid',
		ALREADY_PARTICIPATED: 'V-ați folosit deja codul! Dacă doriți să treceți din Olimpiada, trebuie să vă înregistrați din nou.',
		TIME_EXPIRED: 'Timpul a expirat',
		FIRST_NAME_REQUIRED: 'Necesită câmpul „Nume”',
		LAST_NAME_REQUIRED: 'Câmp obligatoriu "Numele de familie"',
		INVALID_EMAIL: 'E-mail nevalid',
		INVALID_PHONE: 'Telefon nevalid',
		INVALID_YEAR: 'Grupa de vârstă nevalidă',
		INVALID_DIFFICULTY: 'Nivel de dificultate nevalid',
		INVALID_COUNTRY: 'Țară nevalidă',
		NOT_AUTHENTICATED: 'Eroare autorizată',
		NOT_ADMIN: 'Nu sunteți administrator',
		NOT_FOUND: 'Nu a fost gasit',
		SHORT_PHONE: 'Șirul furnizat este prea scurt pentru a fi un număr de telefon',
		'invalid phone': 'Numar de telefon invalid',
		LONG_PHONE: 'Șirul furnizat este prea lung pentru a fi un număr de telefon',
		'invalid email': 'E-mail invalid',
		DAILY_TESTS_LIMIT: 'Pentru astăzi încercările tale s-au finisat, poți începe antrenamentul mîine',
		TEMPLATE_NOT_FOUND: 'Șablonul nu a fost găsit'


	}
}
