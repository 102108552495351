import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ru from './ru'
import en from './en'
import uk from './uk'
import pl from './pl'
import ro from './ro'
import az from './az'
import hy from './hy'

import { getDefaultLanguage } from 'utils/localStorage'


const lng = getDefaultLanguage()

const resources = {
	ru: {
		translation: ru,
	},
	en: {
		translation: en,
	},
	uk: {
		translation: uk,
	},
	pl: {
		translation: pl,
	},
	ro: {
		translation: ro,
	},
	az: {
		translation: az,
	},
	hy: {
		translation: hy,
	},
}
i18n
	.use(initReactI18next) // pass the i18n instance to react-i18next.
	.init({
		resources,
		fallbackLng: false, // fallback language is english.
		returnEmptyString: false,
		lng,

	})

export default i18n
