import { ContestStateEnum, CurrencyEnum, GenderEnum } from 'api/generated'
import React from 'react'
import { BoyIcon, GirlIcon } from '../assets/icons'

export const ASSETS = 'https://assets.indigomental.com/'
export const CONTEST_STATE = [ContestStateEnum.Current, ContestStateEnum.Future, ContestStateEnum.Passed]
export const LANGUAGES = ['en', 'uk', 'ru', 'pl', 'ro', 'az', 'hy']
export const DEFAULT_LNG_ADMIN = 'ru'
export const LANGUAGE_OPTIONS = [
	{ value: 'en', name: 'English' },
	{ value: 'uk', name: 'Українська' },
	{ value: 'ru', name: 'Русский' },
	{ value: 'pl', name: 'Polski' },
	{ value: 'ro', name: 'Română' },
	{ value: 'az', name: 'Azərbaycan dili' },
	{ value: 'hy', name: 'Հայերեն' },
]

export enum Lng {
	en,
	uk,
	ru,
	pl,
	ro,
	az,
	hy
}

export const CURRENCIES = [
	CurrencyEnum.Usd,
	CurrencyEnum.Amd,
	CurrencyEnum.Azn,
	CurrencyEnum.Eur,
	CurrencyEnum.Pln,
	CurrencyEnum.Ron,
	CurrencyEnum.Rub,
	CurrencyEnum.Uah,
	CurrencyEnum.Mdl,

]

export const OPTIONS_GENDER = [
	{ value: GenderEnum.Male, name: 'forms.gender.boy', icon: <BoyIcon /> },
	{ value: GenderEnum.Female, name: 'forms.gender.girl', icon: <GirlIcon /> },
	{ value: GenderEnum.WontSay, name: 'forms.gender.notSay', icon: '' },
]
