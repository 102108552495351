export default {
	exit: 'Go out',

	warning: `<div> Be careful! </br> There is only <strong> one attempt </strong> to enter </br> the registration <strong> code </strong> at the next step </div>`,
	inputCode: 'Enter registration code',
	startLater: 'Start later',
	olympiadIndigo: 'INDIGO Online Olympiad',
	olympiadIndigoTest: 'Testing of the INDIGO Olympiad',
	welcome: 'Welcome to the Olympiad!',
	access: `To access tasks, you must specify an <br/><strong> e-mail </strong> and a<strong> unique code </strong>`,
	accessTest: `You must specify an <strong>email</strong> to access the tasks`,
	uniqueCode: 'Unique code',
	example: 'For example',
	auth: 'Log in',
	authTest: 'Authorize',
	readyDescription: `Are you ready? </br> <strong> We wish you success!

 </strong>`,
	goToExecution: 'Go to Assignment',
	ready: 'Get ready',
	nextStage: 'Next step',
	finishStage: 'Next',
	finishAtALl: 'Finish participation',
	start: 'Start',
	startTask: 'Start assignments',
	answerAllExample: 'Answer',
	inputAnswerInCell: `Enter the answer in the cell. <br/> If there is not enough time to count, enter any number.`,
	youDidIt: 'Congratulations, you passed the Olympiad!',
	youDidItTest: 'Hooray your task testing is completed!',
	yourRecord: 'Your result:',
	yourRecordTest: 'Your result:',
	points: 'Points',
	pointsTest: 'Points',
	downloadResult: 'Download the result',
	endParticipation: 'Finish participation',
	congratulation: `Congratulations, you <strong>passed</strong> the Olympiad! <br/>Your participation diploma has been sent by <strong>email</strong>. <br/>Wait for the competition to end. </br> We wish you to take the prize!`,
	payment: `Congratulations, your <strong> payment </strong> successfully completed! </br> Check your <strong> Email </strong> </br>  ( if you have not received the email, check your <strong>spam</strong> folder or <strong>promotions</strong>) </br>and wait for the start of <strong> Olympiad. </strong>`,

	email: 'Email',
	attempt: 'Attempt',
	takeParticipate: `Participate in the <strong>Olympiad</strong> and test your skills against other opponents!

`,
	tryAgain: 'Try again',
	leaveTesting: 'Exit Test',
	lookItUpResultPrev: `Get familiarized with the <strong>results</strong> of the Olympiad`,
	showResult: 'See the results',
	ONE: 'One-digit (Once)',
	TEN: 'Two-digit (Tens) ',
	HUNDRED: 'Three-digit (Hundreds)',
	THOUSAND: 'Four-digit',
	TEN_THOUSANDS: 'Five-digit',
	actions: 'actions',
	action: 'action',
	speed: 'speed',
	sec: 'sec',
	mental: '“Mental Arithmetic”',
	prev: 'Previous',
	next: 'Next',
	completedStage: 'Complete stage'

}
